import React, { FC } from 'react';
import { AccountsService } from '@packages/contexts/accounts';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Typography } from '../shared';
import { useBmisState } from '@packages/contexts/bmis';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    section: {
        paddingVertical: 12,
        paddingHorizontal: 24,
    },
    retailerLabelWrapper: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    retailerTitle: {
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 24,
    },
    retailerSubTitle: {
        marginTop: 8,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
    retailerContent: {
        fontWeight: '400',
        lineHeight: 24,
    },
});

interface BmisAsideDetailsProps {
    accountService: AccountsService;
}

export const BmisAsideDetails: FC<BmisAsideDetailsProps> = () => {
    const { t } = useTranslation();
    const { selectedBmisRow } = useBmisState();

    const retailer = selectedBmisRow?.retailer;

    const retailerLocation = `Region ${retailer?.regionCode || ''}, Zone ${retailer?.zoneCode || ''}, District ${retailer?.districtCode || ''}`;

    return (
        <ScrollView>
            <View style={styles.section}>
                <View style={styles.retailerLabelWrapper}>
                    <Typography variant="h6" style={styles.retailerTitle}>
                        {t('Retailer')}
                    </Typography>
                </View>
                <Typography style={styles.retailerSubTitle} color="blueOne">
                    {retailer?.name} (#{retailer?.code})
                </Typography>
                <Typography style={styles.retailerContent}>{retailerLocation}</Typography>
            </View>
        </ScrollView>
    );
};
