import React from 'react';
import { Icon, StsIconName, Typography } from '@packages/ui/shared';
import { StyleProp, TextStyle } from 'react-native';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
    },
    imageWrapper: {
        width: 160,
        height: 160,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: colors.grayOne,
        marginBottom: 16,
    },
    image: {
        width: 160,
        height: 160,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export interface EmptyProps {
    image?: React.ReactNode | string;
    imageStyle?: React.CSSProperties;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    icon?: StsIconName;
    className?: string;
    descriptionStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}

export const Empty: React.FC<EmptyProps> = React.memo(
    ({
        image,
        imageStyle = {},
        description = 'No Result',
        children,
        icon = 'battery',
        className,
        descriptionStyle = {},
        containerStyle = {},
    }) => {
        const classes = useStyles();

        const typographyStyle = {
            fontSize: '21px',
            textAlign: 'center',
            marginTop: '8px',
            width: '300px',
            paddingRight: '30px',
            paddingLeft: '30px',
            gap: 4,
            fontWeight: '600',
            whiteSpace: 'pre-wrap',
            lineHeight: '32px',
            ...descriptionStyle,
        } as StyleProp<TextStyle>;

        return (
            <div className={`${classes.container} ${className || ''}`} style={containerStyle}>
                <div className={classes.imageWrapper}>
                    {image ? (
                        typeof image === 'string' ? (
                            <img
                                src={image}
                                alt="empty state"
                                className={classes.image}
                                style={imageStyle}
                                loading="lazy"
                            />
                        ) : (
                            <div style={imageStyle}>{image}</div>
                        )
                    ) : (
                        <Icon testID="icon" name={icon} color="grayFive" size={80} aria-label="Empty state icon" />
                    )}
                </div>
                {description && (
                    <Typography color="textDarkSecondary" variant="caption" style={typographyStyle}>
                        {description}
                    </Typography>
                )}
                {children && children}
            </div>
        );
    }
);
