import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Avatar from '@web/components/avatar';
import { Button, Icon, Label, Typography } from '@packages/ui/shared';
import InfoCard from '@packages/ui/shared/info-card';
import { accountsService, techlineService, notificationsService } from '@web/services/singletons';
import { GetRetailerByRetailerResponseBody } from '@packages/contexts/accounts';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from 'react-select/async';
import colors from '@packages/core/styles/colors';
import { Retailer, RetailerProfile, TechlineStatusCode } from '@packages/models/api/techline';
import AccountSettingsModal from '@web/components/account-settings-modal';
import { useAuthState } from '@packages/contexts/auth';

interface OptionType {
    label: string;
    value: GetRetailerByRetailerResponseBody;
}

interface Props {
    retailerNumber: string;
    caseNumber: string;
    retailerProfiles: RetailerProfile[];
    caseStatus: any;
    modifiedOnDescription?: string;
    showError?: (error: string) => void;
    disableAddMember?: boolean;
    fetchCaseDetails?: any;
}

export const CaseTeamOnDetails: FC<Props> = ({
    retailerNumber,
    caseNumber,
    retailerProfiles,
    showError,
    disableAddMember,
    fetchCaseDetails,
    caseStatus,
    modifiedOnDescription,
}) => {
    const { account } = useAuthState();

    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [initialTab, setInitialTab] = useState('notifications');
    const [notificationPreferences, setNotificationPreferences] = useState<any>(undefined);

    const fetchNotificationPreferences = useCallback(async () => {
        try {
            const response = await notificationsService.fetchNotificationPreferences(undefined, true);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            let data = response.data.notificationPreferences;
            // Edge Case: Merge CSV export notifs into the QMR notifications category
            if (data.csvExportNotifications && data.qmrNotifications) {
                // Merge the csvExportNotifications into qmrNotifications
                data.qmrNotifications = [...data.qmrNotifications, ...data.csvExportNotifications];
                // Delete the original csvExportNotifications category
                delete data.csvExportNotifications;
            }
            // Update the state with the modified data
            setNotificationPreferences(data);
        } catch (error) {
            window.alert(error.message);
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchNotificationPreferences();
    }, [fetchNotificationPreferences]);

    const techlineNotifications = useMemo(() => {
        if (notificationPreferences && notificationPreferences.techlineNotifications?.length) {
            return notificationPreferences.techlineNotifications[0];
        }
    }, [notificationPreferences]);

    const defaultEmailAddress = useMemo(() => {
        if (account?.emailAddresses?.length && account?.defaultEmailAddressId) {
            return (
                account.emailAddresses.find((e) => {
                    return e.emailAddressId === account?.defaultEmailAddressId;
                })?.emailAddress || ''
            );
        }
        return '';
    }, [account]);

    const isCloseCase = useMemo(() => {
        if (caseStatus) {
            return [TechlineStatusCode.CLOSED, TechlineStatusCode.CLOSED_RESOLVED].includes(caseStatus);
        }
        return false;
    }, [caseStatus]);

    const fetchMembers = useCallback(
        async (inputValue: string, callback: (options: OptionType[]) => void): Promise<OptionType[]> => {
            if (inputValue.length < 3) {
                callback([]);
                return [];
            }
            setIsLoading(true);
            try {
                const data = await accountsService.getRetailersByRetailerNumber({
                    text: inputValue,
                    retailerCode: retailerNumber,
                });

                if (data.success) {
                    const options = data.data.map((retailer) => ({
                        label: `${retailer.firstName} ${retailer.lastName} (${retailer.soaUsername}) - ${retailer.jobTitle}`,
                        value: retailer,
                    }));
                    callback(options); // Directly call the callback with the options
                    return options;
                } else {
                    callback([]);
                    return [];
                }
            } catch (error) {
                console.error('Error fetching members:', error);
                callback([]);
                return [];
            } finally {
                setIsLoading(false);
            }
        },
        [retailerNumber]
    );

    const addMember = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await techlineService.patchRetailerProfile({
                casenumber: caseNumber,
                username: selectedOption?.value.soaUsername!,
                retailernumber: retailerNumber,
                statuscode: 2,
            });
            if (!res.success) {
                showError && showError('User could not be added because of a server error.');
            }
            fetchCaseDetails(caseNumber);
        } catch (error) {
            console.error('Add Member error:', error);
            showError && showError('User could not be added because of a server error.');
        } finally {
            setIsLoading(false);
            setShow(false);
        }
    }, [caseNumber, fetchCaseDetails, retailerNumber, selectedOption?.value.soaUsername, showError]);

    return (
        <>
            <AccountSettingsModal
                show={showSettingsModal}
                onHide={() => {
                    setShowSettingsModal(false);
                    fetchNotificationPreferences();
                }}
                initialActiveTabKey={initialTab}
                handleSetInitialTab={() => {
                    setInitialTab('notifications');
                }}
            />
            <div className="d-flex flex-row align-items-center justify-content-between">
                <Typography variant="h4">Case team</Typography>
                {!isCloseCase && (
                    <Button
                        onPress={() => {
                            setShow(true);
                        }}
                        iconLeft={
                            <Icon size={16} raised name="plus" raisedContainerStyle={{ width: 22, height: 22 }} />
                        }
                        disabled={disableAddMember}
                    >
                        Add member
                    </Button>
                )}
            </div>
            <div className="mt-5 mb-5">
                {retailerProfiles?.map(
                    (profile: { name: string | undefined; userName: string; soaUserId?: string | undefined }) => {
                        return (
                            <View>
                                <View style={styles.avatarWrapper}>
                                    <View style={styles.avatarNameWrapper}>
                                        {/* TODO: Add JOb Description and last updated date */}
                                        <InfoCard
                                            content={`${profile.userName} (${profile.soaUserId})`}
                                            accountService={accountsService}
                                            userName={profile.soaUserId}
                                        />
                                        <Typography variant="caption">{modifiedOnDescription} Last update</Typography>
                                    </View>
                                </View>
                            </View>
                        );
                    }
                )}
            </div>
            <div>
                <Typography>
                    TechShare uses your primary email address for any email notifications. Currently, you have email
                    notifications turned{' '}
                    <Typography variant="caption3">
                        {techlineNotifications?.emailNotification ? '"on"' : '"off"'}
                    </Typography>{' '}
                    for Techline and your primary email address is{' '}
                    <Typography style={{ color: colors.blueOne }}>{defaultEmailAddress}</Typography>. You can update
                    your notification preferences{' '}
                    <Typography onPress={() => setShowSettingsModal(true)} style={styles.link}>
                        here
                    </Typography>
                    . If your email is incorrect, please contact your DEM administrator.
                </Typography>
            </div>
            <Modal show={show} centered={true} onHide={() => setShow(false)} size="lg">
                <Modal.Header placeholder={''}>
                    <Modal.Title>Add member to case</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Label required>Member name</Label>
                    <AsyncSelect
                        styles={{
                            control: (styles, { isFocused }) => ({
                                ...styles,
                                boxShadow: `0 0 0 1px ${colors.blueOne}`,
                                padding: 6,
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
                            option: (base, { isFocused }) => ({
                                ...base,
                                lineHeight: '18px',
                                backgroundColor: isFocused ? colors.blueOne : 'white',
                                color: isFocused ? colors.white : colors.black,
                                padding: 20,
                            }),
                            indicatorSeparator: (base) => ({
                                ...base,
                                width: 0,
                            }),
                        }}
                        loadOptions={fetchMembers}
                        placeholder={''}
                        isLoading={isLoading}
                        onChange={(option) => {
                            setSelectedOption(option);
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button
                            variant="ghost-blue"
                            onPress={() => {
                                setShow(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={isLoading}
                        disabled={isLoading || !selectedOption}
                        onPress={() => addMember()}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const styles = StyleSheet.create({
    userContainer: {
        minHeight: 82,
    },
    avatarWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
    },
    avatarNameWrapper: {
        marginLeft: 16,
    },
    link: {
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        color: colors.blueOne,
        cursor: 'pointer',
    },
});
