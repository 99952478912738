import React, { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { authActions, AuthStatus, useAuthDispatch, useAuthState } from '@packages/contexts/auth';
import { QmrProvider } from '@packages/contexts/qmrs';
import { Account } from '@packages/models/api';

import Header from '@web/components/header';
import Navigation from '@web/components/navigation';
import PrivateRoute from '@web/components/private-route';

import { AuthLoading } from '@web/views/auth-loading';
import { FeedbackBrowser, FeedbackDetail } from '@web/feedback/views';
import NoMatch from '@web/views/no-match';
import Qmrs from '@web/views/qmrs';
import QmrDetail from '@web/qmr/views/qmr-detail';
import QmrDashboard from '@web/qmr/views/qmrs-dashboard';
import AdvancedSearch from '@web/qmr/views/advanced-search';
import AdvancedSearchResults from '@web/qmr/views/advanced-search-results';
import AdvancedSearchSaved from '@web/qmr/views/advanced-search-saved';
import Reports from '@web/views/reports';
import { Dashboard, RecentSearches, TechlineDetails } from '@web/techline/views';
import Overview from '@web/views/overview';
import QmrCsvExport from '@web/views/qmr-csv-export';
import UserOnboarding from '@web/views/user-onboarding';
import Investigations from '@web/investigations/views/investigations';
import InvestigationDetails from '@web/investigations/views/investigation-details';
import { configService, httpClient, pushNotificationsService, vinSessionService } from '@web/services/singletons';
import Settings from '@web/views/settings';
import {
    EntityDetail,
    EntityForm,
    FeedbackDetail as AdminFeedbackDetail,
    FeedbackFormBuilder,
    ManageEntities,
    ManageFeedback,
    ManageRetailers,
    ManageUsers,
    RetailerDetail,
    RetailerForm,
    TouchbookAttachments,
} from '@web/administration/views';
import { ManageWorksheets } from '@web/administration/views/worksheets';
import { UserArticles } from '@web/userarticles/user-articles';
import EmailNotificationsModal from '@web/components/email-notifications-modal';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import constants from '@web/config/constants';
import { ContentSettingsMainWrapper } from '../../content-settings/content-settings-main-wrapper';
import { SessionTimeoutPopup } from '@packages/ui/shared/popups';
import { InvestigationModal } from '@web/investigations/components/investigation-modal';
import { useInvestigationModal } from '@packages/contexts/investigations';
import { EscalationDetail, Escalations } from '@web/escalations/views';
import { EscalationModal } from '@web/escalations/components';
import { useEscalationModal } from '@packages/contexts/escalations';
import { WorksheetsForm } from '@web/administration/views/worksheets/worksheet-form/worksheets-form';
import { WorksheetDetail } from '@web/administration/views/worksheets/worksheet-detail/worksheet-detail';
import { WorksheetAnswer } from '@web/techline/components/worksheet/worksheet-answer';
import { ContentSettingsTechlineWrapper } from '@web/content-settings/content-settings-techline-wrapper';
import { ContentSettingsQmrsDashboardWrapper } from '@web/content-settings/content-settings-qmrs-dashboard-wrapper';
import { BmisRouter } from '@web/bmis/bmis-router';
import { FailCodeTeams } from '@web/administration/views/fail-code-teams';
import { FailCodeCsvExport } from '@web/administration/components/fail-code-teams-csv-export';
import WorksheetPreview from '@web/administration/views/worksheets/worksheet-preview/worksheet-preview';
import { useVinSessionDispatch, useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { vinSessionActions } from '@web/vin-sessions/context/vin-sessions.state';
import { closeVinSession, handleVinSessionError, openVinSession } from '@web/vin-sessions/utils/vinSessionUtils';
import DocsQuerySearch from '@web/vin-sessions/components/docs-query-search';
import VinSessionBanner from '@web/vin-sessions/components/vin-session-banner';
import { VinSessionDashboard } from '@web/vin-sessions/views/vin-session-dashboard';
import { BmisProvider } from '@packages/contexts/bmis';

interface AppRoute {
    path: string;
    private?: boolean;
    main?: ComponentType;
    routeGuard?: (account: Account | null) => boolean;
    redirectTo?: string;
    useChildRouter?: boolean | undefined;
}

export const routes: AppRoute[] = [
    {
        path: '/auth',
        private: false,
        main: AuthLoading,
    },
    {
        path: '/',
        private: true,
        main: Overview,
    },
    {
        path: '/vin/:vin',
        private: true,
        main: () => {
            return (
                <BmisProvider>
                    <VinSessionDashboard />
                </BmisProvider>
            );
        },
        routeGuard: () => {
            const isDevEnv = configService.debugEnvName === 'local' || configService.debugEnvName === 'development';
            return isDevEnv;
        },
        redirectTo: '/',
    },
    {
        path: '/docs/',
        private: true,
        main: DocsQuerySearch,
    },
    {
        path: '/onboard',
        private: true,
        main: UserOnboarding,
    },
    {
        path: '/qmrs',
        private: true,
        main: Qmrs,
    },
    {
        path: '/vin/:vin/bmis/*',
        private: true,
        main: () => {
            return <BmisRouter />;
        },
        useChildRouter: true,
    },
    {
        path: '/qmrs/dashboard',
        private: true,
        main: QmrDashboard,
    },
    {
        path: '/qmrs/advanced-search/search',
        private: true,
        main: AdvancedSearch,
    },
    {
        path: '/qmrs/advanced-search/saved',
        private: true,
        main: AdvancedSearchSaved,
    },
    {
        path: '/qmrs/advanced-search/:searchId',
        private: true,
        main: AdvancedSearchResults,
    },
    {
        path: '/qmrs/:displayIdentifier',
        private: true,
        main: () => {
            return (
                <QmrProvider>
                    <QmrDetail />
                </QmrProvider>
            );
        },
    },
    {
        path: '/qmrs/:displayIdentifier/edit',
        private: true,
        main: () => {
            return (
                <QmrProvider>
                    <QmrDetail />
                </QmrProvider>
            );
        },
    },
    {
        path: '/qmrs/csv-export/:exportId',
        private: true,
        main: QmrCsvExport,
    },
    {
        path: '/techline/:caseNumber',
        private: true,
        main: TechlineDetails,
    },
    {
        path: '/techline/worksheet-answer',
        private: true,
        main: WorksheetAnswer,
    },
    {
        path: '/techline/recent-searches',
        private: true,
        main: RecentSearches,
    },
    {
        path: '/techline',
        redirectTo: '/techline/dashboard',
    },
    {
        path: '/techline/dashboard',
        private: true,
        main: Dashboard,
    },
    // {
    //     path: '/techline/open/:retailerId',
    //     private: true,
    //     main: TechlineOpen,
    //     routeGuard: (account) => !!account?.systemCapabilities.viewInfotainmentPortal,
    // },
    {
        path: '/feedback',
        private: true,
        main: FeedbackBrowser,
        routeGuard: (account) => !!account?.systemCapabilities?.feedbackNavigationEnabled,
    },
    {
        path: '/feedback/:feedbackSessionId',
        private: true,
        main: FeedbackDetail,
        routeGuard: (account) => !!account?.systemCapabilities?.feedbackNavigationEnabled,
    },
    {
        path: '/feedback/entity/:feedbackCreationUrlPath',
        private: true,
        main: FeedbackDetail,
        routeGuard: (account) => !!account?.systemCapabilities?.feedbackNavigationEnabled,
    },
    {
        path: '/investigations',
        private: true,
        main: Investigations,
        routeGuard: (account) => !!account?.investigationCapabilities?.viewInvestigations,
    },
    {
        path: '/investigations/:investigationId',
        private: true,
        main: InvestigationDetails,
        routeGuard: (account) => !!account?.investigationCapabilities?.viewInvestigations,
    },
    {
        path: '/escalations',
        private: true,
        main: Escalations,
    },
    {
        path: '/escalations/:trReplyId',
        private: true,
        main: EscalationDetail,
    },
    {
        path: '/reports',
        private: true,
        main: Reports,
    },
    {
        path: '/settings',
        private: true,
        main: Settings,
    },
    {
        path: '/administration',
        private: true,
        main: () => <Navigate to="/administration/retailers" />,
    },
    {
        path: '/administration/users',
        private: true,
        main: ManageUsers,
    },
    {
        path: '/administration/entities',
        private: true,
        main: ManageEntities,
    },
    {
        path: '/administration/retailers',
        private: true,
        main: ManageRetailers,
    },
    {
        path: '/administration/retailers/:retailerId',
        private: true,
        main: RetailerDetail,
    },
    {
        path: '/administration/retailers/:retailerId/edit',
        private: true,
        main: RetailerForm,
    },
    {
        path: '/administration/entities/new',
        private: true,
        main: EntityForm,
    },
    {
        path: '/administration/entities/:entityId',
        private: true,
        main: EntityDetail,
    },
    {
        path: '/administration/entities/:entityId/edit',
        private: true,
        main: EntityForm,
    },
    {
        path: '/administration/feedback',
        private: true,
        main: ManageFeedback,
    },
    {
        path: '/administration/feedback/new',
        private: true,
        main: FeedbackFormBuilder,
    },
    {
        path: '/administration/feedback/:feedbackFormId',
        private: true,
        main: AdminFeedbackDetail,
    },
    {
        path: '/administration/feedback/:feedbackFormId/edit',
        private: true,
        main: FeedbackFormBuilder,
    },
    {
        path: '/administration/worksheets',
        private: true,
        main: ManageWorksheets,
    },
    {
        path: '/administration/worksheet/new',
        private: true,
        main: () => {
            return (
                <>
                    <WorksheetsForm />
                </>
            );
        },
    },
    {
        path: '/administration/worksheet/:worksheetFormId',
        private: true,
        main: () => {
            return (
                <>
                    <WorksheetDetail />
                </>
            );
        },
    },
    {
        path: '/administration/worksheet/preview/:worksheetId',
        private: true,
        main: () => {
            return <WorksheetPreview />;
        },
    },
    {
        path: '/administration/worksheet/:worksheetFormId/edit',
        private: true,
        main: () => {
            return (
                <>
                    <WorksheetsForm />
                </>
            );
        },
    },
    {
        path: '/administration/toughbook-attachments',
        private: true,
        main: TouchbookAttachments,
    },
    {
        path: '/administration/content-settings',
        private: true,
        main: ContentSettingsMainWrapper,
    },
    {
        path: '/administration/content-settings/techline',
        private: true,
        main: ContentSettingsTechlineWrapper,
    },
    {
        path: '/administration/content-settings/qmrsdashboard',
        private: true,
        main: ContentSettingsQmrsDashboardWrapper,
    },
    {
        path: '/administration/fail-code-teams',
        private: true,
        main: FailCodeTeams,
    },
    {
        path: '/failCode/csv-export/:exportId',
        private: true,
        main: FailCodeCsvExport,
    },
    {
        path: '/user-articles',
        private: false,
        main: UserArticles,
        routeGuard: (account) => !!account?.systemCapabilities?.viewDocumentation,
    },
    {
        path: '/user-articles/search',
        private: false,
        main: UserArticles,
        routeGuard: (account) => !!account?.systemCapabilities?.viewDocumentation,
    },
    {
        path: '/user-articles/edit/:id',
        private: false,
        main: UserArticles,
        routeGuard: (account) => !!account?.systemCapabilities?.manageDocumentation,
    },
    {
        path: '/user-articles/new',
        private: false,
        main: UserArticles,
        routeGuard: (account) => !!account?.systemCapabilities?.manageDocumentation,
    },
    {
        path: '/user-articles/:id',
        private: false,
        main: UserArticles,
        routeGuard: (account) => !!account?.systemCapabilities?.manageDocumentation,
    },
    {
        path: '*',
        private: false,
        main: NoMatch,
    },
];

const useAppNavigatorStyles = createUseStyles({
    appNavigator: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    contentAreaOverlay: ({ showOverlay, draggable }: { showOverlay: boolean; draggable: boolean }) => ({
        zIndex: showOverlay ? 10 : -10,
        backgroundColor: showOverlay ? 'rgba(0,0,0,0.65)' : 'transparent',
        position: 'absolute',
        top: constants.headerHeight,
        bottom: 0,
        right: showOverlay ? 0 : draggable ? 0 : -390,
        left: 0,
        transition: 'right 0.5s, background-color 0.5s',
    }),
    contentArea: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    routeSidebarOuter: {
        display: 'flex',
    },
    routeMainOuter: {
        flex: 1,
        overflow: 'hidden',
        overflowY: 'scroll',
    },
    '@media(max-width: 600px)': {
        contentAreaOverlay: ({ showOverlay, draggable }: { showOverlay: boolean; draggable: boolean }) => ({
            right: showOverlay ? 0 : draggable ? 0 : 0,
        }),
    },
    '@media(min-width: 768px) and (max-width: 1024px)': {
        routeMainOuter: {
            scrollbarWidth: 'none',
        },
    },
});

export const AppNavigator = () => {
    const { account } = useAuthState();
    const { status: authStatus } = useAuthState();
    const authDispatch = useAuthDispatch();
    const isProcessingAuthRedirect = useMatch('/auth');
    const [navIsOpen, setNavIsOpen] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { showOverlay, dismissAllOverlays, draggable } = useContentOverlayState();
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const classes = useAppNavigatorStyles({ showOverlay, draggable });
    const tokenExpiration = useRef<number>();
    const { show, hideModal } = useInvestigationModal();
    const { show: showEscalationModal, hideModal: hideEscalationModal } = useEscalationModal();
    const { isVinLoading, selectedVin, title } = useVinSessionState();
    const [vinSessionLoader, setVinSessionLoader] = useState<boolean>(false);
    const dispatch = useVinSessionDispatch();

    const handleNotAuthenticated = useMemo(() => {
        let isHandlingNotAuthRedirect = false;

        return () => {
            if (isHandlingNotAuthRedirect) {
                return;
            }

            isHandlingNotAuthRedirect = true;

            authDispatch(authActions.resetState());
            navigate('/', { replace: true });
        };
    }, [authDispatch, navigate]);

    // Navigation Path Logic
    const fullNavigationPath = useMemo(() => location.pathname, [location.pathname]);
    const navigationPathParts = useMemo(() => fullNavigationPath.split('/'), [fullNavigationPath]);

    // Account Logic
    const accountId = account?.accountId || null;

    // Vin Path Logic
    const isVinSessionActive = useMatch('/vin/*');
    const vinString = useMemo(
        () => (isVinSessionActive ? navigationPathParts[2] : null),
        [isVinSessionActive, navigationPathParts]
    );

    const fetchVin = (vin: string) => {
        async function getVin(vin: string) {
            setVinSessionLoader(true);
            dispatch(
                vinSessionActions.setIsVinLoading({
                    isVinLoading: true,
                })
            );
            try {
                const response = await vinSessionService.getVinData({
                    fetchVinParams: {
                        vin: vin,
                    },
                    ignoreCache: true,
                });
                if (response.success) {
                    if (JSON.stringify(response.data) == '{}') {
                        setVinSessionLoader(false);
                        navigate('/');
                        const error = new Error('No Record Found; returned data is empty');
                        handleVinSessionError(`Vin Fetching Error: ${error.message}`, error);
                        dispatch(
                            vinSessionActions.setIsVinLoading({
                                isVinLoading: false,
                            })
                        );
                        return;
                    }
                    dispatch(
                        vinSessionActions.setSelectedVin({
                            selectedVin: response.data,
                        })
                    );
                    dispatch(vinSessionActions.setTitle({ title: response.data.vinRecord.vin }));
                }
            } catch (error) {
                navigate('/');
                setVinSessionLoader(false);
                dispatch(vinSessionActions.setIsVinLoading({ isVinLoading: false }));
                dispatch(vinSessionActions.setSelectedVin({ selectedVin: undefined }));
                handleVinSessionError(`Vin Fetching Error: No Record Found; an unknown error occurred`, error);
                return;
            }
            openVinSession(vin, account?.accountId, setVinSessionLoader);
            setVinSessionLoader(false);
            dispatch(
                vinSessionActions.setIsVinLoading({
                    isVinLoading: false,
                })
            );
        }

        if (vin.length < 8) {
            navigate('/');
            setVinSessionLoader(false);
            const error = new Error('Invalid VIN');
            handleVinSessionError(`Vin Fetching Error: ${error.message}`, error);
            return;
        }
        getVin(vin);
    };

    const handleTokenUpdate = useCallback(
        (token: string) => {
            authDispatch(authActions.updateToken({ token }));
        },
        [authDispatch]
    );

    useEffect(() => {
        if (authStatus === AuthStatus.Authenticated) {
            httpClient.registerSessionTimeoutHandler((exp: number) => {
                tokenExpiration.current = exp;
                setShowTimeoutModal(true);
            });

            httpClient.registerNotAuthenticatedHandler(handleNotAuthenticated);
            httpClient.registerTokenUpdateHandler(handleTokenUpdate);

            console.log('[Messaging Debug]: Initializing (Permission/Token/Device) Push Notification Service');
            pushNotificationsService.init();
        }

        const handleWindowFocus = () => {
            if (tokenExpiration.current && Date.now() / 1000 > tokenExpiration.current) {
                handleNotAuthenticated();
            }
        };

        window.addEventListener('focus', handleWindowFocus);

        return () => {
            window.removeEventListener('focus', handleWindowFocus);
            if (authStatus === AuthStatus.Unauthenticated) {
                console.log('[Messaging Debug]: Terminating Push Notification Service');
                pushNotificationsService.terminate();
            }
        };
    }, [authDispatch, authStatus, handleNotAuthenticated, handleTokenUpdate]);

    useEffect(() => {
        if (!isVinSessionActive && !isVinLoading) {
            dispatch(vinSessionActions.setSelectedVin({ selectedVin: undefined }));
            dispatch(vinSessionActions.setTitle({ title: 'Subaru TechShare' }));
        } else {
            if (accountId && vinString) {
                fetchVin(vinString);
            }
        }
    }, [accountId, isVinSessionActive, vinString]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            closeVinSession(vinString, account?.accountId, setVinSessionLoader);
            event.returnValue = 'You are about to close the Vin-Session';
            return 'You are about to close the Vin-Session';
        };

        if (isVinSessionActive) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [isVinSessionActive]);

    if (!isProcessingAuthRedirect && authStatus !== AuthStatus.Authenticated) {
        return <AuthLoading />;
    }

    return (
        <div className={classes.appNavigator}>
            <Header vinSessionLoader={vinSessionLoader} setVinSessionLoader={setVinSessionLoader} />

            {!isVinLoading && (selectedVin || isVinSessionActive) && <VinSessionBanner />}

            <InvestigationModal
                show={show}
                onHide={() => {
                    hideModal();
                }}
            />

            <EscalationModal
                show={showEscalationModal}
                onHide={() => {
                    hideEscalationModal();
                }}
            />

            <EmailNotificationsModal
                show={location.state && location.state.recentlyOnboarded}
                onHide={() => {
                    navigate(location.pathname, {
                        ...location,
                        state: { recentlyOnboarded: false },
                    });
                }}
            />

            <SessionTimeoutPopup
                show={showTimeoutModal}
                onExtendSession={() => {
                    httpClient.refreshToken().then(() => {
                        tokenExpiration.current = undefined;
                        setShowTimeoutModal(false);
                    });
                }}
                onTimeout={handleNotAuthenticated}
            />

            <div className={classes.contentArea}>
                <div className={classes.routeSidebarOuter}>
                    <Navigation open={navIsOpen} onOpenTogglePress={setNavIsOpen} />
                </div>

                <div
                    id="right-side-panel"
                    onClick={() => {
                        dismissAllOverlays();
                    }}
                    className={classes.contentAreaOverlay}
                />

                <div className={classes.routeMainOuter}>
                    <Routes>
                        {routes.map((route, index) => {
                            if (route.private && route.main) {
                                const childPath = route.useChildRouter ? '*' : route.path;
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        element={<PrivateRoute path={route.path} routeGuard={route.routeGuard} />}
                                    >
                                        <Route path={childPath} element={<route.main />} />;
                                    </Route>
                                );
                            }

                            if (route.redirectTo) {
                                return (
                                    <Route key={index} path={route.path} element={<Navigate to={route.redirectTo} />} />
                                );
                            }

                            if (route.main) {
                                return <Route key={index} path={route.path} element={<route.main />} />;
                            }

                            return null;
                        })}
                    </Routes>
                </div>
            </div>
        </div>
    );
};
