import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { Save } from '@web/vin-sessions/assets/Icons';
import { Share } from '@web/vin-sessions/assets/Icons';
import { Typography } from '@packages/ui/shared';

type DocsSearchResultsProps = {
    title: string;
};

const useStyles = createUseStyles({
    searchResultsContainer: {
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
    },
    iconContainer: {
        width: 96,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

const DocsSearchResults: FC<DocsSearchResultsProps> = (props) => {
    const classes = useStyles();
    const { title } = props;

    return (
        <div className={classes.searchResultsContainer}>
            <Typography style={{ fontSize: 24, fontWeight: '500' }}>
                {`Search - ${0} results - \'${title}\'`}
            </Typography>
            <div className={classes.iconContainer}>
                <Save />
                <Share />
            </div>
        </div>
    );
};

export default DocsSearchResults;
