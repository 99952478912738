import { Asset, AssetMedia } from './assets';
import { Account } from './account';
import { Comment } from './comment';
import { TrReplyStatus } from '@packages/models/api';

export enum QmrSortOrder {
    Updated = 'UPDATED',
    DateCreated = 'DATE_CREATED',
    Carline = 'CARLINE',
    Status = 'QMR_STATUS',
    Year = 'YEAR',
    FailCode = 'FAIL_CODE',
    Section = 'SECTION',
    Retailer = 'RETAILER',
}

export enum QmrSortDirection {
    Asc = 'ASC',
    Desc = 'DESC',
}

export enum QmrStatusId {
    Draft = 'DRAFT',
    PendingReview = 'PENDING_REVIEW',
    PendingReviewUpdated = 'PENDING_REVIEW_UPDATED',
    UnderReview = 'UNDER_REVIEW',
    FurtherStudy = 'FURTHER_STUDY',
    NoActionNeeded = 'NO_ACTION_NEEDED',
    Escalated = 'ESCALATED',
    EscalatedAndPartCollection = 'ESCALATED_AND_PART_COLLECTION',
    MoreInfoRequested = 'MORE_INFO_REQUESTED',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/QmrStatusType.java
export enum QmrStatusTypeId {
    PendingReview = 'PENDING_REVIEW',
    UnderReview = 'UNDER_REVIEW',
    Completed = 'COMPLETED',
    MoreInfoRequested = 'MORE_INFO_REQUESTED',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/DsqmReviewStatus.java
export enum DsqmReviewStatusId {
    PendingReview = 'PENDING_REVIEW',
    UnderReview = 'UNDER_REVIEW',
    NoActionNeeded = 'NO_ACTION_NEEDED',
    Escalated = 'ESCALATED',
    EscalatedAndPartCollection = 'ESCALATED_AND_PART_COLLECTION',
    MoreInfoRequested = 'MORE_INFO_REQUESTED',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/DsqmReviewStatusType.java
export enum DsqmReviewStatusTypeId {
    PendingReview = 'PENDING_REVIEW',
    UnderReview = 'UNDER_REVIEW',
    Completed = 'COMPLETED',
    MoreInfoRequested = 'MORE_INFO_REQUESTED',
    NoActionNeeded = 'NO_ACTION_NEEDED',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/FailCodeStatus.java
export enum FailCodeStatus {
    Pending = 'PENDING',
    ApprovalPending = 'APPROVAL_PENDING',
    Approved = 'APPROVED',
    Added = 'ADDED',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/api/response/QmrApiResponse.java
export enum QmrApiSupplement {
    Everything = 'EVERYTHING', // Special supplement to "include everything"
    List = 'LIST',
    DtcCodes = 'DTC_CODES',
    Assets = 'ASSETS',
    FailCodes = 'FAIL_CODES',
    Vehicle = 'VEHICLE',
    Activities = 'QMR_ACTIVITIES',
    ReadStatus = 'READ_STATUS',
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/db/QmrActivityType.java
export enum QmrActivityType {
    QmrStatusChanged = 'QMR_STATUS_CHANGED',
    FailCodeStatusChanged = 'FAIL_CODE_STATUS_CHANGED',
    EscalationStatusChanged = 'ESCALATION_STATUS_CHANGED',
    ReviewStatusChanged = 'REVIEW_STATUS_CHANGED',
    CommentAdded = 'COMMENT_ADDED',
    InvestigationCreated = 'INVESTIGATION_CREATED',
    EscalationWorthinessChanged = 'ESCALATION_WORTHINESS_CHANGED',
    DsqmReviewStatusChanged = 'DSQM_REVIEW_STATUS_CHANGED',
}

export enum TrReplyStatusType {
    Draft = 'TR_DRAFT',
    Issued = 'TR_ISSUED',
    TempReplyReceived = 'TEMPORARY_REPLY_RECEIVED',
    FinalReplyReceived = 'FINAL_REPLY_RECEIVED',
    FinalReplyApproved = 'FINAL_REPLY_APPROVED',
    FinalReplyRefused = 'FINAL_REPLY_REFUSED',
    NotEscalated = 'NOT_ESCALATED',
    EscalationFailed = 'ESCALATION_FAILED',
}

export interface Carline {
    carlineId: string;
    name: string;
}

export interface Vehicle {
    carlineId: string;
    carlineName: string;
    colorCode: string;
    emissionSpec: string;
    engineNumber: string;
    engineSize: number;
    modelCode: string;
    modelYear: number;
    optionCode: string;
    productionDate: string;
    productionDateDescription: string;
    transmissionNumber: string;
    transmissionType: string;
    turboIndicator: boolean;
    vin: string;
    warrantyStartDate: string;
    warrantyStartDateDescription: string;
}

export interface DtcCode {
    carLine: string;
    description: string;
    dtcCode: string;
    dtcCodeId: string;
    engineUnit: string;
    modelYear: string;
    markedAsPrimary?: boolean;
    freezeFrameDataAvailable?: boolean;
}

export interface PartNumber {
    partNumber: string;
    partDescription: string;
}

export interface Retailer {
    retailerId: string;
    code: string;
    name: string;
    city: string;
    state: string;
    postalCode: string;
    districtId: string;
    districtCode: string;
    zoneId: string;
    zoneCode: string;
    regionId: string;
    regionCode: string;
    capabilities?: {
        canCreateQmr: boolean;
        manageFailCodeApprover: boolean;
    };
    failCodeApproverSettingId: string;
    failCodeApproverSettingDescription: string;
}

export interface QmrActivity {
    qmrActivityId: string;
    qmrActivityTypeId: QmrActivityType;
    qmrActivityTypeIdDescription: string;
    createdAccountId: string;
    createdTimestamp: string;
    createdTimestampDescription: string;
    title: string;
    body: string;
    bodyHtml: string;
    data: {
        qmrStatusId: QmrStatusId;
    };
    createdAccount: Account;
}

export interface QmrReviewAction {
    description: string;
    qmrStatusId: QmrStatusId;
}

export interface QaQmrAction {
    qmrStatusId: QmrStatusId;
    qmrStatusTypeId: QmrStatusTypeId;
    description: string;
    actionDescription: string;
}

export interface DsqmQmrAction {
    dsqmReviewStatusId: DsqmReviewStatusId;
    dsqmReviewStatusTypeId: DsqmReviewStatusTypeId;
    description: string;
    actionDescription: string;
}

// https://github.com/subcomm/subcomm-backend/blob/develop/src/main/java/io/subcomm/backend/model/api/response/QmrApiResponse.java
export interface Qmr {
    actions: QmrReviewAction[];
    assets?: Asset[];
    capabilities: Capabilities;
    carlineId: string;
    carlineName: string;
    cause: string;
    colorCode: string;
    complaint: string;
    complaintDuplicated: boolean;
    correction: string;
    createdAccountId: string;
    createdAccount: Account;
    createdTimestamp: string;
    createdTimestampDescription: string;
    relativeCreatedTimestampDescription: string;
    displayIdentifier: string;
    dtcCodes?: DtcCode[];
    dtcCodesSet: boolean;
    emissionSpec: string;
    engineNumber: string;
    engineSize: string;
    escalated: boolean;
    ewdAccident: boolean;
    ewdDeath: boolean;
    ewdDeathCount: number;
    ewdDeathCountDescription: string;
    ewdFire: boolean;
    ewdInjury: boolean;
    ewdInjuryCount: number;
    ewdInjuryCountDescription: string;
    ewdPropertyDamage: boolean;
    ewdRollover: boolean;
    ewdSignsExist?: boolean;
    firstSubmittedAccountId: string;
    firstSubmittedTimestamp: string;
    firstSubmittedTimestampDescription: string;
    failCodeApproved: boolean;
    failCodeStatusId: FailCodeStatus;
    failCodeStatusIdDescription: string;
    failureDate: string;
    failureDateDescription: string;
    dsqmAccount: Account;
    issueResolved: boolean;
    importedFromSiebel: boolean;
    lastEditedTimestamp: string;
    lastEditedTimestampDescription: string;
    relativeLastEditedTimestampDescription: string;
    lastUpdatedAccountId: string;
    lastUpdatedAccount: Account;
    lastUpdatedTimestamp: string;
    lastUpdatedTimestampDescription: string;
    relativeLastUpdatedTimestampDescription: string;
    listAssetImage?: AssetMedia;
    mileage: number;
    modelCode: string;
    modelYear: number;
    optionCode: string;
    outcomeAvailable: boolean;
    outcomeRetailerTechnicianCorporate: string;
    outcomeSneSdcDsqm: string;
    outcomeSoaDsqmNasi: string;
    partNumber: string;
    productionDate: string;
    productionDateDescription: string;
    qmrActivities?: QmrActivity[];
    qmrId: string;
    qmrStatus: QaQmrAction;
    dsqmReviewStatus: DsqmQmrAction;
    read: boolean;
    repairDate: string;
    repairDateDescription: string;
    repairOrderNumber: string;
    restricted: boolean;
    restrictedAssetIds?: Asset['assetId'][];
    retailerId: string;
    retailer: Retailer;
    technicianAccountId: string;
    trReplyRecieved: string;
    trReplyShared: string;
    trReplyStatus?: TrReplyStatus;
    transmissionNumber: string;
    transmissionType: string;
    turboIndicator: boolean;
    vin: string;
    warrantyStartDate: string;
    warrantyStartDateDescription: string;
    failCode: string;
    failCodeDescription: string;
    failCodeSectionName: string;
    failCodeSubsectionName: string;
    failCodeFigureCode: string;
    failCodeFunctionCode: string;
    failCodePartNumber: string;
    additionalInfoComments: Comment[];
    siebelIdentifier: string;
    stsIdentifier: string;
    shareUrl: string;
    investigationTitle?: string;
    investigationId?: string;
    isValidFailCode?: boolean;
    openPartCollectionRequest?: QmrPartCollectionRequest;
    closedPartCollectionRequests?: QmrPartCollectionRequest[];
    qmrCategories?: string[];
}

export interface Capabilities {
    addFailCodeToQmr: boolean;
    attachAssetToQmr: boolean;
    canAddAdditionalInfoToQmr: boolean;
    canAddCommentToQmr: boolean;
    canEscalateQmr: boolean;
    canViewAdditionalInfoAuthorOnQmr: boolean;
    canViewCommentsOnQmr: boolean;
    canViewEscalationOnQmr: boolean;
    deleteAssetFromQmr: boolean;
    deleteDtcOnQmr: boolean;
    deleteFailCodeOnQmr: boolean;
    deleteQmr: boolean;
    downloadAttachmentsOnQmr: boolean;
    editCarlineOnQmr: boolean;
    editCccOnQmr: boolean;
    editColorOnQmr: boolean;
    editDtcOnQmr: boolean;
    editEmissionsSpecOnQmr: boolean;
    editEngineNumberOnQmr: boolean;
    editEngineSizeOnQmr: boolean;
    editEwdOnQmr: boolean;
    editFailCodeOnQmr: boolean;
    editMediaOnQmr: boolean;
    editModelOnQmr: boolean;
    editModelOptionOnQmr: boolean;
    editModelYearOnQmr: boolean;
    editProductionDateOnQmr: boolean;
    editQmr: boolean;
    editRepairOrderOnQmr: boolean;
    editRetailerOnQmr: boolean;
    editTransmissionNumberOnQmr: boolean;
    editTransmissionTypeOnQmr: boolean;
    editTurboIndicatorOnQmr: boolean;
    editVinOnQmr: boolean;
    editWarrantyStartDateOnQmr: boolean;
    giveFeedbackOnQmr: boolean;
    markQmrAsRestricted: boolean;
    markRestrictedMediaOnQmr: boolean;
    openCloseMoreInfoRequest: boolean;
    openClosePartCollRequest: boolean;
    requestFailCodeApproval: boolean;
    revokeFailCodeApproval: boolean;
    transitionableQmrStatuses: Qmr['qmrStatus'][];
    transitionableDsqmReviewStatuses: Qmr['dsqmReviewStatus'][];
    useAccidentInvestigationOnQmr: boolean;
    viewActivityLog: boolean;
    viewCccOnQmr: boolean;
    viewCommentActivityLogEntries: boolean;
    viewCreatorContactOnQmr: boolean;
    viewCreatorNameOnQmr: boolean;
    viewDtcOnQmr: boolean;
    viewEscalationStatusActivityLogEntries: boolean;
    viewEwdOnQmr: boolean;
    viewFailCodeOnQmr: boolean;
    viewMediaOnQmr: boolean;
    viewMoreInfoRequest: boolean;
    viewPartCollRequest: boolean;
    viewQmr: boolean;
    viewRepairOrderOnQmr: boolean;
    viewRestrictedFlagActivityLogEntries: boolean;
    viewRestrictedMediaOnQmr: boolean;
    viewRetailerOnQmr: boolean;
    viewTrReplyActivityLogEntries: boolean;
    viewVinOnQmr: boolean;
    viewCatOnQmr: boolean;
    editCatOnQmr: boolean;
}

export type QmrEwdState = Pick<
    Qmr,
    | 'ewdSignsExist'
    | 'ewdRollover'
    | 'ewdFire'
    | 'ewdAccident'
    | 'ewdPropertyDamage'
    | 'ewdInjury'
    | 'ewdInjuryCount'
    | 'ewdDeath'
    | 'ewdDeathCount'
>;

export interface QmrStatusSummary {
    qmrStatusId?: QmrStatusId;
    count: number;
    countDescription: string;
    description: string;
    listDescription: string;
}

export interface QmrPartCollectionRequest {
    partCollectionRequestId: string;
    openedAccount: Account;
    closedAccount?: Account;
    openedTimestampDescription: string;
    closedTimestampDescription?: string;
    requestOpen: boolean;
}

export type CreateQmrDto = {
    retailerId?: string;
    vin?: string;
    warrantyStartDate?: string;
    productionDate?: string;
    engineSize?: number;
    transmissionType?: string;
    emissionSpec?: string;
    turboIndicator?: boolean;
    carlineId?: string;
    modelYear?: number;
    engineNumber?: string;
    transmissionNumber?: string;
    qmrCategories?: string[];
};

export interface UpdateQmrDtcCodesDto {
    dtcCodesSet: boolean;
    dtcCodes: Pick<DtcCode, 'dtcCodeId' | 'markedAsPrimary' | 'freezeFrameDataAvailable'>[];
}

export interface UpdateQmrRestrictedAssetIdsDto {
    restrictedAssetIds: Asset['assetId'][];
}

export type SaveQmrDto = UpdateQmrDtcCodesDto & PatchQmrDto;

export type PatchQmrDto = {
    retailerId?: Qmr['retailerId'] | null;
    vin?: Qmr['vin'] | null;
    modelYear?: Qmr['modelYear'] | null;
    carlineId?: Qmr['carlineId'] | null;
    transmissionNumber?: Qmr['transmissionNumber'] | null;
    engineNumber?: Qmr['engineNumber'] | null;
    complaint?: Qmr['complaint'] | null;
    ewdSignsExist?: Qmr['ewdSignsExist'] | null;
    ewdRollover?: Qmr['ewdRollover'] | null;
    ewdFire?: Qmr['ewdFire'] | null;
    ewdAccident?: Qmr['ewdAccident'] | null;
    ewdPropertyDamage?: Qmr['ewdPropertyDamage'] | null;
    ewdInjury?: Qmr['ewdInjury'] | null;
    ewdInjuryCount?: Qmr['ewdInjuryCount'] | null;
    ewdDeath?: Qmr['ewdDeath'] | null;
    ewdDeathCount?: Qmr['ewdDeathCount'] | null;
    complaintDuplicated?: Qmr['complaintDuplicated'] | null;
    repairOrderNumber?: Qmr['repairOrderNumber'] | null;
    mileage?: Qmr['mileage'] | null;
    failureDate?: Qmr['failureDate'] | null;
    cause?: Qmr['cause'] | null;
    partNumber?: Qmr['partNumber'] | null;
    correction?: Qmr['correction'] | null;
    issueResolved?: Qmr['issueResolved'] | null;
    repairDate?: Qmr['repairDate'] | null;
    colorCode?: Qmr['colorCode'] | null;
    modelCode?: Qmr['modelCode'] | null;
    optionCode?: Qmr['optionCode'] | null;
    failCode?: Qmr['failCode'] | null;
    requestFailCodeApproval?: boolean;
    revokeFailCodeApproval?: boolean;
    failCodeSectionName?: Qmr['failCodeSectionName'] | null;
    failCodeSubsectionName?: Qmr['failCodeSubsectionName'] | null;
    failCodeFigureCode?: Qmr['failCodeFigureCode'] | null;
    failCodeFunctionCode?: Qmr['failCodeFunctionCode'] | null;
    failCodePartNumber?: Qmr['failCodePartNumber'] | null;
    manuallyAddedFailCode?: boolean;
    dtcCodes?: UpdateQmrDtcCodesDto['dtcCodes'] | null;
    qmrStatusId?: QmrStatusId;
    dsqmReviewStatusId?: DsqmReviewStatusId;
    restricted?: boolean;
    qmrSubmit?: boolean;
    qmrCategories?: string[];
};

export type PatchQmrAssetDto = {
    note?: string | null;
    name?: string | null;
};

export interface DeleteQmrAssetsDto {
    assetIds: Asset['assetId'][];
}

export interface QmrResponseDto {
    qmr: Qmr;
}

export interface QmrListResponseDto {
    qmrs: Qmr[];
    totalCount: number;
    totalCountDescription: string;
    displayStartCount: number;
    displayStartCountDescription: string;
    displayEndCount: number;
    displayEndCountDescription: string;
}

export type QmrStatusSummariesResponseDto = {
    qmrStatusSummaries: QmrStatusSummary[];
};

export interface DtcCodesResponseDto {
    dtcCodes: DtcCode[];
}

export interface PartNumbersResponseDto {
    parts: PartNumber[];
}

export interface RetailersResponseDto {
    retailers: QmrRetailer[];
}

export interface RetailerResponseDto {
    retailer: QmrRetailer;
}

export interface RetailerSearchResponseDto {
    displayEndCount: number;
    displayEndCountDescription: string;
    displayStartCount: number;
    displayStartCountDescription: string;
    results: QmrRetailer[];
    totalCount: number;
    totalCountDescription: string;
}

export interface FailCodeApproverSetting {
    description: string;
    failCodeApproverSettingId: string;
}

export interface VehiclesResponseDto {
    vehicles: Vehicle[];
}

export interface CarlinesResponseDto {
    carlines: Carline[];
}

export interface CarlineQuickFilterOption {
    carlineId: string;
    name: string;
}

export interface QuickFilterOption {
    id: string;
    description: string;
}

export interface QmrQuickFiltersResponseDto {
    carlines: CarlineQuickFilterOption[];
    retailers: QuickFilterOption[];
    submissionDate: QuickFilterOption[];
    yearRange: QuickFilterOption[];
}

export interface RecentQuickSearchesResponseDto {
    recentSearches: string[];
}

// https://github.com/SubaruTechShare/sts-backend/wiki/API:-QMRs#get-qmrssearchparameters
export enum ADVANCED_SEARCH_FIELD_TYPE_ID {
    TEXT = 'TEXT',
    DATE = 'DATE',
    NUMERIC = 'NUMERIC',
    BOOLEAN = 'BOOLEAN',
    USER = 'USER',
    STATE = 'STATE',
    CARLINE = 'CARLINE',
    STATUS = 'STATUS',
    REVIEW_STATUS = 'REVIEW_STATUS',
    FILETYPE = 'FILETYPE',
    GEOGRAPHY = 'GEOGRAPHY',
    RETAILER = 'RETAILER',
    DTC = 'DTC',
    FAIL_CODE_TEAM = 'FAIL_CODE_TEAM',
    NQR = 'NQR',
}

export enum ADVANCED_SEARCH_PICKER_TYPE_ID {
    TEXT = 'TEXT',
    AUTO_TEXT = 'AUTO_TEXT',
    NUMERIC = 'NUMERIC',
    CHECKBOX = 'CHECKBOX',
    DATE = 'DATE',
    DROPDOWN = 'DROPDOWN',
}

export interface AdvancedSearchCombinator {
    combinatorId: string;
    description: string;
}

export interface AdvancedSearchField {
    fieldId: string;
    fieldTypeId: ADVANCED_SEARCH_FIELD_TYPE_ID;
    name: string;
    operators: AdvancedSearchOperator[];
    values: AdvancedSearchValue[];
    pickerTypeId: ADVANCED_SEARCH_PICKER_TYPE_ID;
}

export interface AdvancedSearchValue {
    valueId: string;
    valueName: string;
}

export interface AdvancedSearchOperator {
    operatorId: string;
    operatorName: string;
}

export interface AdvancedSearchParametersDto {
    combinators: AdvancedSearchCombinator[];
    fields: AdvancedSearchField[];
}

export interface AdvancedSearchAutocompleteResult {
    description: string;
    value: string;
}

export interface AdvancedSearchAutocompleteResultsDto {
    results: AdvancedSearchAutocompleteResult[];
}

export interface AdvancedSearchResultsDto {
    results: QmrListResponseDto;
    savedSearch: {
        name: string;
        publicSearch: boolean;
        query: any;
        savedSearchId: string;
    };
}

export interface AdvancedSearchSavedSearchesDto {
    displayEndCount: number;
    displayEndCountDescription: string;
    displayStartCount: number;
    displayStartCountDescription: string;
    savedSearches: SavedSearch[];
    totalCount: number;
    totalCountDescription: string;
}

export interface SavedSearchAction {
    savedSearchActionId: string;
    description: string;
}

export interface SavedSearch {
    account: Account;
    name: string;
    query: any;
    savedSearchId: string;
    publicSearch: boolean;
    actions: SavedSearchAction[];
    isAccountDefault: boolean;
    createdTimestampDescription: string;
}

export interface AdvancedSearchRecentSearchesDto {
    savedSearches: SavedSearch[];
}

export interface QmrInfoRequestData {
    qmrMoreInfoRequestId: string;
    qmrId: string;
    request: string;
    createdById: string;
    createdBy: Account;
    closedById: string;
    closedBy: Account;
    createdAt: Date;
    createdAtDescription: string;
    closedAt: Date;
    closedAtDescription: string;
    isOpen: boolean;
    comments: Comment[];
}

export interface QmrInfoRequestDto {
    openRequest: QmrInfoRequestData;
    closedRequests: QmrInfoRequestData[];
}

export interface OpenPartCollectRequestResponseDto {
    partCollectionRequest: QmrPartCollectionRequest;
}

export interface ClosePartCollectRequestResponseDto {
    partCollectionRequest: QmrPartCollectionRequest;
}
