import React, { FC, useEffect, useMemo, useState } from 'react';
import { patchQmr, useQmrDispatch, useQmrState } from '@packages/contexts/qmrs';
import { useAbortController } from '@packages/core/http';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography, Button } from '@packages/ui/shared';
import { qmrsService } from '@web/services/singletons';
import { Modal } from 'react-bootstrap';
import { SelectQmrPrimaryObjectives } from '@web/components/select-qmr-primary-objectives';

const useStyles = createUseStyles({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
    headerText: {
        fontSize: '21px',
    },
});

const PrimaryObjective = ({ viewOnly = false }: any) => {
    const classes = useStyles();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const [qmrCategories, setQmrCategories] = useState([]);
    const [showObjectiveModal, setShowObjectiveModal] = useState(false);
    const [selectedPrimaryObjectives, setSelectedPrimaryObjectives] = useState([]);

    const fetchQmrCategories = async () => {
        try {
            const qmrCategories: any = await qmrsService.fetchQmrCategories();
            if (qmrCategories?.data?.QmrCategories?.length) {
                setQmrCategories(
                    qmrCategories.data.QmrCategories.map((category: any) => ({ ...category, isSelected: false }))
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchQmrCategories();
    }, []);

    const selectedQmrCategories = useMemo(() => {
        return qmrCategories.filter((c: any) => {
            return qmr?.qmrCategories && qmr.qmrCategories.includes(c.qmrCategoryId);
        });
    }, [qmr?.qmrCategories, qmrCategories]);

    const renderQmrCategories = () => {
        if (!selectedQmrCategories?.length) return null;
        return selectedQmrCategories.map((category: any) => {
            return (
                <div>
                    <Typography variant="caption3">{category.description}</Typography>
                </div>
            );
        });
    };

    const handleObjectiveUpdate = async () => {
        if (!qmr) {
            return;
        }
        await patchQmr({
            qmrId: qmr.qmrId,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
            qmrPatch: { qmrCategories: selectedPrimaryObjectives.map((c: any) => c.qmrCategoryId) },
        });
        handleObjectiveModalClose();
    };

    const handleObjectiveModalClose = () => {
        setShowObjectiveModal(false);
    };

    const onPrimaryObjectivesSelected = (selectedOptions: any) => {
        setSelectedPrimaryObjectives(selectedOptions);
    };

    const renderSelectObjectiveModal = () => {
        return (
            <Modal show={showObjectiveModal} onHide={handleObjectiveModalClose} size="lg" centered style>
                <Modal.Header placeholder={'updateObjective'}>Update Primary Objective</Modal.Header>
                <Modal.Body>
                    <SelectQmrPrimaryObjectives
                        defaultSelected={selectedQmrCategories}
                        onSelect={onPrimaryObjectivesSelected}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleObjectiveModalClose}>
                            Cancel
                        </Button>
                    </div>
                    <Button disabled={!selectedPrimaryObjectives?.length} onPress={handleObjectiveUpdate}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div>
            <div className={classes.header}>
                <Typography style={{ fontSize: 21 }} variant="caption3">
                    Primary objective
                </Typography>
                {!viewOnly ? (
                    <button
                        onClick={() => setShowObjectiveModal(true)}
                        type="button"
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            Change Primary Objective
                        </Typography>
                    </button>
                ) : (
                    <></>
                )}
            </div>
            <div className="mt-3">{renderQmrCategories()}</div>
            {renderSelectObjectiveModal()}
        </div>
    );
};

export default PrimaryObjective;
