import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import { BmisAsideDetails } from '@packages/ui/bmis/bmis-aside-details';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { AccountsService } from '@packages/contexts/accounts';

const useBmisAsideContentStyles = createUseStyles({
    asideHeader: {
        position: 'relative',
        borderBottom: `1px solid ${colors.grayThree}`,
        padding: '16px 24px',
        marginBottom: '16px',
        '&::before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 24,
            height: '3px',
            width: '60px',
            backgroundColor: colors.blueOne,
        },
    },
});

interface BmisAsideContentProps {
    accountService: AccountsService;
}

export const BmisAsideContent: FC<BmisAsideContentProps> = ({ accountService }) => {
    const { t } = useTranslation();
    const classes = useBmisAsideContentStyles();

    return (
        <>
            <div className={classes.asideHeader}>
                <Typography variant="h5" color="blueOne">
                    {t('Details')}
                </Typography>
            </div>
            <BmisAsideDetails accountService={accountService} />
        </>
    );
};
