import React, { useCallback, useMemo, useState } from 'react';
import { Icon, Typography, Button, AssetProgressBar } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { useDropzone } from 'react-dropzone';
import { techlineService, analyticsService } from '@web/services/singletons';
import { AssetType, TechlineStatusCode } from '@packages/models/api';
import classNames from 'classnames';
import { Popover } from 'react-tiny-popover';
import AssetEditorModal from '../components/techline-asset-editor-modal';
import { caseStatusMap as statusMap } from '@web/utils';
import { ActivityIndicator } from 'react-native';
import { TechlineSections } from '.';
import useUserDetailsOrPermissions from './worksheet/hooks/useUserDetailsOrPermissions';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { Row, Col, Form, FormControlProps } from 'react-bootstrap';
import { default as placeHolderImage } from '../../assets/asset-placeholder@3x.png';
import ConfirmDeleteAssetModal from './confirm-delete-asset-modal';
import { throttle } from 'lodash';

const useStyles = createUseStyles({
    attachmentsContainer: {
        border: `2px solid ${colors.grayTwo}`,
        borderRadius: 5,
    },
    assetPopover: {
        minWidth: 175,
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayThree}`,
        borderRadius: 4,
    },
    assetPopoverSection: {
        padding: '4px 0',
    },
    downloadAssetBtn: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    dropZoneArea: {
        height: 80,
        marginRight: 24,
        paddingLeft: 24,
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        border: `2px dashed ${colors.grayThree}`,
    },
});

export const Attachments = ({
    caseNumber,
    assetsDetails,
    fetchAssets,
    caseDetails,
    scrollToSection,
    setSuccessMessage,
    setErrorMessage,
}: {
    caseNumber: string;
    assetsDetails: any;
    fetchAssets: any;
    caseDetails: any;
    scrollToSection: any;
    setSuccessMessage?: any;
    setErrorMessage?: any;
}) => {
    const classes = useStyles();
    const [popoverId, setPopoverId] = useState<string | null>(null);
    const [editorAssetIndex, setEditorAssetIndex] = useState(-1);
    const [viewOnly, setViewOnly] = useState(true);
    const [deleteAsset, setDeleteAsset] = useState<any>();
    const [deleteLoader, showDeleteLoader] = useState(false);
    const [inProgressAssets, setInProgressAssets] = useState<any>({});
    const {
        permissions: { canAddOrEditAttachment },
    } = useUserDetailsOrPermissions();
    const formatBytes = (bytes: any, decimals = 2) => {
        if (!+bytes) return '0 Bytes';
        const k = 1000; // Use 1000 for standard units (KB, MB, GB)
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    const assetsSize = useMemo(() => {
        let sizeInBytes = 0;
        assetsDetails.forEach((asset: any) => {
            // asset.media[0] is original file
            // asset.media[1] is thumbnail file
            if (asset?.media?.[0]?.sizeInBytes) {
                sizeInBytes += asset.media[0].sizeInBytes;
            }
        });
        return formatBytes(sizeInBytes);
    }, [assetsDetails]);

    const onDrop = useCallback(
        async (acceptedFiles: any) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_STARTS_ATTACHMENT_FLOW);
            try {
                let isScroll = false;
                if (acceptedFiles[0]) {
                    const assetsPreReqBody = {
                        assets: [
                            {
                                assetTypeId: acceptedFiles[0].type.includes('video')
                                    ? AssetType.Video
                                    : acceptedFiles[0].type.includes('image')
                                      ? AssetType.Image
                                      : AssetType.File,
                                assetDispositionId: 'TechShare',
                                caseNumber,
                                name: acceptedFiles[0].name,
                                fileName: acceptedFiles[0].name,
                                restrdicted: false,
                                note: '',
                                hasOverlay: false,
                            },
                        ],
                        techlinecasestatus:
                            caseDetails?.techlineStatusCode === TechlineStatusCode.OPEN_ESCALATED
                                ? TechlineStatusCode.OPEN_ESCALATED
                                : TechlineStatusCode.PENDING_TECHLINE,
                    };
                    const postAssetsRes = await techlineService.postAssets({ assetsBody: assetsPreReqBody });
                    const assetUploadReqParams = postAssetsRes?.data?.results?.assets?.[0]?.presignedUpload;
                    const assetsDetails = postAssetsRes?.data?.results?.assets?.[0]?.asset;
                    if (assetUploadReqParams) {
                        const onProgress = throttle(async (progress: any) => {
                            assetsDetails.percentUploaded = progress;
                            if (progress === 100) {
                                await fetchAssets(caseNumber);
                                if (inProgressAssets[assetsDetails.assetId]) {
                                    setInProgressAssets((preVal: any) => {
                                        delete preVal[assetsDetails.assetId];
                                        return preVal;
                                    });
                                }

                                setSuccessMessage?.('Media has finished uploading!.');
                                scrollToSection(TechlineSections.Attachments);
                                setTimeout(() => {
                                    setSuccessMessage?.('');
                                }, 5000);
                            } else {
                                setInProgressAssets((preVal: any) => {
                                    preVal[assetsDetails.assetId] = assetsDetails;
                                    return { ...preVal };
                                });
                            }
                        }, 500);

                        const assetUploadReqest = await techlineService.uploadAssets({
                            uploadUrl: assetUploadReqParams.url,
                            headers: assetUploadReqParams.httpHeaders,
                            assetsBody: acceptedFiles[0],
                            onProgress,
                        });
                        analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_UPLOADS_ATTACHMENT);
                    }
                }
            } catch (err) {
                setErrorMessage?.('Media uploading failed, Please try again!.');
                setTimeout(() => {
                    setErrorMessage?.('');
                }, 5000);
                alert('Error: ' + err + '. Please refresh & try again.');
                console.log(err);
            }
        },
        [
            caseDetails?.techlineStatusCode,
            caseNumber,
            fetchAssets,
            inProgressAssets,
            scrollToSection,
            setErrorMessage,
            setSuccessMessage,
        ]
    );

    const downloadImage = async (imageSrc: string, fileName: string) => {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onDeleteAssetConfirm = async () => {
        try {
            setEditorAssetIndex(-1);
            showDeleteLoader(true);
            const updateAssetsRes = await techlineService.updateAssets({
                assetsBody: {
                    assets: [
                        {
                            assetId: deleteAsset.assetId,
                            processingState: 'ERROR',
                            percentUploaded: 0,
                        },
                    ],
                },
            });
            showDeleteLoader(false);
            fetchAssets(caseNumber);
            setDeleteAsset(undefined);
        } catch (err) {
            console.log(err);
            showDeleteLoader(false);
            setDeleteAsset(undefined);
        }
    };

    const renderAssetOptions = (assetDetail: any, index: number) => {
        const downloadUri = assetDetail?.media?.find(
            (m: { assetImageTypeId: string }) => m.assetImageTypeId === 'ORIGINAL'
        )?.url;
        const thumbnailUri = assetDetail?.media?.find(
            (m: { assetImageTypeId: string }) => m.assetImageTypeId === 'THUMBNAIL'
        )?.url;
        return (
            <Popover
                isOpen={popoverId === assetDetail.assetId}
                positions={['bottom', 'top']}
                onClickOutside={() => setPopoverId(null)}
                contentLocation={({ childRect, popoverRect, position }) => {
                    // Calculate position of popover based on window height and client position and based on calculation show the popover at top or bottom position
                    if (childRect.y + childRect.height + popoverRect.height > window.innerHeight) {
                        return {
                            top: childRect.top - 4 - popoverRect.height,
                            left: childRect.left - (popoverRect.width - childRect.width),
                        };
                    } else {
                        return {
                            top: childRect.bottom + 4,
                            left: childRect.left - (popoverRect.width - childRect.width),
                        };
                    }
                }}
                content={
                    <div className={classes.assetPopover}>
                        <div className={classes.assetPopoverSection}>
                            <Button
                                alignTitle="left"
                                variant="ghost-gray"
                                onPress={() => {
                                    setViewOnly(true);
                                    setEditorAssetIndex(index);
                                    setPopoverId(null);
                                }}
                            >
                                <>
                                    <Icon name="eye" size={16} />
                                    <Typography style={{ paddingLeft: 12 }} variant="label">
                                        View
                                    </Typography>
                                </>
                            </Button>

                            <Button
                                alignTitle="left"
                                variant="ghost-gray"
                                onPress={() => {
                                    setViewOnly(false);
                                    setEditorAssetIndex(index);
                                    setPopoverId(null);
                                }}
                            >
                                <>
                                    <Icon name="pencil" size={16} />
                                    <Typography style={{ paddingLeft: 12 }} variant="label">
                                        Edit
                                    </Typography>
                                </>
                            </Button>

                            {/* asset.media[0] is original file
                            asset.media[1] is thumbnail file */}
                            {(downloadUri || thumbnailUri) && (
                                <Button
                                    alignTitle="left"
                                    variant="ghost-gray"
                                    onPress={() => {
                                        setPopoverId(null);
                                        downloadImage(downloadUri ? downloadUri : thumbnailUri, assetDetail.name);
                                    }}
                                >
                                    <>
                                        <Icon name="download" size={16} />
                                        <Typography style={{ paddingLeft: 12 }} variant="label">
                                            Download
                                        </Typography>
                                    </>
                                </Button>
                            )}

                            <div className={classes.assetPopoverSection}>
                                <Button
                                    alignTitle="left"
                                    variant="ghost-gray"
                                    onPress={() => {
                                        setPopoverId(null);
                                        setDeleteAsset(assetDetail);
                                    }}
                                >
                                    <>
                                        <Icon name="trash" size={16} color="redOne" />
                                        <Typography style={{ paddingLeft: 12 }} variant="label" color="redOne">
                                            Delete
                                        </Typography>
                                    </>
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            >
                <div>
                    <Button
                        active={popoverId === assetDetail.assetId}
                        variant="ghost-blue"
                        onPress={() => setPopoverId(popoverId === assetDetail.assetId ? null : assetDetail.assetId)}
                    >
                        <Icon name="more-dots-vertical" />
                    </Button>
                </div>
            </Popover>
        );
    };

    const renderInProgressAssets = () => {
        const inProgressAssetIds = Object.keys(inProgressAssets);
        if (inProgressAssetIds.length) {
            return inProgressAssetIds.map((key: string) => {
                const assetDetails = inProgressAssets[key];
                return (
                    <>
                        <div
                            key={assetDetails.assetId}
                            className={`${classes.attachmentsContainer} flex-column d-flex mt-5 p-5`}
                        >
                            <div className="d-flex flex-row align-items-center">
                                <div className="d-flex flex-column ml-5">
                                    <Typography variant="h6">{assetDetails.name}</Typography>
                                    <Typography variant="caption">
                                        {assetDetails.createdTimestampDescription}
                                    </Typography>
                                </div>
                            </div>
                            <div style={{ margin: 10, width: '100%' }}>
                                <AssetProgressBar percent={assetDetails.percentUploaded} canCancel={false} />
                            </div>
                        </div>
                    </>
                );
            });
        }
    };

    const renderFiles = () => {
        return assetsDetails.map((asset: any, index: number) => {
            if (!asset) return null;
            const thumbnailUri = asset.media?.find(
                (m: { assetImageTypeId: string }) => m.assetImageTypeId === 'THUMBNAIL'
            )?.url;
            return (
                <div
                    key={asset.assetId}
                    className={`${classes.attachmentsContainer} d-flex flex-row align-items-center justify-content-between mt-5 p-5`}
                >
                    <div className="d-flex flex-row align-items-center">
                        {thumbnailUri ? (
                            <img height={55} width={55} src={thumbnailUri} alt="" />
                        ) : asset.assetTypeId !== 'IMAGE' ? (
                            <img height={55} width={55} src={placeHolderImage} alt="" />
                        ) : null}
                        {!asset.media?.length && (
                            <ActivityIndicator style={{ margin: 10 }} color={colors.blueOne} size="large" />
                        )}
                        <div className="d-flex flex-column ml-5">
                            <Typography variant="h6">{asset.name}</Typography>
                            <Typography variant="caption">{asset.createdTimestampDescription}</Typography>
                        </div>
                    </div>
                    {!!asset.media?.length && renderAssetOptions(asset, index)}
                </div>
            );
        });
    };

    const {
        getRootProps,
        getInputProps,
        open: openFileInput,
    } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    const dropZoneRootProps = getRootProps();
    const fileInputProps = getInputProps() as FormControlProps;

    return (
        <div>
            <hr className="mt-5 mb-5" color={colors.grayThree} />
            <ConfirmDeleteAssetModal
                show={!!deleteAsset}
                deleteLoader={deleteLoader}
                onConfirm={() => {
                    onDeleteAssetConfirm();
                }}
                onHide={() => {
                    setDeleteAsset(undefined);
                }}
            />
            <div>
                {editorAssetIndex > -1 && (
                    <AssetEditorModal
                        viewOnly={viewOnly}
                        toggleEdit={() => {
                            setViewOnly(!viewOnly);
                        }}
                        onClose={() => {
                            setEditorAssetIndex(-1);
                        }}
                        selectedAssetDetails={assetsDetails[editorAssetIndex]}
                        caseNumber={caseNumber}
                        caseDetails={caseDetails}
                        fetchAssets={fetchAssets}
                        setDeleteAsset={setDeleteAsset}
                        inProgressAssets={inProgressAssets}
                        setInProgressAssets={setInProgressAssets}
                        setErrorMessage={setErrorMessage}
                        setSuccessMessage={setSuccessMessage}
                    />
                )}
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <Typography variant="h3">
                        Attachments <Typography style={{ color: colors.brandSecondary }}>(Optional)</Typography>
                    </Typography>
                    <Typography style={{ color: colors.brandSecondary }}>
                        {assetsDetails?.length || 0} files attached ({assetsSize})
                    </Typography>
                </div>

                {!statusMap['CLOSED'].includes(caseDetails?.techlineStatusCode) && canAddOrEditAttachment && (
                    <Row>
                        <Col>
                            <div
                                className="d-flex align-items-center justify-content-start flex-wrap"
                                {...dropZoneRootProps}
                            >
                                <div className={classNames('mt-4 mb-0', classes.dropZoneArea)}>
                                    Drop files here to attach or &nbsp;
                                    <Button
                                        variant="link"
                                        onPress={() => {
                                            openFileInput();
                                        }}
                                        title={'upload'}
                                    />
                                </div>

                                <Form.Group controlId="attachments" className="mt-4 mb-0">
                                    <Form.Control data-testid="drop-input" as="input" {...fileInputProps} />
                                    <Form.Label />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                )}
                {renderInProgressAssets()}
                {renderFiles()}
            </div>
        </div>
    );
};
