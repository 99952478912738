import React, { FC } from 'react';
import { BadgeVariants, Icon, TextBadge } from '@packages/ui/shared';
import { IconProps } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';

interface BmisBatteryBadgeProps {
    label: string | null | undefined;
    style?: StyleProp<ViewStyle>;
}

const useStyles = createUseStyles({
    labelContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        maxWidth: '20ch',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export const BmisBatteryBadge: FC<BmisBatteryBadgeProps> = ({ label, style }) => {
    const classes = useStyles();
    let variant: BadgeVariants;
    let iconProps: IconProps;

    if (!label) return null;
    const lowerCaseLabel = label.toLowerCase();

    switch (lowerCaseLabel) {
        case 'good battery':
        case 'good recharge':
        case 'in balance':
        case 'pdi complete':
            variant = 'green';
            iconProps = { name: 'check-circle-hollow' as const, color: 'greenOne' };
            break;

        case '24 volt battery':
        case 'aborted':
        case 'aborted/24v':
        case 'charge & retest':
        case 'clamps removed':
        case 'gr8 diagnostic needed':
        case 'lost power':
        case 'marginal battery':
        case 'marginal recharge':
        case 'out of balance':
        case 'temp sensor failed':
            variant = 'yellow';
            iconProps = { name: 'warning' as const, color: 'yellowOne' };
            break;

        case 'bad cell replace':
        case 'bad cell short replace':
        case 'bc open or load fail replace':
        case 'broken weld replace':
        case 'frozen battery':
        case 'replace battery':
        case 'replace load fail':
        case 'too hot replace':
            variant = 'red';
            iconProps = { name: 'x-circle' as const, color: 'redOne' };
            break;

        case 'invalid test':
        case 'remote post':
        case 'side post':
            variant = 'gray';
            iconProps = { name: 'circle-indeterminate' as const, color: 'black' };
            break;

        default:
            variant = 'gray';
            iconProps = { name: 'circle-indeterminate' as const, color: 'black' };
            break;
    }

    const flattenedStyle = StyleSheet.flatten([{ paddingLeft: 8, paddingRight: 8 }, style]);

    return (
        <TextBadge style={flattenedStyle} variant={variant} icon={<Icon {...iconProps} size={16} />}>
            <div className={classes.labelContainer}>
                <span className={classes.label}>{label ? label : 'N/A'}</span>
            </div>
        </TextBadge>
    );
};
