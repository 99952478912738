import { vinSessionService } from '@web/services/singletons';

export const filterList = [
    'QMR Report',
    'techline case',
    'Accessory Installation Guide',
    'Owner manual',
    'On-Board Diagnostics',
    'Maintainance Advisory Bulletin',
    'TechTIPS NewsLetter',
    'Campaign Bulletin',
    'Service Manual',
    'Service Manual Correction',
    'Video',
    'Warranty Bulletin',
    'Technical Reference Booklet',
    'Technical Service Bulletin',
    'Troubleshooting Guide',
    'Warranty Booklet',
    'Service Diagnostics',
];

export const dateRange = ['Today', 'Last 7 days', 'Last 30 days', 'This year', 'Last year', 'Custom date range'];

export function handleVinSessionError(message: string, error?: Error) {
    console.error('[VinSession Error]:', message, error);
}

export function handleSelectedFilters(filter: string, selectedFilters: any, vinSessionActions: any, dispatch: any) {
    if (selectedFilters?.includes(filter)) {
        const updatedFilters = selectedFilters.filter((f: string) => f !== filter);
        dispatch(
            vinSessionActions.setSelectedFilters({
                selectedFilters: updatedFilters,
            })
        );
        return;
    }
    dispatch(
        vinSessionActions.setSelectedFilters({
            selectedFilters: [...selectedFilters, filter],
        })
    );
}

export async function openVinSession(
    vin: string,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
    setIsLoading(true);
    try {
        await vinSessionService.openVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
    } catch (error) {
        handleVinSessionError('Open VIN Session Error', error);
    }
    setIsLoading(false);
}

export async function closeVinSession(
    vin: any,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
    setIsLoading(true);
    try {
        await vinSessionService.closeVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
    } catch (error) {
        handleVinSessionError('Close VIN Session Error', error);
    }
    setIsLoading(false);
}

export async function getVin(vin: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) {
    setIsLoading(true);
    try {
        const response = await vinSessionService.getVinData({
            fetchVinParams: {
                vin: vin,
            },
            ignoreCache: true,
        });
        if (response.success) {
            return response.data;
        }
    } catch (error) {
        window.alert(`Vin Fetching Error: ${error.message}`);
        setIsLoading(false);
    }
}
