import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import constants from '@web/config/constants';
import { BmisAsideContent } from '@web/bmis/components/bmis-aside-content';
import { BmisHeader } from '@web/bmis/components/bmis-header';
import { BmisReportSections } from '@web/bmis/components/bmis-report-sections';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { accountsService } from '@web/services/singletons';

interface BmisLayoutStyleProps {
    children?: React.ReactNode;
    hideAsideContent: boolean;
    hideBmisHeader: boolean;
    hideReportsSection: boolean;
}

const useBmisLayoutStyles = createUseStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    bmisHeader: ({ hideBmisHeader }: BmisLayoutStyleProps) => ({
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grayThree}`,
        display: hideBmisHeader ? 'none' : 'flex',
        flexShrink: 0,
        height: constants.bmisHeaderHeight,
        justifyContent: 'space-between',
        padding: '0 24px',
    }),
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        overflow: 'hidden',
    },
    asideContent: ({ hideAsideContent }: BmisLayoutStyleProps) => ({
        backgroundColor: colors.grayOne,
        borderLeft: `1px solid ${colors.grayThree}`,
        flexBasis: hideAsideContent ? 0 : 400,
        flexShrink: 0,
        overflowY: hideAsideContent ? 'hidden' : 'auto',
    }),
    details: {
        backgroundColor: colors.white,
        flexGrow: 1,
        overflowY: 'auto',
        overflow: 'hidden',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    reportSections: ({ hideReportsSection }: BmisLayoutStyleProps) => ({
        backgroundColor: colors.grayOne,
        flexBasis: hideReportsSection ? 0 : 248,
        flexShrink: 0,
        overflowY: 'auto',
    }),
    '@media only screen and (max-width: 1100px)': {
        reportSections: {
            display: 'none',
        },
    },
});

interface BmisLayoutProps {
    children?: React.ReactNode;
}

export const BmisLayout: FC<BmisLayoutProps> = ({ ...props }) => {
    const { rowGuid } = useParams<{ rowGuid?: string }>();
    const classes = useBmisLayoutStyles({
        hideBmisHeader: !rowGuid,
        hideAsideContent: !rowGuid,
        hideReportsSection: !rowGuid,
    });
    return (
        <div className={classes.wrapper}>
            <div className={classes.bmisHeader}>
                <BmisHeader />
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.reportSections}>
                    <BmisReportSections />
                </div>
                <div className={classes.details}>{props.children}</div>
                <div className={classes.asideContent}>
                    <BmisAsideContent accountService={accountsService} />
                </div>
            </div>
        </div>
    );
};
