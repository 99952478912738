import { HttpClient } from '@packages/core/http';

import {
    Qmr,
    QmrApiSupplement,
    CreateQmrDto,
    UpdateQmrDtcCodesDto,
    UpdateQmrRestrictedAssetIdsDto,
    SaveQmrDto,
    QmrResponseDto,
    QmrListResponseDto,
    QmrStatusSummariesResponseDto,
    DtcCodesResponseDto,
    PartNumbersResponseDto,
    PatchQmrAssetDto,
    DeleteQmrAssetsDto,
    RetailersResponseDto,
    VehiclesResponseDto,
    CarlinesResponseDto,
    GetAssetResponseDto,
    PatchQmrDto,
    Comment,
    QmrQuickFiltersResponseDto,
    RecentQuickSearchesResponseDto,
    AdvancedSearchParametersDto,
    ADVANCED_SEARCH_FIELD_TYPE_ID,
    AdvancedSearchAutocompleteResultsDto,
    AdvancedSearchResultsDto,
    AdvancedSearchSavedSearchesDto,
    AdvancedSearchRecentSearchesDto,
    Retailer,
    QmrInfoRequestDto,
    QmrInfoRequestData,
    RetailerSearchResponseDto,
    RetailerResponseDto,
    Account,
    FailCodeApproverSetting,
    OpenPartCollectRequestResponseDto,
    ClosePartCollectRequestResponseDto,
} from '@packages/models/api';

interface DtcSearchParams {
    query: string;
}

interface CarlineSearchParams {
    query: string;
}

export interface RetailerSearchParams {
    query?: string;
    canCreateQmr?: boolean;
    recent?: boolean;
    techline?: boolean;
}

export interface RetailerListParams {
    page?: number;
    size?: number;
    query?: string;
}

export interface AddAdditionalInformationResponseBody {
    comment: Comment;
}

interface GetAdvancedSearchSavedSearchesParameters {
    size?: number;
    page?: number;
}

export class QmrsService {
    constructor(private http: HttpClient) {}

    fetchDtcCodes({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams: DtcSearchParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/dtc-codes' + this.http.queryString(searchParams);

        return this.http.orchestrateRequest<DtcCodesResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchPartNumbers({
        qmrId,
        query,
        ignoreCache = false,
        signal,
    }: {
        qmrId: string;
        query?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/part-number/search' + this.http.queryString({ qmrId, query });

        return this.http.orchestrateRequest<PartNumbersResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchRetailers({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams: RetailerSearchParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/retailers' + this.http.queryString(searchParams);

        return this.http.orchestrateRequest<RetailersResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    searchRetailers({
        searchParams,
        ignoreCache,
        signal,
    }: {
        searchParams: RetailerListParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/searchable-retailers' + this.http.queryString(searchParams);

        return this.http.orchestrateRequest<RetailerSearchResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchRetailerbyId({
        retailerId,
        ignoreCache,
        signal,
    }: {
        retailerId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<RetailerResponseDto>({
            method: 'GET',
            url: `/retailers/${retailerId}`,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeApproverSettings({ ignoreCache, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<{
            failCodeApproverSettings: FailCodeApproverSetting[];
        }>({
            method: 'GET',
            url: '/retailers/fail-code-approver-settings',
            ignoreCache,
            signal,
        });
    }

    fetchRetailerFailCodeApprovers({
        retailerId,
        ignoreCache,
        signal,
    }: {
        retailerId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<{ accounts: Account[] }>({
            method: 'GET',
            url: `/retailers/${retailerId}/fail-code-approvers`,
            ignoreCache,
            signal,
        });
    }

    updateRetailerApproverSetting({
        retailerId,
        approverSettingId,
        ignoreCache,
        signal,
    }: {
        retailerId: string;
        approverSettingId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest({
            method: 'PUT',
            url: `/retailers/${retailerId}/fail-code-approver-setting/${approverSettingId}`,
            ignoreCache,
            signal,
        });
    }

    updateRetailerFailCodeApprovers({
        retailerId,
        accountIds,
        ignoreCache,
        signal,
    }: {
        retailerId: string;
        accountIds: string[];
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest({
            method: 'PUT',
            url: `/retailers/${retailerId}/fail-code-approvers`,
            body: { accountIds },
            ignoreCache,
            signal,
        });
    }

    checkVin({ vin, ignoreCache = false, signal }: { vin: string; ignoreCache?: boolean; signal?: AbortSignal }) {
        let searchParams: { vin?: string; vinSuffix?: string } = {};

        if (vin.length < 17) {
            searchParams.vinSuffix = vin;
        } else {
            searchParams.vin = vin;
        }

        const url = '/vehicles' + this.http.queryString(searchParams);

        return this.http.orchestrateRequest<VehiclesResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchCarlines({
        searchParams,
        ignoreCache = false,
        signal,
    }: {
        searchParams?: CarlineSearchParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        let url = '/carlines';

        if (searchParams) {
            url += this.http.queryString(searchParams);
        }

        return this.http.orchestrateRequest<CarlinesResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchQmrStatusSummaries(signal?: AbortSignal, ignoreCache = false) {
        return this.http.orchestrateRequest<QmrStatusSummariesResponseDto>({
            method: 'GET',
            url: '/qmrs/status-summaries',
            ignoreCache,
            signal,
        });
    }

    fetchQmrQuickFilters({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<QmrQuickFiltersResponseDto>({
            method: 'GET',
            url: '/qmrs/quick-filters',
            ignoreCache,
            signal,
        });
    }

    fetchRecentQuickSearches({ ignoreCache = false, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<RecentQuickSearchesResponseDto>({
            method: 'GET',
            url: '/qmrs/quick-search-recent',
            ignoreCache,
            signal,
        });
    }

    fetchQmrsList({
        queryString,
        ignoreCache = false,
        signal,
    }: {
        queryString: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = `/qmrs?${queryString}&supplement=${QmrApiSupplement.List}`;

        return this.http.orchestrateRequest<QmrListResponseDto>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchQmr({
        qmrId,
        ignoreCache = false,
        signal,
    }: {
        qmrId: Qmr['qmrId'];
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'GET',
            url: `/qmrs/${qmrId}`,
            ignoreCache,
            signal,
        });
    }

    createQmr(body: CreateQmrDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'POST',
            url: '/qmrs',
            body,
            signal,
        });
    }

    patchQmr({ qmrId, signal, ...body }: { qmrId: Qmr['qmrId']; signal?: AbortSignal } & PatchQmrDto) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'PATCH',
            url: `/qmrs/${qmrId}`,
            signal,
            body,
        });
    }

    saveQmr(qmrId: Qmr['qmrId'], body: SaveQmrDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'PUT',
            url: `/qmrs/${qmrId}`,
            body,
            signal,
        });
    }

    updateQmrDtcCodes(qmrId: Qmr['qmrId'], body: UpdateQmrDtcCodesDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'PUT',
            url: `/qmrs/${qmrId}/dtc-codes`,
            body,
            signal,
        });
    }

    updateQmrRestrictedAssetIds(qmrId: Qmr['qmrId'], body: UpdateQmrRestrictedAssetIdsDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'PUT',
            url: `/qmrs/${qmrId}/restricted-asset-ids`,
            body,
            signal,
        });
    }

    deleteQmr(qmrId: Qmr['qmrId'], signal?: AbortSignal) {
        return this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/qmrs/${qmrId}`,
            signal,
        });
    }

    patchQmrAsset({ assetId, signal, ...body }: { assetId: string; signal?: AbortSignal } & PatchQmrAssetDto) {
        return this.http.orchestrateRequest<GetAssetResponseDto>({
            method: 'PATCH',
            url: `/assets/${assetId}`,
            signal,
            body,
        });
    }

    deleteQmrAssets(qmrId: Qmr['qmrId'], body: DeleteQmrAssetsDto, signal?: AbortSignal) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'DELETE',
            url: `/qmrs/${qmrId}/assets`,
            body,
            signal,
        });
    }

    setQmrNoRepairModeFailCode({
        qmrId,
        locationCode,
        frequencyCode,
        concernCode,
        signal,
    }: {
        qmrId: Qmr['qmrId'];
        locationCode: string;
        frequencyCode: string;
        concernCode: string;
        signal?: AbortSignal;
    }) {
        return this.http.orchestrateRequest<QmrResponseDto>({
            method: 'PUT',
            url: `/qmrs/${qmrId}/fail-code`,
            body: {
                locationCode,
                frequencyCode,
                concernCode,
            },
            signal,
        });
    }

    addAdditionalInformation(qmrId: string, body: string) {
        return this.http.orchestrateRequest<AddAdditionalInformationResponseBody>({
            method: 'PUT',
            url: `/qmrs/${qmrId}/additional-info`,
            body: {
                qmrId,
                body,
            },
        });
    }

    getAdvancedSearchParameters() {
        return this.http.orchestrateRequest<AdvancedSearchParametersDto>({
            method: 'GET',
            url: '/qmrs/search/parameters',
        });
    }

    getAdvancedSearchAutocompleteResults(fieldTypeId: ADVANCED_SEARCH_FIELD_TYPE_ID, query: string) {
        return this.http.orchestrateRequest<AdvancedSearchAutocompleteResultsDto>({
            method: 'GET',
            url: `/qmrs/advanced-search/autocomplete/${fieldTypeId}?query=${query}`,
        });
    }

    postAdvancedSearchQuery(query: any, name?: string, publicSearch?: boolean, rolesToShareSelected?: string[]) {
        return this.http.orchestrateRequest<{ searchId: string }>({
            method: 'POST',
            url: '/qmrs/advanced-search',
            body: { query, name, publicSearch, sharedUserRoles: rolesToShareSelected || [] },
        });
    }

    updateAdvancedSearchQuery(
        searchId: string,
        query: any,
        name?: string,
        publicSearch?: boolean,
        rolesToShareSelected?: string[]
    ) {
        return this.http.orchestrateRequest<{ searchId: string }>({
            method: 'PUT',
            url: `/qmrs/advanced-search/${searchId}`,
            body: { query, name, publicSearch, sharedUserRoles: rolesToShareSelected || [] },
        });
    }

    getAdvancedSearchResultsForSearchId(searchId: string, queryParams: string) {
        return this.http.orchestrateRequest<AdvancedSearchResultsDto>({
            method: 'GET',
            url: `/qmrs/advanced-search/${searchId}?${queryParams}`,
        });
    }

    getAdvancedSearchSavedSearches(queryParameters?: GetAdvancedSearchSavedSearchesParameters) {
        return this.http.orchestrateRequest<AdvancedSearchSavedSearchesDto>({
            method: 'GET',
            url: `/qmrs/advanced-search/saved-searches${this.http.queryString(queryParameters || {})}`,
        });
    }

    getAdvancedSearchRecentSavedSearches() {
        return this.http.orchestrateRequest<AdvancedSearchRecentSearchesDto>({
            method: 'GET',
            url: '/qmrs/advanced-search/recent-saved-searches',
        });
    }

    deleteAdvancedSearchSavedSearch(searchId: string) {
        return this.http.orchestrateRequest<AdvancedSearchResultsDto>({
            method: 'DELETE',
            url: `/qmrs/advanced-search/${searchId}`,
        });
    }
    getRetailerById(retailerId: string) {
        return this.http.orchestrateRequest<{ retailer: Retailer }>({
            method: 'GET',
            url: `/retailers/${retailerId}`,
        });
    }

    getInfoRequests({ qmrId }: { qmrId: Qmr['qmrId'] }) {
        const qs = this.http.queryString({ qmrId });

        return this.http.orchestrateRequest<QmrInfoRequestDto>({
            method: 'GET',
            url: `/qmrs/more-info-request${qs}`,
            ignoreCache: true,
        });
    }

    getInfoRequest({ id }: { id: string }) {
        return this.http.orchestrateRequest<{ data: QmrInfoRequestData }>({
            method: 'GET',
            url: `/qmrs/more-info-request/${id}`,
            ignoreCache: true,
        });
    }

    openInfoRequest({ qmrId, request }: { qmrId: Qmr['qmrId']; request: string }) {
        const body = {
            qmrId,
            request,
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/qmrs/more-info-request',
            body,
        });
    }

    closeInfoRequest({ requestId }: { requestId: string }) {
        const body = {
            requestId,
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/qmrs/more-info-request/close',
            body,
        });
    }

    addCommentOnInfoRequest({
        qmrId,
        comment,
        requestId: qmrMoreInfoRequestId,
    }: {
        qmrId: Qmr['qmrId'];
        comment: string;
        requestId: string;
    }) {
        const body = {
            body: comment,
            qmrId,
            qmrMoreInfoRequestId,
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/qmrs/more-info-request/comments',
            body,
        });
    }

    initiateCsvExport({ qmrs }: { qmrs: Qmr[] | null }, searchId?: string | null) {
        const qmrIds = qmrs ? qmrs.map((qmr) => qmr.qmrId) : null;
        const body = {
            qmrIds,
            ...(searchId ? { searchId } : {}),
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/qmrs/csv-export',
            body,
        });
    }

    openPartCollectionRequest(qmrId: string) {
        return this.http.orchestrateRequest<OpenPartCollectRequestResponseDto>({
            method: 'POST',
            url: `/qmrs/${qmrId}/part-collection-request`,
        });
    }

    closePartCollectionRequest(partCollectionRequestId: string) {
        return this.http.orchestrateRequest<ClosePartCollectRequestResponseDto>({
            method: 'PATCH',
            url: `/qmrs/part-collection-requests/${partCollectionRequestId}`,
        });
    }

    fetchQmrCategories() {
        return this.http.orchestrateRequest<AdvancedSearchResultsDto>({
            method: 'GET',
            url: '/qmrs/categories',
        });
    }
}
