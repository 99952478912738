import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { techlineService } from '@web/services/singletons';
import Loader from '@web/components/loader';
import { Open } from './open';
import { caseStatusMap as statusMap } from '@web/utils';
import { Draft } from './draft';
import { Retailer } from '@packages/models/api/techline';
import { AssetProcessingState } from '@packages/models/api/assets';
import { cloneDeep } from 'lodash';

export const TechlineDetails = () => {
    const { caseNumber } = useParams();

    const [vehicleDetails, setVehicleDetails] = useState<any>();
    const [caseDetails, setCaseDetails] = useState<Retailer>();
    const [assetsDetails, setAssetsDetails] = useState<any>([]);
    const timeoutRef = useRef<any>(null);

    const fetchCaseDetails = async (caseNumber: string) => {
        try {
            const caseDetailsRes: any = await techlineService.getCaseDetails({ caseNumber });

            if (caseDetailsRes?.data && caseDetailsRes.success) {
                if (caseDetailsRes.data.caseDetails) {
                    const isRetailerPushed: any = {};
                    const uniqueRetailerProfiles: any = [];
                    caseDetailsRes.data.caseDetails.retailerProfiles.forEach((item: any) => {
                        if (!isRetailerPushed[item.retailerNumber]) {
                            uniqueRetailerProfiles.push(item);
                            isRetailerPushed[item.retailerNumber] = true;
                        }
                    });
                    caseDetailsRes.data.caseDetails.retailerUniqueProfiles = uniqueRetailerProfiles;
                    setCaseDetails(caseDetailsRes.data.caseDetails);
                }
                if (caseDetailsRes.data.vinDetails?.[0]) {
                    setVehicleDetails(caseDetailsRes.data.vinDetails[0]);
                }
                if (caseDetailsRes.data.caseAssetsDetails?.length) {
                    const assetDetails = caseDetailsRes.data.caseAssetsDetails.map((assetDetails: any) => {
                        return assetDetails?.asset;
                    });
                    setAssetsDetails(
                        assetDetails.filter((a: { assetProcessingStateId: AssetProcessingState }) =>
                            [AssetProcessingState.Complete, AssetProcessingState.Uploading].includes(
                                a.assetProcessingStateId
                            )
                        )
                    );
                }
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    useEffect(() => {
        if (caseNumber) {
            fetchCaseDetails(caseNumber);
        }
    }, [caseNumber]);

    useEffect(() => {
        fetchAssets(caseNumber);

        // Cleanup function to stop recursion on component unload
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [caseNumber]);

    const fetchAssets = useCallback(async (caseNumber: any) => {
        try {
            const retrieveCaseAssetsRes = await techlineService.retrieveCaseAssets({
                caseNumber: caseNumber || '',
            });
            if (retrieveCaseAssetsRes?.data?.results?.length) {
                const assetDetails = retrieveCaseAssetsRes.data.results.map((assetDetails: any) => {
                    return assetDetails?.asset;
                });
                const assetDetailsData = assetDetails.filter((a: { assetProcessingStateId: AssetProcessingState }) =>
                    [AssetProcessingState.Complete, AssetProcessingState.Uploading].includes(a.assetProcessingStateId)
                );

                setAssetsDetails(assetDetailsData);
                const inProgressAssets = assetDetailsData.filter(
                    (asset: any) => asset.assetProcessingStateId === AssetProcessingState.Uploading
                );
                if (!!inProgressAssets?.length) {
                    return new Promise((res) => {
                        pullingAssets(inProgressAssets, 0, res);
                    });
                }
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log('Assets fetching Error: ', err, { caseNumber, assetsDetails });
        }
    }, []);

    const updateAssetsStatus = useCallback(async (assetDetailsData: any) => {
        try {
            const assets = assetDetailsData
                .filter((asset: any) => asset.assetProcessingStateId === AssetProcessingState.Uploading)
                .map((a: any) => {
                    return {
                        assetId: a.assetId,
                        processingState: AssetProcessingState.Error,
                        percentUploaded: a.percentUploaded,
                    };
                });
            const assetsBody = {
                assets,
            };
            if (assets.length) {
                await techlineService.updateAssets({ assetsBody });
            }
        } catch (error) {
            console.log('Assets status update error:', error);
        }
    }, []);

    const pullingAssets = useCallback(
        async (uploadingAssets: any[], count: number = 0, res: (value: unknown) => void) => {
            if (uploadingAssets.length) {
                const responses = await Promise.all(
                    uploadingAssets.map((a) => techlineService.retrieveAsset(a.assetId))
                );
                const otherStatusAssets: any[] = [];
                const newUploadingAssets: any[] = [];
                responses.forEach((r) => {
                    if (r.success) {
                        const asset = r.data?.results?.asset;
                        if (asset?.assetProcessingStateId === AssetProcessingState.Uploading) {
                            newUploadingAssets.push(asset);
                        } else {
                            otherStatusAssets.push(asset);
                        }
                    }
                });

                if (otherStatusAssets.length) {
                    setAssetsDetails((prev: any[]) => {
                        const prevClone = cloneDeep(prev);
                        otherStatusAssets.forEach((a) => {
                            const index = prevClone.findIndex((pa) => pa.assetId === a.assetId);
                            if (index > -1) {
                                if (a.assetProcessingStateId === AssetProcessingState.Error) {
                                    prevClone.splice(index, 1);
                                } else {
                                    prevClone[index] = a;
                                }
                            }
                        });
                        return prevClone;
                    });
                }
                if (count === 5) {
                    await updateAssetsStatus(newUploadingAssets);
                    pullingAssets(newUploadingAssets, (count += 1), res);
                } else if (newUploadingAssets?.length && count < 5) {
                    const inProgressAssets = newUploadingAssets.filter(
                        (asset: any) => asset.assetProcessingStateId === AssetProcessingState.Uploading
                    );
                    if (!!inProgressAssets?.length) {
                        timeoutRef.current = setTimeout(() => {
                            pullingAssets(newUploadingAssets, (count += 1), res);
                        }, 5000);
                    }
                } else {
                    res('');
                }
            } else {
                res('');
            }
        },
        [updateAssetsStatus]
    );

    const renderCaseDetails = () => {
        if (statusMap['DRAFT'].includes(caseDetails?.techlineStatusCode)) {
            return (
                <Draft
                    caseNumber={caseNumber}
                    vehicleDetails={vehicleDetails}
                    caseDetails={caseDetails}
                    assetsDetails={assetsDetails}
                    fetchAssets={fetchAssets}
                    fetchCaseDetails={fetchCaseDetails}
                    setCaseDetails={setCaseDetails}
                />
            );
        }
        return (
            <Open
                caseNumber={caseNumber}
                vehicleDetails={vehicleDetails}
                caseDetails={caseDetails}
                assetsDetails={assetsDetails}
                fetchAssets={fetchAssets}
                fetchCaseDetails={fetchCaseDetails}
            />
        );
    };

    return caseDetails ? renderCaseDetails() : <Loader />;
};
