import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { SkeletonLoader } from '@web/components/skeleton-loader';
import { GestureResponderEvent } from 'react-native';

const useStyles = createUseStyles({
    dataCardFooter: {
        padding: '16px',
        borderTop: '1px solid #E3E3E3',
        marginTop: 'auto',
    },
});

interface DataCardFooterProps {
    buttonLink: string;
    buttonText: string;
    loading?: boolean;
}

export const DataCardFooter: FC<DataCardFooterProps> = ({ buttonLink, buttonText, loading = false }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();

    const dataCardButtonStyle = {
        borderColor: '#1F518F',
        borderWidth: 1,
        paddingLeft: 16,
        paddingRight: 8,
        paddingVertical: 0,
    };

    const handleNavigation = (event: GestureResponderEvent) => {
        event.preventDefault();
        navigate(buttonLink);
    };

    return (
        <div className={classes.dataCardFooter}>
            {loading ? (
                <div className="d-flex justify-content-end mt-auto" data-testid="data-card-footer-loading">
                    <SkeletonLoader type="btn" />
                </div>
            ) : (
                <div
                    className="d-flex justify-content-end mt-auto text-decoration-none"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Button
                        style={dataCardButtonStyle}
                        iconRight={<Icon name="chevron-right" color={isHovered ? 'white' : 'blueOne'} />}
                        title={t(buttonText)}
                        variant="outlinePrimary"
                        onPress={handleNavigation}
                    />
                </div>
            )}
        </div>
    );
};
