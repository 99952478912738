import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { TextStyle } from 'react-native';

const useStyles = createUseStyles({
    emptyState: {
        display: 'flex',
        flexGrow: 1,
        padding: '16px',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
});

export interface DataCardEmptyStateProps {
    titleText: string;
    description: string;
    descriptionStyle?: TextStyle;
    titleStyle?: TextStyle;
}

export const DataCardEmptyState: FC<DataCardEmptyStateProps> = ({
    titleText,
    description,
    titleStyle,
    descriptionStyle,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const titleStyles: TextStyle = {
        fontSize: 13,
        fontWeight: '600',
        ...titleStyle,
    };

    const descriptionStyles: TextStyle = {
        maxWidth: 150,
        fontSize: 11,
        fontWeight: '400',
        ...descriptionStyle,
    };

    return (
        <div className={classes.emptyState}>
            <Typography color="textDarkSecondary" variant="h2" style={titleStyles}>
                {t(titleText)}
            </Typography>
            <Typography color="textDarkSecondary" variant="caption" style={descriptionStyles}>
                {t(description)}
            </Typography>
        </div>
    );
};
