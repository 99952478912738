import React, { FC, useEffect, useCallback, useLayoutEffect, useState, useMemo } from 'react';
import { useParams, useLocation, useNavigate, useMatch, Navigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { get, isEqual } from 'lodash';

import { fetchQmr, useQmrDispatch, useQmrState, useQmrInfoRequests } from '@packages/contexts/qmrs';
import { useMarkNotificationAsReadOnLoad } from '@packages/contexts/notifications';
import { useAbortController } from '@packages/core/http';
import { FailCode, QmrStatusId, TrReplyStatusType } from '@packages/models/api';
import { AlertBar, Typography } from '@packages/ui/shared';

import { failCodesService, notificationsService, qmrsService } from '@web/services/singletons';

import QmrLayout from '@web/qmr/components/qmr-layout';
import VehicleInformationDraft from '@web/qmr/components/vehicle-information-draft';
import VehicleInformationSubmitted from '@web/qmr/components/vehicle-information-submitted';
import Attachments from '@web/qmr/components/attachments';
import ConcernDraft from '@web/qmr/components/concern-draft';
import ConcernSubmitted from '@web/qmr/components/concern-submitted';
import CauseDraft from '@web/qmr/components/cause-draft';
import CauseSubmitted from '@web/qmr/components/cause-submitted';
import CorrectionDraft from '@web/qmr/components/correction-draft';
import CorrectionSubmitted from '@web/qmr/components/correction-submitted';
import DtcCodesSubmitted from '@web/qmr/components/dtc-codes-submitted';
import DtcCodesDraft from '@web/qmr/components/dtc-codes-draft';
import FailCodeReportSection from '@web/qmr/components/fail-code-report-section';
import { AdditionalInformation } from '@web/qmr/components/additional-information';
import { scrollIntoView } from '@web/utils';
import Loader from '@web/components/loader';
import { QmrNotFound } from '@web/qmr/components/qmr-not-found';
import colors from '@packages/core/styles/colors';
import NoMatch from '@web/views/no-match';
import QmrOutcome from '../components/qmr-outcome';
import { useAuthState } from '@packages/contexts/auth';
import PrimaryObjective from '@web/qmr/components/primary-objective';
import { isPrimaryObjectiveEnabled } from '@web/config/constants';

const useQmrDetailStyles = createUseStyles({
    section: {
        padding: `0px 40px`,
    },
    sectionDivider: {
        margin: '24px 40px',
    },
    infoSection: {
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: colors.grayOne,
        borderTop: `1px solid ${colors.grayThree}`,
    },
});

const sectionIdsMap = {
    vehicle: 'vehicle-info',
    primaryObjective: 'primary-objective',
    concern: 'concern',
    cause: 'cause',
    correction: 'correction',
    dtc: 'dtc',
    failCodes: 'fail-codes',
    attachments: 'attachments',
    additionalInfo: 'additional-info',
    qmrOutcome: 'qmr-outcome',
};

const CHECKLIST_CONTAINER_ID = 'report-details';
const CHECKLIST_SECTION_IDS = Object.values(sectionIdsMap);

const QmrDetail: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { displayIdentifier } = useParams<{ displayIdentifier: string }>();
    const qmrDispatch = useQmrDispatch();
    const { abortSignalRef } = useAbortController();
    const { qmr } = useQmrState();
    const classes = useQmrDetailStyles();
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');
    const { hasOpenInfoRequest } = useQmrInfoRequests({ qmrsService });

    const [isFetching, setIsFetching] = useState(false);
    const [qmrFetchRestricted, setQmrFetchRestricted] = useState(false);
    const [showRecentlyCreatedMessage, setShowRecentlyCreatedMessage] = useState(false);
    const [restrictedRecentlyState, setRestrictedRecentlyState] = useState({ restricted: false, unrestricted: false });
    const [matchingFailCode, setMatchingFailCode] = useState<FailCode | null>(null);
    const [showQmrEscalatedTrMessage, setShowQmrEscalatedTrMessage] = useState(false);
    const [showQmrEscalatedFailedMessage, setShowQmrEscalatedFailedMessage] = useState(false);
    const { account } = useAuthState();
    const [toggle, setToggle] = useState<boolean>(false);

    const isSubmitted = useMemo(
        () =>
            !!qmr?.qmrStatus?.qmrStatusId &&
            qmr.qmrStatus.qmrStatusId !== QmrStatusId.Draft &&
            qmr?.createdAccount?.accountId === account?.accountId,
        [qmr, account]
    );
    const isDraft = qmr?.qmrStatus.qmrStatusId === QmrStatusId.Draft;
    const isEditing = isDraft || !!editRouteMatch;
    const refetchDetails = !qmr || isEditing;

    useMarkNotificationAsReadOnLoad(notificationsService);

    const fetchQmrDetail = useCallback(() => {
        if (!displayIdentifier) {
            return;
        }

        setIsFetching(true);

        fetchQmr({
            qmrId: displayIdentifier,
            qmrsService,
            qmrDispatch,
            signal: abortSignalRef.current,
            ignoreCache: true,
        })
            .then(() => {
                setIsFetching(false);
            })
            .catch((e) => {
                setIsFetching(false);

                if (e.code === 'AUTHORIZATION_REQUIRED') {
                    setQmrFetchRestricted(true);
                    return;
                }
            });

        setToggle(false);
    }, [displayIdentifier, qmrDispatch, abortSignalRef, toggle]);

    useLayoutEffect(() => {
        const hashFragment = (location.hash || '').replace('#', '');

        if (hashFragment !== '') {
            scrollIntoView(hashFragment);
        }
    }, [location.hash]);

    useEffect(
        () => {
            fetchQmrDetail();
        },
        // re-fetch qmr when toggling between edit states
        [refetchDetails, fetchQmrDetail]
    );

    useEffect(() => {
        if (location.state && location.state?.isRecent) {
            setShowRecentlyCreatedMessage(true);
        }
    }, [location.state]);

    useEffect(() => {
        const trReplyStatusType = get(qmr, 'trReplyStatus.trReplyStatusId');
        if (get(qmr, 'capabilities.canViewEscalationOnQmr', false)) {
            if (trReplyStatusType && isEqual(trReplyStatusType, TrReplyStatusType.EscalationFailed)) {
                setShowQmrEscalatedFailedMessage(true);
                setShowQmrEscalatedTrMessage(false);
            } else if (!isEqual(trReplyStatusType, TrReplyStatusType.NotEscalated)) {
                setShowQmrEscalatedTrMessage(true);
                setShowQmrEscalatedFailedMessage(false);
            }
        }
    }, [qmr]);

    useEffect(() => {
        if (qmr && qmr.assets && qmr.assets.length > 0) {
            const pending = qmr.assets.filter((asset, index) => asset.media.length == 0);
            if (pending.length > 0) {
                setTimeout(() => {
                    fetchQmrDetail();
                }, 1000);
            }
        }
    }, [qmr]);

    useEffect(() => {
        if (!isEditing) {
            return;
        }

        if (!qmr) {
            return;
        }

        async function checkForMatchingFailCode() {
            if (!qmr) {
                return;
            }

            try {
                const response = await failCodesService.lookupFailCodes({
                    qmrId: qmr.qmrId,
                    partNumber: qmr.partNumber,
                    ignoreCache: true,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                if (!!response.data.failCodes.length) {
                    setMatchingFailCode(response.data.failCodes[0]);
                } else {
                    setMatchingFailCode(null);
                }
            } catch (error) {
                // Fail silently and remove any matching fail code.
                setMatchingFailCode(null);
            }
        }

        checkForMatchingFailCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qmr?.partNumber]);

    if (!qmr && isFetching) {
        return <Loader />;
    }

    if (qmrFetchRestricted) {
        return <QmrNotFound />;
    }

    if (!qmr) {
        return <NoMatch />;
    }

    if (!!editRouteMatch && !qmr.capabilities.editQmr) {
        return <Navigate to={`/qmrs/${qmr.displayIdentifier}`} />;
    } else if (!editRouteMatch && !qmr.capabilities.viewQmr) {
        return <QmrNotFound />;
    }

    const divider = <hr className={classes.sectionDivider} />;

    return (
        <QmrLayout checklistContainerId={CHECKLIST_CONTAINER_ID} checklistSectionIds={CHECKLIST_SECTION_IDS}>
            <AlertBar
                success
                show={showRecentlyCreatedMessage}
                onClose={() => {
                    setShowRecentlyCreatedMessage(false);
                    navigate(location.pathname, {
                        state: {
                            isRecent: false,
                        },
                    });
                }}
            >
                <Typography variant="h5">
                    {t('alerts:qmrCreated.message', 'QMR has been created for {{year}} {{model}} VIN: {{vin}}', {
                        year: qmr.modelYear,
                        model: qmr.carlineName,
                        vin: qmr.vin,
                    })}
                </Typography>
            </AlertBar>

            <AlertBar
                success
                show={restrictedRecentlyState.restricted || restrictedRecentlyState.unrestricted}
                onClose={() => {
                    setRestrictedRecentlyState({
                        restricted: false,
                        unrestricted: false,
                    });
                }}
            >
                <Typography variant="h5">
                    {restrictedRecentlyState.restricted
                        ? t('alerts:qmrResitrcted.message', 'QMR has been marked as Restricted')
                        : t('alerts:qmrUnresitrcted.message', 'QMR has been marked as Unrestricted')}
                </Typography>
            </AlertBar>

            <AlertBar
                error
                show={showQmrEscalatedFailedMessage}
                onClose={() => {
                    setShowQmrEscalatedFailedMessage(false);
                }}
            >
                <Typography variant="h5">
                    {t(
                        'alerts:qmrEscalatedFailed.message',
                        'This QMR failed to escalate to the Technical Reporting System (TR System).'
                    )}
                </Typography>
            </AlertBar>
            <AlertBar
                warning
                show={showQmrEscalatedTrMessage}
                onClose={() => {
                    setShowQmrEscalatedTrMessage(false);
                }}
            >
                <Typography variant="h5">
                    {t(
                        'alerts:qmrEscalatedTr.message',
                        'This QMR was escalated to the Technical Reporting System (TR System). Any changes to this QMR will not be reflected in the TR.'
                    )}
                </Typography>
            </AlertBar>

            {isEditing ? (
                <>
                    <section id={sectionIdsMap.vehicle} className={classNames('pt-6', classes.section)}>
                        <VehicleInformationDraft />
                    </section>

                    {isPrimaryObjectiveEnabled && qmr.capabilities.viewCatOnQmr && (
                        <>
                            {divider}
                            <section
                                id={sectionIdsMap.primaryObjective}
                                className={classNames('pt-6', classes.section)}
                            >
                                <PrimaryObjective viewOnly={!qmr.capabilities.editCatOnQmr} />
                            </section>
                        </>
                    )}

                    {divider}

                    <section id={sectionIdsMap.concern} className={classes.section}>
                        <ConcernDraft />
                    </section>

                    {divider}

                    <section id={sectionIdsMap.cause} className={classes.section}>
                        <CauseDraft />
                    </section>

                    {divider}

                    <section id={sectionIdsMap.correction} className={classes.section}>
                        <CorrectionDraft />
                    </section>

                    {divider}

                    <section id={sectionIdsMap.dtc} className={classes.section}>
                        <DtcCodesDraft />
                    </section>
                    {divider}
                </>
            ) : (
                <>
                    <section id={sectionIdsMap.vehicle} className={classNames('pt-6', classes.section)}>
                        <VehicleInformationSubmitted />
                    </section>

                    {isPrimaryObjectiveEnabled && qmr.capabilities.viewCatOnQmr && (
                        <>
                            {divider}
                            <section
                                id={sectionIdsMap.primaryObjective}
                                className={classNames('pt-6', classes.section)}
                            >
                                <PrimaryObjective viewOnly={true} />
                            </section>
                        </>
                    )}

                    {divider}

                    {qmr.capabilities.viewCccOnQmr && (
                        <>
                            <section id={sectionIdsMap.concern} className={classes.section}>
                                <ConcernSubmitted />
                            </section>

                            {divider}

                            <section id={sectionIdsMap.cause} className={classes.section}>
                                <CauseSubmitted />
                            </section>

                            {divider}

                            <section id={sectionIdsMap.correction} className={classes.section}>
                                <CorrectionSubmitted />
                            </section>
                        </>
                    )}

                    {divider}

                    {qmr.capabilities.viewDtcOnQmr && (
                        <section id={sectionIdsMap.dtc} className={classes.section}>
                            <DtcCodesSubmitted />
                        </section>
                    )}
                    {divider}
                </>
            )}

            {(isDraft || qmr.capabilities.viewFailCodeOnQmr) && (
                <>
                    <section id={sectionIdsMap.failCodes} className={classes.section}>
                        <FailCodeReportSection potentialFailCodeFound={!!matchingFailCode} />
                    </section>

                    {divider}
                </>
            )}

            {qmr.capabilities.viewMediaOnQmr && (
                <>
                    <section id={sectionIdsMap.attachments} className={classes.section}>
                        <Attachments toggle={() => setToggle(true)} />
                    </section>

                    {divider}
                </>
            )}

            {qmr.outcomeAvailable && (
                <>
                    <section id={sectionIdsMap.qmrOutcome} className={classes.section}>
                        <QmrOutcome />
                    </section>

                    {divider}
                </>
            )}

            <section className={classNames(classes.section, 'mt-6')}>
                <AlertBar show={hasOpenInfoRequest} error onClose={() => {}}>
                    <Typography>More information has been requested.</Typography>
                </AlertBar>
            </section>

            {qmr.qmrStatus.qmrStatusId !== QmrStatusId.Draft && (
                <section
                    id={sectionIdsMap.additionalInfo}
                    className={classNames(classes.section, classes.infoSection, 'mt-6')}
                >
                    <AdditionalInformation />
                </section>
            )}
        </QmrLayout>
    );
};

export default QmrDetail;
