import React, { FC, useEffect } from 'react';
import { BmisLayout } from '@web/bmis/components/bmis-layout';
import { Typography } from '@packages/ui/shared';
import { bmisService } from '@web/services/singletons';
import { createUseStyles } from 'react-jss';
import { useBmisDispatch, useBmisState, setRowGuid, setVin } from '@packages/contexts/bmis';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatToTitleCase, getDisplayDate, getDisplayValue, getFormattedTime } from '@web/utils';
import { BmisBatteryBadge } from '../components/bmis-battery-badge';
import Loader from '@web/components/loader';
import { DATE_FORMAT, TIME_FORMAT } from '@web/config/constants';

const useBmisDetailStyles = createUseStyles({
    title: {
        flex: 1,
        display: 'flex',
        padding: '24px 30px',
    },
    content: {
        padding: '0px 30px',
    },
    flexBasis50: {
        flexBasis: '50%',
    },
});

export const BmisDetail: FC = () => {
    const classes = useBmisDetailStyles();
    const bmisDispatch = useBmisDispatch();
    const { selectedBmisRow, loading } = useBmisState();
    const { t } = useTranslation();
    const { vin: vinString, rowGuid } = useParams<{ vin: string; rowGuid?: string }>();

    useEffect(() => {
        if (vinString) {
            setVin(bmisDispatch, bmisService, { vin: vinString });
        }

        if (rowGuid) {
            setRowGuid(bmisDispatch, rowGuid);
        }
    }, [vinString, rowGuid, bmisDispatch]);

    if (!selectedBmisRow && loading) {
        return <Loader />;
    }

    const batteryDetails = [
        {
            label: 'Latest Battery Test Decision',
            value: <BmisBatteryBadge label={selectedBmisRow?.finalBatteryTestDecision} />,
        },
        { label: 'Test Type', value: getDisplayValue(formatToTitleCase(selectedBmisRow?.testType)) },
        {
            label: 'Service Date',
            value: getDisplayDate(selectedBmisRow?.serviceDateandTime, DATE_FORMAT.MON_DAY_YEAR),
        },
        { label: 'Service Time', value: getDisplayDate(selectedBmisRow?.serviceDateandTime, TIME_FORMAT.HH_MM_SS) },
        { label: 'Voltage', value: getDisplayValue(selectedBmisRow?.voltage?.toFixed(1), 'v') },
        { label: 'Initial Voltage', value: getDisplayValue(selectedBmisRow?.initialVoltage?.toFixed(1), 'v') },
        { label: 'Charge Time', value: getFormattedTime(Number(selectedBmisRow?.chargeTime)) },
        { label: 'Amp Hour', value: getDisplayValue(selectedBmisRow?.ampHour?.toFixed(1), ' Ah') },
        { label: 'Battery Type', value: getDisplayValue(formatToTitleCase(selectedBmisRow?.batteryType)) },
        { label: 'Serial Number', value: getDisplayValue(selectedBmisRow?.serialNumber) },
        { label: 'CCA', value: getDisplayValue(selectedBmisRow?.cca, ' Amps') },
        { label: 'Rated CCA', value: getDisplayValue(selectedBmisRow?.ratedCCA, ' Amps') },
        { label: 'Location ID', value: `#${getDisplayValue(selectedBmisRow?.locationId)}` },
        { label: 'Tech ID', value: `${getDisplayValue(selectedBmisRow?.techID)}` },
    ];

    return (
        <BmisLayout>
            <div className={classes.title}>
                <Typography variant="h3">{t('Battery Diagnostics Report')}</Typography>
            </div>
            <div className={classes.content}>
                <div className="d-flex flex-wrap">
                    {batteryDetails.map(({ label, value }, index) => (
                        <div key={index} className={`d-flex flex-column flex-grow-1 pb-6 ${classes.flexBasis50}`}>
                            <div>
                                <Typography variant="label">{t(label)}</Typography>
                            </div>
                            <div className="pt-1">
                                <Typography>{value}</Typography>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </BmisLayout>
    );
};
