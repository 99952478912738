import React, { FC, useState, useEffect, useCallback } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import {
    storageService,
    configService,
    qmrsService,
    notificationsService,
    authService,
    analyticsService,
} from '@web/services/singletons';
import Debugger from '@web/components/debugger';
import NotificationsPanel from '@web/components/notifications-panel';
import { Button, Icon, Typography } from '@packages/ui/shared';

import { useAbortController } from '@packages/core/http';
import { WebStorageKeys } from '@packages/core/storage';
import { displayNotificationCount } from '@packages/core/utils';
import { useAuthDispatch, authActions, useAuthState, fetchAuthUserRetailers } from '@packages/contexts/auth';
import {
    useNotificationsState,
    fetchNotificationsSummary,
    useNotificationsDispatch,
} from '@packages/contexts/notifications';

import colors from '@packages/core/styles/colors';

import { AccountStatusId, NotificationStatusId } from '@packages/models/api';

import { useInterval } from '@packages/ui/shared';
import { ReactComponent as StsLogo } from '@packages/ui/assets/images/techshare-logo-white.svg';
import AccountSettingsModal from './account-settings-modal';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { usePushNotifications } from '@web/core/hooks';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useClickState } from '@web/core/navigation/navigation.contex';
import VinSessionModal from '@web/vin-sessions/components/vin-session-modal';
import { useVinSessionDispatch, useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { VinSessionButton } from '@web/vin-sessions/components/vin-session-button';
import { vinSessionActions } from '@web/vin-sessions/context/vin-sessions.state';
import VinSessionSearchBar from '@web/vin-sessions/components/vin-session-header';

type HeaderProps = {
    vinSessionLoader: boolean;
    setVinSessionLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

const useHeaderStyles = createUseStyles({
    header: {
        zIndex: 101, // PopOver component having z-index: 100 so we have to set 101 for header.
        width: '100%',
        display: 'flex',
        padding: '8px 30px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.blueOne,
        borderTop: `8px solid ${colors.blueZero}`,
        '& p': {
            color: 'white',
        },
    },
    headerRight: {
        display: 'flex',
        alignItems: 'center',
    },
    debuggerOuter: {
        marginRight: 12,
        display: 'flex',
        alignItems: 'center',
    },
    branding: {
        margin: 0,
        '& a': {
            color: colors.white,
        },
    },
    accountDropdownToggle: {
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:after': {
            display: 'none',
        },
        marginLeft: '10px',
    },
    accountDropdownMenu: {
        width: 280,
    },
    roleInformation: {
        padding: '16px 16px 10px',
    },
    retailerInformation: {
        padding: 16,
    },
    dropdownControls: {
        padding: '8px 0',
    },
    dropdownItem: {
        display: 'flex',
        padding: '10px 16px',
        alignItems: 'center',
    },
    dropdownBorderOuter: {
        padding: '0 16px',
    },
    verticalRule: {
        width: 1,
        height: 24,
        opacity: 0.24,
        margin: '0 10px',
        backgroundColor: colors.white,
    },
    notificationsBadge: ({ count }: { count: number }) => {
        return {
            top: 1,
            right: 10,
            height: 18,
            minWidth: 18,
            display: count ? 'flex' : 'none',
            borderRadius: 9,
            padding: '0 5px',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
            backgroundColor: colors.redOne,
        };
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#436CA1',
        color: colors.white,
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        fontSize: '15px',
        lineHeight: '24px',
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvent: 'none',
    },
    '@media(max-width: 600px)': {
        accountDropdownToggle: {
            width: '55px',
            height: '40px',
            borderRadius: '5px !important',
            padding: '10px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '400',
            fontSize: 15,
            lineHeight: 20,
            fontFamily: 'Roboto',
            '&:after': {
                display: 'none',
            },
            marginLeft: 10,
        },
        header: {
            padding: '8px 20px',
        },
    },
});

const Header: FC<HeaderProps> = (props) => {
    const { vinSessionLoader, setVinSessionLoader } = props;
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const authDispatch = useAuthDispatch();
    const { account } = useAuthState();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const navigate = useNavigate();
    const vinSessionRouteMatch = useMatch('/vin/*');

    const { notificationSummary } = useNotificationsState();

    const notificationsDispatch = useNotificationsDispatch();

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [initialTab, setInitialTab] = useState('profile');
    const { isMobileDevice } = useClickState();
    const [vinSessionModal, setVinSessionModal] = useState<boolean>(false);

    const { isVinLoading } = useVinSessionState();
    const dispatch = useVinSessionDispatch();

    const classes = useHeaderStyles({
        count: notificationSummary[NotificationStatusId.New].count,
        showNotifications: toggledOverlays.notifications,
    });

    useEffect(() => {
        if (!account) {
            return;
        }

        fetchAuthUserRetailers({
            searchParams: { canCreateQmr: true },
            qmrsService,
            authDispatch,
            signal: abortSignalRef.current,
        });

        fetchNotificationsSummary({
            notificationsService,
            notificationsDispatch,
            signal: abortSignalRef.current,
            ignoreCache: true,
        });
    }, [abortSignalRef, account, authDispatch, notificationsDispatch]);

    useInterval(() => {
        fetchNotificationsSummary({
            notificationsService,
            notificationsDispatch,
            signal: abortSignalRef.current,
            ignoreCache: true,
        });
    }, 1000 * 60);

    const pushNotificationHandler = useCallback(
        (notification: any) => {
            if (notification.notificationTypeId === 'NOTIFICATION_CREATED') {
                fetchNotificationsSummary({
                    notificationsService,
                    notificationsDispatch,
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                });
            }
        },
        [abortSignalRef, notificationsDispatch]
    );
    usePushNotifications(pushNotificationHandler);

    function handleLogOutButtonClick() {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_TO_LOGOFF);
        storageService.removeItem(WebStorageKeys.AccessToken);
        authService.logout(account?.accountId || '');
        authDispatch(authActions.resetState());
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_LOGS_OFF);
        navigate('/');
    }

    function handleUserDocClick() {
        navigate('/user-articles');
    }

    function handleNotificationsPress() {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_NOTIFICATION_LINK);
        toggleOverlay('notifications');

        fetchNotificationsSummary({
            notificationsService,
            notificationsDispatch,
            signal: abortSignalRef.current,
            ignoreCache: true,
        });
    }

    function refetchNotifications() {
        fetchNotificationsSummary({
            notificationsService,
            notificationsDispatch,
            signal: abortSignalRef.current,
            ignoreCache: true,
        });
    }

    const openModalWithNotificationsTab = () => {
        setInitialTab('notifications');
        setShowSettingsModal(true);
    };

    const extractAccountInitials = (fullName: String) => {
        const words = fullName.split(' ');
        const initials = words.map((word) => word.charAt(0)).join('');
        return initials;
    };

    const canViewUserDocs =
        account?.systemCapabilities.manageDocumentation || account?.systemCapabilities.viewDocumentation;
    const canViewDebugger =
        configService.debugEnvName === 'production'
            ? account?.baseRoleIds.includes('LAG_SUPER_ADMIN')
            : configService.buildDebugEnabled;
    return (
        <>
            {toggledOverlays.notifications && (
                <NotificationsPanel
                    handleNotificationsPress={handleNotificationsPress}
                    refetchNotifications={refetchNotifications}
                    openNotificationsSettingsModal={openModalWithNotificationsTab}
                />
            )}

            {vinSessionModal &&
                (configService.debugEnvName === 'local' || configService.debugEnvName === 'development') && (
                    <VinSessionModal
                        show={vinSessionModal && !isVinLoading}
                        onHide={() => setVinSessionModal(false)}
                        isLoading={vinSessionLoader}
                        setIsLoading={setVinSessionLoader}
                    />
                )}

            <AccountSettingsModal
                show={showSettingsModal}
                onHide={() => {
                    setShowSettingsModal(false);
                }}
                initialActiveTabKey={initialTab}
                handleSetInitialTab={() => {
                    setInitialTab('profile');
                }}
            />

            <header className={classes.header}>
                <h5 className={classes.branding}>
                    <Link to={'/'}>
                        <StsLogo />
                    </Link>
                </h5>

                {(configService.debugEnvName === 'local' || configService.debugEnvName === 'development') && (
                    <VinSessionSearchBar
                        onHide={() => setVinSessionModal(false)}
                        isLoading={vinSessionLoader}
                        setIsLoading={setVinSessionLoader}
                    />
                )}

                <div className={classes.headerRight}>
                    {!vinSessionRouteMatch && !isVinLoading && canViewDebugger && (
                        <div className={classes.debuggerOuter}>
                            <Debugger />
                        </div>
                    )}

                    {(configService.debugEnvName === 'local' || configService.debugEnvName === 'development') && (
                        <VinSessionButton
                            setVinSessionModal={setVinSessionModal}
                            isLoading={vinSessionLoader}
                            setIsLoading={setVinSessionLoader}
                        />
                    )}

                    {!isMobileDevice && (
                        <Button active={toggledOverlays.notifications} onPress={handleNotificationsPress}>
                            <>
                                <div
                                    style={{
                                        height: '36px',
                                        width: '24px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon style={{ height: '24px', width: '24px' }} name="bell" color="white" />
                                </div>
                                <span className={classes.notificationsBadge}>
                                    <Typography variant="caption" color="white">
                                        {displayNotificationCount(notificationSummary[NotificationStatusId.New].count)}
                                    </Typography>
                                </span>
                            </>
                        </Button>
                    )}
                    {account && (
                        <Dropdown>
                            <Dropdown.Toggle className={classes.accountDropdownToggle} id="account-dropdown-toggle">
                                <div className={classes.avatar}>
                                    <p
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '15px',
                                            textAlign: 'center',
                                            lineHeight: '24px',
                                            marginTop: '11px',
                                        }}
                                    >
                                        {extractAccountInitials(account.name)}
                                    </p>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className={classes.accountDropdownMenu} alignRight>
                                {account.profiles.length > 1 ? (
                                    <div className={classes.retailerInformation}>
                                        <Typography variant="label">
                                            {t(
                                                'header:dropdownMenu.multipleRetailerLocations',
                                                'Multiple Retailer Locations'
                                            )}
                                        </Typography>
                                    </div>
                                ) : account.profiles.length === 1 ? (
                                    <>
                                        <div className={classes.roleInformation}>
                                            <Typography variant="labelRegular">
                                                {account.profiles[0].roleDescription}
                                            </Typography>
                                        </div>
                                        {account.profiles[0].details.length > 0 ? (
                                            <>
                                                <div className={classes.dropdownBorderOuter}>
                                                    <Dropdown.Divider />
                                                </div>
                                                <div className={classes.retailerInformation}>
                                                    <Typography variant="label">
                                                        {account.profiles[0].overview}
                                                    </Typography>
                                                    {account.profiles[0].details.map((detail, detailIdx) => {
                                                        const isFirstDetail = detailIdx === 0;
                                                        const hasMoreDetails =
                                                            isFirstDetail && account.profiles[0].details.length > 1;

                                                        const renderedDetail = (
                                                            <Typography
                                                                variant="labelRegular"
                                                                color={'textDarkSecondary'}
                                                            >
                                                                {detail}
                                                            </Typography>
                                                        );

                                                        return hasMoreDetails ? (
                                                            <div className="mb-1">{renderedDetail}</div>
                                                        ) : (
                                                            renderedDetail
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}

                                <div className={classes.dropdownBorderOuter}>
                                    <Dropdown.Divider />
                                </div>
                                <div className={classes.dropdownControls}>
                                    {account.accountStatusId !== AccountStatusId.New && (
                                        <Dropdown.Item
                                            className={classes.dropdownItem}
                                            onClick={() => {
                                                setShowSettingsModal(true);
                                            }}
                                        >
                                            <Icon name="gear" size={16} style={{ marginRight: 16 }} />
                                            <Typography variant="labelRegular">
                                                {t('header:dropdownMenu.accountSettings', 'Account Settings')}
                                            </Typography>
                                        </Dropdown.Item>
                                    )}

                                    {canViewUserDocs && (
                                        <Dropdown.Item className={classes.dropdownItem} onClick={handleUserDocClick}>
                                            <Icon name="administration" size={16} style={{ marginRight: 16 }} />
                                            <Typography variant="labelRegular">
                                                {t('header:dropdownMenu.userDocs', 'User Documentation')}
                                            </Typography>
                                        </Dropdown.Item>
                                    )}

                                    <Dropdown.Item className={classes.dropdownItem} onClick={handleLogOutButtonClick}>
                                        <Icon name="logout" size={16} style={{ marginRight: 16 }} />
                                        <Typography variant="labelRegular">
                                            {t('header:dropdownMenu.logout', 'Logout')}
                                        </Typography>
                                    </Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
