import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';
import { useQmrState } from '@packages/contexts/qmrs';

const QmrOutcome: FC = () => {
    const { account } = useAuthState();
    const { qmr } = useQmrState();
    const { t } = useTranslation();
    const { canViewQMROutcomeRetailer, canViewQMROutcomeSneSdc, canViewQMROutcomeSoaNasi } =
        account?.systemCapabilities || {};

    if (!qmr) {
        return null;
    }

    // The backend concatenates the outcome with the label for Retailer/Technician/Corporate
    // and SNE/SDC DSQMs, so we need to remove the label from the outcome before displaying it.
    function removeLabelFromOutcome(outcome: string) {
        if (!outcome) {
            return null;
        }
        const labels = ['Retailer/Technician/Corporate Message:', 'SNE/SDC DSQMs Message:'];
        for (const label of labels) {
            if (outcome.startsWith(label)) {
                return outcome.slice(label.length).trim();
            }
        }
        return outcome;
    }

    return (
        <>
            <Typography variant="h4">{t('qmr:sections.qmrOutcome.title', 'QMR Outcome')}</Typography>
            {canViewQMROutcomeRetailer && qmr.outcomeRetailerTechnicianCorporate && (
                <div className="my-4">
                    <div>
                        <Typography variant="h6">Retailer/Technician/Corporate Message:</Typography>
                    </div>
                    <div className="my-2">
                        <Typography>{removeLabelFromOutcome(qmr.outcomeRetailerTechnicianCorporate)}</Typography>
                    </div>
                </div>
            )}
            {canViewQMROutcomeSoaNasi && qmr.outcomeSoaDsqmNasi && (
                <div className="my-4">
                    <div>
                        <Typography variant="h6">SOA DSQM/NASI Message:</Typography>
                    </div>
                    <div className="my-2">
                        <Typography>{removeLabelFromOutcome(qmr.outcomeSoaDsqmNasi)}</Typography>
                    </div>
                </div>
            )}
            {canViewQMROutcomeSneSdc && qmr.outcomeSneSdcDsqm && (
                <div className="my-4">
                    <div>
                        <Typography variant="h6">SNE/SDC DSQMs Message:</Typography>
                    </div>
                    <div className="my-2">
                        <Typography>{removeLabelFromOutcome(qmr.outcomeSneSdcDsqm)}</Typography>
                    </div>
                </div>
            )}
        </>
    );
};

export default QmrOutcome;
