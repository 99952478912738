import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, StsIconName, TextBadge, Typography } from '@packages/ui/shared';
import { DetailViewHeader } from '@web/components/detail-view';
import { DimensionValue, StyleSheet, TouchableOpacity } from 'react-native';
import { WorkSheetStatusEnum } from '@packages/models/api';
import { WorksheetForm } from '@packages/models/api/worksheets';
import { worksheetsService } from '@web/services/singletons';
import { WorksheetModal } from './worksheet-modal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WorksheetAction } from '../types';
import { DateTime } from 'luxon';
import { SelectPopover } from './selectPopover';
import { useAuthState } from '@packages/contexts/auth';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';
import { statusVariants } from './worksheets-table';
import colors from '@packages/core/styles/colors';
import useWorksheetPermission from '../hooks/use-worksheet-permission';

interface WorksheetFormHeaderProps {
    handlePreview?: () => void;
    handleSaveAndExit: (isExit: boolean) => void;
    handleSaveButtonPress?: () => void;
    icon?: StsIconName;
    worksheetInfo?: WorksheetForm;
    worksheetDisplayVersion: number;
    worksheetFormId?: string;
    worksheetStatus: WorkSheetStatusEnum;
    isLoading: { saveAndExit: boolean; save: boolean };
    isCaseTypeLoading?: boolean;
    isPreview: boolean;
}

export const WorksheetFormHeader: FC<WorksheetFormHeaderProps> = ({
    handlePreview,
    handleSaveAndExit,
    worksheetInfo,
    worksheetDisplayVersion,
    icon,
    worksheetFormId,
    worksheetStatus,
    isLoading,
    isCaseTypeLoading,
    isPreview,
}) => {
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(WorkSheetStatusEnum.Pending);
    const [isStatusUpdated, setIsStatusUpdated] = useState({ published: false, other: false, successfully: false });
    const [showUpdatedStatus, setShowUpdatedStatus] = useState<boolean>(false);
    const [modalBodyMessage, setModalBodyMessage] = useState<string>('');
    const [showDeleteWorksheetFormModal, setShowDeleteWorksheetFormModal] = useState<boolean>(false);
    const { modalDataTranslate, dataTranslate } = useWorksheetFormTranslation();

    const somethingWrongMsg = modalDataTranslate.commonBodyMessage.somethingWrong;
    const loading = useMemo(() => {
        return isLoading.save || isLoading.saveAndExit;
    }, [isLoading]);
    //permission for statusUpdate and deleteWorksheet api call
    const { account } = useAuthState();
    const { isEditOnlyPermission, isDeleteOnlyPermission } = useWorksheetPermission();

    const permissionsForApiCalls = useMemo(() => {
        const permissions = {
            statusUpdate: false,
            deleteWorksheet: false,
        };
        if (account) {
            const {
                techlineCapabilities: { canEditWorkSheetsTechlineCase, canDeleteWorkSheetsTechlineCase },
            } = account;
            permissions.statusUpdate = canEditWorkSheetsTechlineCase;
            permissions.deleteWorksheet = canDeleteWorkSheetsTechlineCase;
        }
        return permissions;
    }, [account]);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const action = searchParams.get('action') as WorksheetAction;

    useEffect(() => {
        setSelectedStatus(worksheetStatus);
    }, []);

    const statusOfWorksheet = useMemo(
        () => ({
            isArchivedWorksheet: worksheetStatus === WorkSheetStatusEnum.Archived,
            isPublishedWorksheet: worksheetStatus === WorkSheetStatusEnum.Published,
            isPendingWorksheet: worksheetStatus === WorkSheetStatusEnum.Pending,
            isPilotWorksheet: worksheetStatus === WorkSheetStatusEnum.Pilot,
        }),
        [worksheetStatus]
    );

    const actionOnWorksheet = useMemo(
        () => ({
            isNewWorksheet: action === WorksheetAction.New,
            isEditWorksheet: action === WorksheetAction.Edit,
            isAddVersionWorksheet: action === WorksheetAction.AddVersion,
        }),
        [action]
    );

    const disableFields = useMemo(() => {
        const { isNewWorksheet, isAddVersionWorksheet } = actionOnWorksheet;
        const { isPublishedWorksheet, isPendingWorksheet, isArchivedWorksheet } = statusOfWorksheet;
        const isAddVersionNewWorksheet = isAddVersionWorksheet && isPendingWorksheet;
        console.log('Admin Worksheet Debugging - isAddVersionNewWorksheet Status: ' + isAddVersionNewWorksheet);
        return {
            isMenuDisable: isNewWorksheet || isAddVersionNewWorksheet || isArchivedWorksheet,
            isPublishButtonDisable: isNewWorksheet || isPublishedWorksheet || isAddVersionNewWorksheet,
            isEditButtonDisable: (isPreview && !statusOfWorksheet.isPendingWorksheet) || !isEditOnlyPermission,
            isDeleteButtonDisable: !isDeleteOnlyPermission,
        };
    }, [actionOnWorksheet, isPreview, statusOfWorksheet, isEditOnlyPermission, isDeleteOnlyPermission]);
    const relativeTime = DateTime.fromISO(worksheetInfo?.lastUpdatedTimestamp || new Date().toISOString()).toRelative();

    const actionMenu = useMemo(() => {
        const menu: { label: string; value: WorkSheetStatusEnum }[] = [];
        const archived = { label: dataTranslate.statusActions.archived, value: WorkSheetStatusEnum.Archived };
        const pilot = { label: dataTranslate.statusActions.sendToPilot, value: WorkSheetStatusEnum.Pilot };
        const addVersion = { label: dataTranslate.statusActions.addVersion, value: WorkSheetStatusEnum.AddVersion };

        const { isPendingWorksheet, isPilotWorksheet, isPublishedWorksheet } = statusOfWorksheet;

        if (isPendingWorksheet) {
            menu.push(pilot);
        } else if (isPublishedWorksheet) {
            menu.push(addVersion, archived);
        } else if (isPilotWorksheet) {
            menu.push(addVersion, archived);
        }
        return menu;
    }, [
        dataTranslate.statusActions.addVersion,
        dataTranslate.statusActions.archived,
        dataTranslate.statusActions.sendToPilot,
        statusOfWorksheet,
    ]);

    const updateWorksheetFormStatus = useCallback(
        async (status: WorkSheetStatusEnum) => {
            if (!worksheetFormId) return;

            if (!permissionsForApiCalls.statusUpdate) {
                setModalBodyMessage(modalDataTranslate.commonBodyMessage.permission.updateStatus);
                setShowUpdatedStatus(true);
                return;
            }
            // when status update to add version navigate to create new worksheet
            if (status === WorkSheetStatusEnum.AddVersion) {
                navigate(
                    `/administration/worksheet/${worksheetFormId}/edit?status=${WorkSheetStatusEnum.Pending}&action=${WorksheetAction.AddVersion}&maxVersion=${worksheetInfo?.version}`
                );
                return;
            }
            if (status === WorkSheetStatusEnum.Published) {
                setIsStatusUpdated((prev) => ({ ...prev, published: true, successfully: false }));
            } else {
                setIsStatusUpdated((prev) => ({ ...prev, other: true, successfully: false }));
            }
            const body = {
                worksheetId: worksheetFormId,
                worksheetStatus: status,
            };
            try {
                let response;

                // When status update to archived worksheet then updateWorksheetFormStatusArchived api call  other wise updateWorksheetFormStatus api call
                if (status === WorkSheetStatusEnum.Archived) {
                    response = await worksheetsService.updateWorksheetFormStatusArchived(worksheetFormId);
                } else {
                    response = await worksheetsService.updateWorksheetFormStatus(body);
                }

                if (!response.success && response.aborted) {
                    setModalBodyMessage(somethingWrongMsg);
                    return;
                } else if (!response.success) {
                    setModalBodyMessage(response.data.message || somethingWrongMsg);
                } else {
                    setModalBodyMessage(
                        modalDataTranslate.updateStatus.bodyMessage(response.data.worksheetStatus || status)
                    );
                    setSelectedStatus(status);
                    setIsStatusUpdated((prev) => ({ ...prev, successfully: true }));
                }
                setShowUpdatedStatus(true);
                setIsStatusUpdated((prev) => ({ ...prev, other: false, published: false }));
            } catch (error) {
                console.error(error);
                setShowUpdatedStatus(true);
                setModalBodyMessage(somethingWrongMsg);
                setIsStatusUpdated((prev) => ({ ...prev, other: false, published: false }));
            }
        },
        [
            worksheetFormId,
            permissionsForApiCalls.statusUpdate,
            modalDataTranslate.commonBodyMessage.permission.updateStatus,
            modalDataTranslate.updateStatus,
            navigate,
            worksheetInfo?.version,
            somethingWrongMsg,
        ]
    );

    const deleteWorksheetForm = useCallback(async () => {
        if (!worksheetFormId) {
            return;
        }

        const res = await worksheetsService.deleteWorksheetForm(worksheetFormId);
        if (res.success) {
            navigate('/administration/worksheets');
        }
    }, [navigate, worksheetFormId]);

    return (
        <>
            <WorksheetModal
                show={showUpdatedStatus}
                title={modalDataTranslate.updateStatus.title}
                onHideModal={() => setShowUpdatedStatus(false)}
                bodyMessage={modalBodyMessage}
                confirmButton="Ok"
                handleAction={(accepted: boolean) => {
                    setShowUpdatedStatus(false);
                    if (isStatusUpdated.successfully) {
                        navigate('/administration/worksheets');
                    }
                }}
            />

            <WorksheetModal
                show={showDeleteWorksheetFormModal}
                title={modalDataTranslate.deleteElement.title}
                {...(permissionsForApiCalls.deleteWorksheet
                    ? { warningMessage: modalBodyMessage }
                    : { bodyMessage: modalBodyMessage })}
                onHideModal={() => setShowDeleteWorksheetFormModal(false)}
                confirmButton={modalDataTranslate.deleteElement.confirmButton}
                cancelButton={modalDataTranslate.deleteElement.cancelButton}
                handleAction={(accepted) => {
                    if (accepted && permissionsForApiCalls.deleteWorksheet) {
                        deleteWorksheetForm();
                    }
                    setShowDeleteWorksheetFormModal(false);
                }}
            />

            <DetailViewHeader
                breadcrumbs={[
                    {
                        to: '/administration/worksheets',
                        title: dataTranslate.breadcrumbs.manageWorksheet,
                    },
                    {
                        to: `/administration/worksheets`,
                        title: dataTranslate.breadcrumbs.worksheet,
                    },
                    !worksheetFormId
                        ? {
                              to: `/administration/worksheet/new`,
                              title: dataTranslate.breadcrumbs.newWorksheetForm,
                          }
                        : {
                              to: `/administration/worksheet/${worksheetFormId}`,
                              title: dataTranslate.breadcrumbs.editWorksheetForm,
                          },
                ]}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center ">
                        <Typography variant="h3" style={{ marginRight: '15px' as DimensionValue }}>
                            {`${worksheetInfo?.displayname} ${
                                actionOnWorksheet.isAddVersionWorksheet && statusOfWorksheet.isPendingWorksheet
                                    ? `(Adding New Version from existing v${worksheetDisplayVersion})`
                                    : `(v${worksheetDisplayVersion})`
                            }`}
                        </Typography>
                        <TextBadge variant={statusVariants[selectedStatus]}>{selectedStatus}</TextBadge>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center"
                        style={{
                            columnGap: '25px',
                        }}
                    >
                        {!actionOnWorksheet.isNewWorksheet && (
                            <Typography variant="caption">save a {relativeTime}</Typography>
                        )}
                        <SelectPopover
                            isOpen={showStatusModal}
                            options={
                                <>
                                    {actionMenu.map((menu) => {
                                        return (
                                            <Button
                                                variant="ghost-gray"
                                                onPress={() => {
                                                    updateWorksheetFormStatus(menu.value);
                                                }}
                                                alignTitle="left"
                                                title={menu.label}
                                                key={menu.value}
                                            />
                                        );
                                    })}
                                </>
                            }
                            setShow={(isShow) => setShowStatusModal(isShow)}
                        >
                            <div>
                                <Button
                                    variant="link"
                                    onPress={() => setShowStatusModal((prev) => !prev)}
                                    disabled={disableFields.isMenuDisable || loading}
                                    isLoading={isStatusUpdated.other}
                                >
                                    <Icon
                                        size={30}
                                        name="more-dots-vertical"
                                        color="blueOne"
                                        raisedContainerStyle={{ width: 22, height: 22 }}
                                    />
                                </Button>
                            </div>
                        </SelectPopover>
                        <div className="ml-2">
                            <TouchableOpacity
                                onPress={handlePreview}
                                disabled={disableFields.isEditButtonDisable || loading}
                            >
                                <Icon
                                    size={30}
                                    name={icon || 'eye'}
                                    color={disableFields.isEditButtonDisable ? 'grayFive' : 'blueOne'}
                                    raisedContainerStyle={{ width: 22, height: 22 }}
                                    disabled={disableFields.isEditButtonDisable || loading}
                                />
                            </TouchableOpacity>
                        </div>
                        <Button
                            variant="ghost-blue"
                            onPress={() => handleSaveAndExit(true)}
                            isLoading={isLoading.saveAndExit}
                            disabled={isCaseTypeLoading || loading || disableFields.isEditButtonDisable}
                            style={styles.primaryOutlineBtn}
                        >
                            {dataTranslate.adminFormList.buttons.saveAndExit}
                        </Button>

                        <Button
                            variant="primary"
                            onPress={() => handleSaveAndExit(false)}
                            isLoading={isLoading.save}
                            disabled={isCaseTypeLoading || loading || disableFields.isEditButtonDisable}
                        >
                            {dataTranslate.adminFormList.buttons.save}
                        </Button>

                        {!statusOfWorksheet.isArchivedWorksheet && (
                            <div className="ml-2">
                                <Button
                                    isLoading={isStatusUpdated.published}
                                    onPress={() => {
                                        updateWorksheetFormStatus(WorkSheetStatusEnum.Published);
                                    }}
                                    disabled={disableFields.isPublishButtonDisable || loading}
                                >
                                    {dataTranslate.adminFormList.buttons.publish}
                                </Button>
                            </div>
                        )}

                        {statusOfWorksheet.isPendingWorksheet && actionOnWorksheet.isEditWorksheet && (
                            <div className="ml-2">
                                <TouchableOpacity
                                    onPress={() => {
                                        setModalBodyMessage(
                                            !permissionsForApiCalls.deleteWorksheet
                                                ? modalDataTranslate.commonBodyMessage.permission.delete
                                                : modalDataTranslate.deleteWorksheet.bodyMessage
                                        );
                                        setShowDeleteWorksheetFormModal(true);
                                    }}
                                    disabled={disableFields.isDeleteButtonDisable || loading}
                                >
                                    <Icon
                                        size={30}
                                        name="trash"
                                        color={disableFields.isDeleteButtonDisable ? 'grayFive' : 'blueOne'}
                                        raisedContainerStyle={{ width: 22, height: 22 }}
                                        disabled={disableFields.isDeleteButtonDisable || loading}
                                    />
                                </TouchableOpacity>
                            </div>
                        )}
                    </div>
                </div>
            </DetailViewHeader>
        </>
    );
};

const styles = StyleSheet.create({
    primaryOutlineBtn: {
        borderColor: colors.blueOne,
        borderRadius: 4,
        borderWidth: 1,
    },
});
