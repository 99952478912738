import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { BmisSummaryCard } from '@web/bmis/components/bmis-summary-card';

export const VinSessionDashboard: FC = () => {
    return (
        <DetailView>
            <DetailViewContent topOffset="breadcrumb">
                <DetailViewBody>
                    <Row>
                        <Col xs={12} md={6} lg={4} className="mb-2">
                            <BmisSummaryCard />
                        </Col>
                    </Row>
                </DetailViewBody>
            </DetailViewContent>
        </DetailView>
    );
};
