import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, Typography } from '@packages/ui/shared';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@web/components/table';
import colors from '@packages/core/styles/colors';

type DocsTableProps = {};

const useStyles = createUseStyles({
    searchResultsContainer: {
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20,
    },
    iconContainer: {
        width: 96,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    noResultsOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    iconOuter: {
        width: 192,
        height: 192,
        display: 'flex',
        marginBottom: 32,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.grayOne,
    },
});

const DocsTable: FC<DocsTableProps> = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowsData, setRowsData] = useState<any>([]);

    return (
        <div style={{ padding: 20, height: '100%' }}>
            <TableRenderer<any>
                isLoading={isLoading}
                tableRowsData={rowsData}
                tableRowsDataSetter={setRowsData}
                noResultsTitle={'No results found'}
                emptyStateProps={{
                    description: "Try adjusting your search or filters to find what you're looking for",
                }}
                tableHeaderRowRenderer={() => {
                    return (
                        <TableRow>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Document Type
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Title / ID / Case No
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Doc Code
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Created Date
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Actions
                                </Typography>
                            </TableHeader>
                        </TableRow>
                    );
                }}
                tableBodyRowRenderer={(rowData) => {
                    return (
                        <TableRow key={rowData.rowId}>
                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.documentType}</Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.title}</Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.docCode}</Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.createdDate}</Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.Actions}</Typography>
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                }}
            />
        </div>
    );
};

export default DocsTable;
