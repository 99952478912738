import { useInvestigationModal } from '@packages/contexts/investigations';
import { useAbortController } from '@packages/core/http';
import { InvestigationModel } from '@packages/models/api';
import { Button, Icon, TextBadge, TextInputHelper, Typography } from '@packages/ui/shared';
import {
    SORT_DIRECTION,
    TableCell,
    TableHeader,
    TablePagination,
    TableRenderer,
    TableRow,
} from '@web/components/table';
import { isEmpty, get } from 'lodash';
import { useContentOverlayState, useQuery } from '@web/core/hooks';
import { investigationService } from '@web/services/singletons';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import InvestigationsQuickFiltersPanel from '../components/investigations-quick-filters-panel';

interface InvestigationTableModel extends InvestigationModel {
    rowId: string;
}

const Investigations: FC = () => {
    const { t } = useTranslation();
    const { showModal: showInvestigationsModal } = useInvestigationModal();
    const { abortSignalRef } = useAbortController();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const queryParams = useQuery();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const searchInputRef = useRef(null);

    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const textSearchQuery = queryParams.get('query') || '';
    const parsedSortOrder = queryParams.get('sortOrder');
    const parsedSortDirection = queryParams.get('sortDir');

    const hasQuickFilters = useMemo(() => {
        return queryParams.has('statuses') || queryParams.has('creators');
    }, [queryParams]);

    const [newTextSearch, setNewTextSearch] = useState(textSearchQuery);
    const [isLoading, setIsLoading] = useState(false);
    const [investigations, setInvestigations] = useState<InvestigationTableModel[]>([]);
    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const fetchInvestigations = useCallback(() => {
        setIsLoading(true);
        const params = new URLSearchParams(search);

        params.set('page', '' + parsedPage);
        params.set('size', '' + parsedSize);

        if (!params.has('query')) {
            (searchInputRef.current as any).clear();
        }
        const isSearching = params.has('query') ? true : false;

        investigationService
            .searchInvestigations({
                searchString: params.toString(),
                isSearching: isSearching,
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const data = isEmpty(response.data)
                    ? {
                          investigations: [] as InvestigationModel[],
                          displayStartCountDescription: '0',
                          displayEndCountDescription: '0',
                          totalCountDescription: '0',
                          totalCount: 0,
                      }
                    : {
                          investigations: response.data.investigations,
                          displayStartCountDescription: response.data.displayStartCountDescription,
                          displayEndCountDescription: response.data.displayEndCountDescription,
                          totalCountDescription: response.data.totalCountDescription,
                          totalCount: response.data.totalCount,
                      };

                setInvestigations(data.investigations.map((i) => ({ ...i, rowId: i.investigationId })));
                setIsLoading(false);
                setPageStartCount(data.displayStartCountDescription);
                setPageEndCount(data.displayEndCountDescription);
                setPageTotalCount(data.totalCountDescription);
                setTotalEntires(data.totalCount);
            })
            .catch((e) => {
                setIsLoading(false);
                alert(e.message);
            });
    }, [abortSignalRef, parsedPage, parsedSize, search]);

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('investigationQuickFilters');
    }, [toggleOverlay]);

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (sizeFromQuery) {
            newQueryParams.set('size', sizeFromQuery);
        }

        if (textSearchQuery) {
            newQueryParams.set('query', textSearchQuery);
        }

        navigate(`/investigations?${newQueryParams.toString()}`);
    }, [navigate, sizeFromQuery, textSearchQuery]);

    const handleSortChange = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);
            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const searchByText = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            params.delete('page');
            params.delete('size');

            if (text) {
                params.set('query', text);
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    useEffect(() => {
        fetchInvestigations();
    }, [fetchInvestigations]);

    useEffect(() => {
        setNewTextSearch(textSearchQuery);
    }, [textSearchQuery]);

    return (
        <>
            {toggledOverlays.investigationQuickFilters && (
                <InvestigationsQuickFiltersPanel
                    hasActiveFilters={hasQuickFilters}
                    onClear={handleClearQuickFilters}
                    onClose={toggleQuickFilterPanel}
                />
            )}

            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <Typography variant="h2">Investigations</Typography>

                    <div className="mt-4 d-flex">
                        <div className="w-50">
                            <TextInputHelper
                                ref={searchInputRef}
                                rightIcon={
                                    <Icon
                                        name={textSearchQuery ? 'x-close' : 'magnifying-glass'}
                                        onPress={
                                            textSearchQuery
                                                ? () => {
                                                      searchByText('');
                                                  }
                                                : undefined
                                        }
                                    />
                                }
                                value={newTextSearch}
                                onChangeText={setNewTextSearch}
                                placeholder="Enter an investigation name"
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key === 'Enter') {
                                        console.log('search!!', newTextSearch);
                                        searchByText(newTextSearch);
                                    }
                                }}
                            />
                        </div>

                        <div className="ml-auto d-flex">
                            <Button
                                iconRight={
                                    <Icon
                                        size={16}
                                        raised
                                        name="plus"
                                        raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                    />
                                }
                                onPress={() => {
                                    showInvestigationsModal({
                                        mode: 'create',
                                        onSuccess: () => {
                                            fetchInvestigations();
                                        },
                                    });
                                }}
                                style={{ marginRight: 10 }}
                            >
                                New Investigation
                            </Button>
                        </div>
                    </div>

                    <div className="my-4 d-flex">
                        <Button
                            active={hasQuickFilters}
                            variant="ghost-blue"
                            iconLeft={<Icon color="blueOne" name="filter-list" />}
                            onPress={toggleQuickFilterPanel}
                        >
                            {hasQuickFilters
                                ? t('views:investigations.actions.filterToggle.active', 'Filters applied')
                                : t('views:investigations.actions.filterToggle.inactive', 'Filters')}
                        </Button>
                        {hasQuickFilters && (
                            <div className="ml-1">
                                <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                    <Icon color="blueOne" name="x-close" />
                                </Button>
                            </div>
                        )}
                    </div>

                    <TableRenderer
                        isLoading={isLoading}
                        tableRowsData={investigations}
                        tableRowsDataSetter={setInvestigations}
                        noResultsTitle={t('views:investigations.noResults', 'No Investigations found')}
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'ID'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('ID', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">ID</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'NAME'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('NAME', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Name</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'CREATED_BY'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('CREATED_BY', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Created By</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'CREATION_DATE'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('CREATION_DATE', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Creation Date</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'STATUS'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('STATUS', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Status</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'MEETINGS'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('MEETINGS', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Meetings</Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'DESCRIPTION'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('DESCRIPTION', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label">Description</Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId}>
                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>{rowData.investigationStsId}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                <Link to={`/investigations/${rowData.investigationId}`}>
                                                    {rowData.title}
                                                </Link>
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>{rowData.createdAccount.name}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                {rowData.createdTimestampDescription}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell width={175}>
                                        <div className="d-flex align-items-center">
                                            <TextBadge variant={'green' || 'red'}>{rowData.status}</TextBadge>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                {rowData.meetingNotes[0]
                                                    ? `${rowData.meetingNotes[0].meetingTypeDescription}, ${rowData.meetingNotes[0].meetingDate}`
                                                    : 'N/A'}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography>{rowData.description}</Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                    <TablePagination
                        page={parsedPage}
                        size={parsedSize}
                        total={totalEntries}
                        nextDisable={isLoading}
                        prevDisable={isLoading}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                        displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                    />
                </div>
            </div>
        </>
    );
};

export default Investigations;
