import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyleSheet, View } from 'react-native';
import Select from 'react-select';

import { Col, Row } from 'react-bootstrap';
import { Button, Icon, Typography, AlertBar } from '@packages/ui/shared';
import { Typeahead } from 'react-bootstrap-typeahead';
import colors from '@packages/core/styles/colors';
import { useAbortController } from '@packages/core/http';
import {
    fetchQmrsDashboardAlertLink,
    fetchAllHaveYouSeens,
    fetchAllCounterMeasureOfTheMonth,
    fetchAllHelpfulLinks,
    useQmrsDashboardDispatch,
    useQmrsDashboardState,
} from '@packages/contexts/qmrsdashboard';
import { useAuthState } from '@packages/contexts/auth';
import { Qmr } from '@packages/models/api';
import { useAlert } from '@packages/ui/shared';
import { useQmrTableActions } from '@packages/contexts/qmrs';

import {
    qmrsService,
    qmrsDashboardService,
    httpClient,
    analyticsService,
    qmrsDashboardDistrictStatsService,
} from '@web/services/singletons';
import { TablePagination, TableRenderer } from '@web/components/table';
import { QmrTableHead, QmrTableRow } from '@web/qmr/components/qmr-table';
import { SORT_DIRECTION } from '@web/components/table';
import QmrsDashboardBanner from '../components/qmrs-dashboard/qmrs-dashboard-banner';
import QmrsUpdateCards from '../components/qmrs-dashboard/qmrs-update-cards';
import QmrsDashboardHelpfulLinks from '../components/qmrs-dashboard/qmrs-dashboard-helpfullinks';
import QmrsDistrictStats from '../components/qmrs-dashboard/qmrs-dashboard-district-stats';
import QmrsDashboardPendingReviewList from '../components/qmrs-dashboard/qmrs-dashboard-pending-review-list';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import QmrSearch from '../components/qmr-search';

const useQmrDashboardStyle = createUseStyles({
    wrapper: ({ hideAsideContent }) => ({
        display: 'flex',
        flexDirection: 'column',
        width: hideAsideContent === true ? '100%' : 'calc(100% - 271px)',
        overflowY: 'auto',
        maxHeight: hideAsideContent === true ? 'initial' : 'calc(100vh - 64px)',
    }),
    details: {
        bottom: 0,
        backgroundColor: colors.white,
    },
    asideContent: ({ hideAsideContent }) => ({
        right: 0,
        bottom: 0,
        width: hideAsideContent === true ? 0 : 291,
        overflowY: 'auto',
        position: 'fixed',
        paddingTop: 16,
        borderLeft: `1px solid ${colors.grayThree}`,
        top: 64,
        backgroundColor: '#F2F5F9',
        transition: '0.2s width',
    }),
    qmrsDashBoardUpdatesRow: {
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        paddingTop: '24px',
    },
    collapseButton: {
        width: 24,
        height: 24,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.blueFour,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
    },
    collapseButtonOuter: {
        position: 'fixed',
        bottom: 32,
        right: 277,
        zIndex: 999999999,
    },
    collapseButtonOuterHiddenAside: {
        position: 'fixed',
        bottom: 32,
        right: 20,
    },
    selectDropDownCustom: {
        '&.react-select__control': {
            backgroundColor: colors.blueFour,
        },
    },
    typeAheadWrapper: {
        position: 'relative',
        width: '330px',
    },
    iconStyle: {
        position: 'absolute',
        top: 11,
        right: 30,
    },
});

const styles = StyleSheet.create({
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: colors.grayFour,
    },
});

interface QmrTableRowModel extends Qmr {
    rowId: string;
    checked: boolean;
}

const QmrDashboard: FC = () => {
    const [qmrTableRows, setQmrTableRows] = useState<QmrTableRowModel[]>([]);
    const [startCount, setStartCount] = useState('0');
    const [endCount, setEndCount] = useState('0');
    const [qmrTotal, setQmrTotal] = useState('0');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedQmrs, setSelectedQmrs] = useState<QmrTableRowModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [hideAsideContent, setHideAsideContent] = useState(false);
    const [savedSearches, setSavedSearches] = useState<any[]>([]);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState<string | undefined>();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [displayRoleSelection, setDisplayRoleSelection] = useState(false);
    const [displayPendingReviewList, setDisplayPendingReviewList] = useState(false);
    const [districtDropDownOptions, setDistrictDropDownOptions] = useState<any[]>([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState<string>('');
    const [selectedDistrict, setSelectedDistrict] = useState<any>();
    const navigate = useNavigate();
    const { account } = useAuthState();
    const { t } = useTranslation();
    const alert = useAlert();
    const { abortSignalRef } = useAbortController();
    const [districtSearch, setDistrictSearch] = useState(false);
    const { pathname } = useLocation();
    let path = pathname.replace('/dashboard', '');

    const [allAccounts, setAllAccounts] = useState<
        { accountId: string; name: string; soaUsername: string; districtId?: string; details?: string }[]
    >([]);
    const [permissions, setPermissions] = useState<any>([]);
    const [selectedAccount, setSelectedAccount] = useState<{
        accountId?: string;
        name?: string;
        soaUsername?: string;
        districtId?: string;
        details?: string;
    }>();

    const qmrTableActions = useQmrTableActions({
        qmrs: selectedQmrs.length ? selectedQmrs : null,
        alert,
        qmrsService,
    });

    const classes = useQmrDashboardStyle({ hideAsideContent });
    const qmrsDashboardState = useQmrsDashboardState();
    const qmrsDashboardDispatch = useQmrsDashboardDispatch();

    useEffect(() => {
        const userDontHaveAccess = ['RETAILER_USER', 'RETAILER_ADMIN', 'CORPORATE_USER', 'NASI_SIA_ADMIN'];

        let shouldNavigateToQmrs = account?.baseRoleIds?.some((ele: string) => userDontHaveAccess.indexOf(ele) !== -1);
        const shouldShowUserSelection = permissions.some(
            (permission: any) => permission.permissionId === 'DASHBOARD007'
        );
        const shouldShowPendingReviewList = permissions.some(
            (permission: any) => permission.permissionId === 'DASHBOARD004'
        );
        const isDistrictUser = permissions.some((permission: any) => permission.permissionId === 'DASHBOARD003');
        setDistrictSearch(isDistrictUser);
        if (isDistrictUser) {
            fetchDistrictsData();
        }
        if (shouldShowUserSelection || shouldShowPendingReviewList) {
            setDisplayRoleSelection(shouldShowUserSelection);
            setDisplayPendingReviewList(shouldShowPendingReviewList);
        } else if (shouldNavigateToQmrs) {
            navigate('/qmrs');
        }
    }, [account, navigate, permissions]);

    const {
        qmrsDashboardAlertLinks = [
            {
                qmrDashAlertLinksId: '',
                title: '',
                description: '',
                link: '',
                displayOrder: '',
            },
        ],
        haveYouSeens,
        counterMeasuresOfTheMonth,
        helpfullinks,
    } = qmrsDashboardState;

    const haveYouSeenDisplayList =
        haveYouSeens?.map((hys: { title: any; description: any; link: any }) => {
            return {
                heading: hys.title,
                description: hys.description,
                link: hys.link,
            };
        }) || [];
    const counterMeasureOfTheMonthList =
        counterMeasuresOfTheMonth?.map((cmom: { title: any; description: any; link: any }) => {
            return {
                heading: cmom.title,
                description: cmom.description,
                link: cmom.link,
            };
        }) || [];
    const helpFullinksDisplayList =
        helpfullinks?.map((hpl: { title: any; link: any }) => {
            return {
                linkText: hpl.title,
                targetUri: hpl.link,
            };
        }) || [];
    const updates = [
        {
            heading: 'Have you seen?',
            headerWithIcon: false,
            body: [...haveYouSeenDisplayList],
        },
        {
            heading: 'Improvement of the month',
            headerWithIcon: false,
            body: [...counterMeasureOfTheMonthList],
        },
    ];

    useEffect(() => {
        fetchQmrsDashboardAlertLink({
            id: undefined,
            qmrsDashboardDispatch,
            qmrsDashboardService,
        }).catch((e) => console.log(e));

        fetchAllHaveYouSeens({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        }).catch((e) => console.log(e));
        fetchAllCounterMeasureOfTheMonth({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        }).catch((e) => console.log(e));
        fetchAllHelpfulLinks({
            qmrsDashboardDispatch,
            qmrsDashboardService,
        }).catch((e) => console.log(e));
    }, [qmrsDashboardDispatch]);

    const handleSortChange = (sortBy: string, sortDirection: SORT_DIRECTION) => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
        fetchQmrList(sortBy, sortDirection);
    };

    const fetchQmrList = async (sortBy?: string, sortDirection?: SORT_DIRECTION) => {
        setIsLoading(true);
        try {
            let queryParams: any = { size: '10', qmrStatusId: 'DRAFT' };
            if (sortBy && sortDirection) {
                queryParams = { ...queryParams, sortOrder: sortBy, sortDir: sortDirection };
            }
            const queryString = new URLSearchParams(queryParams).toString();
            const response = await qmrsService.fetchQmrsList({ queryString });
            setIsLoading(false);
            if (response.success) {
                setQmrTableRows(
                    response.data.qmrs.map((qmr: any) => {
                        const qmrAsTableRow = { ...qmr, rowId: qmr.qmrId, checked: false };
                        return qmrAsTableRow;
                    })
                );
                setStartCount(response.data.displayStartCountDescription);
                setEndCount(response.data.displayEndCountDescription);
                setQmrTotal(response.data.totalCountDescription);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(typeof error.message === 'string' ? error.message : 'Something went wrong.');
        }
    };

    const fetchSavedSearchData = async () => {
        try {
            const response = await qmrsService.getAdvancedSearchSavedSearches({});
            if (response.success) {
                setSavedSearches(response.data.savedSearches);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    };
    const fetchSelectedSavedSearchData = async (searchId: string | undefined) => {
        const dashboardQmrParams = new URLSearchParams({ size: '' + pageSize, page: '' + page }).toString();
        const response = await qmrsService.getAdvancedSearchResultsForSearchId(searchId || '', dashboardQmrParams);
        return response;
    };

    useEffect(() => {
        fetchSavedSearchData();
        analyticsService.logEvent(ANALYTICS_EVENTS.QMRDASH_USER_VISITED_DSQM_DASHBOARD);
    }, []);

    useEffect(() => {
        if (selectedSavedSearch === 'view_all') {
            navigate('/qmrs/advanced-search/saved');
        }
        try {
            if (selectedSavedSearch || account?.defaultSavedSearchId) {
                setIsLoading(true);
                fetchSelectedSavedSearchData(selectedSavedSearch ? selectedSavedSearch : account?.defaultSavedSearchId)
                    .then((response) => {
                        if (response.success) {
                            const qmrsTableRowsTemp = response.data.results.qmrs.map((qmr) => {
                                const qmrAsTableRow = { ...qmr, rowId: qmr.qmrId, checked: false };
                                return qmrAsTableRow;
                            });
                            setQmrTableRows([...qmrsTableRowsTemp]);
                            setStartCount(response.data.results.displayStartCountDescription);
                            setEndCount(response.data.results.displayEndCountDescription);
                            setQmrTotal(response.data.results.totalCountDescription);
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        if (!err.success) {
                            setIsLoading(false);
                            throw err.data;
                        }
                    });
            }
        } catch (error) {
            window.alert(typeof error.message === 'string' ? error.message : 'Something went wrong.');
        }
        //eslint-disable-next-line
    }, [selectedSavedSearch, page, pageSize]);

    const options = useCallback(() => {
        let optionsToDisplay = savedSearches?.length > 6 ? savedSearches.slice(0, 6) : savedSearches;
        let mappedOptions =
            optionsToDisplay?.map((ss: { savedSearchId: string; name: string }) => ({
                value: ss.savedSearchId,
                label: ss.name,
            })) || [];
        if (savedSearches?.length > 6) {
            mappedOptions.push({ value: 'view_all', label: 'View All' });
        }
        return mappedOptions;
        //eslint-disable-next-line
    }, [account, savedSearches]);

    const selectedValue = useCallback(() => {
        if (selectedSavedSearch) {
            return options()?.filter((ss: { value: string; label: string }) => ss.value === selectedSavedSearch)[0];
        } else if (account?.defaultSavedSearchId) {
            return options()?.filter(
                (ss: { value: string; label: string }) => ss.value === account?.defaultSavedSearchId
            )[0];
        } else {
            if (savedSearches) {
                setSelectedSavedSearch(savedSearches[0]?.savedSearchId);
            }
            return options() ? options()[0] : undefined;
        }
        // eslint-disable-next-line
    }, [account, savedSearches, selectedSavedSearch]);

    let timer: any;
    const fetchAllMatchingAccount = async (query: string) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            const config = {
                method: 'GET' as const,
                signal: abortSignalRef.current,
                ignoreCache: true,
            };
            if (query && query.length > 2) {
                httpClient
                    .orchestrateRequest<any>({
                        ...config,
                        url: `/qmr/dashboard/accounts/autocompleteDistrict?query=${query}`,
                    })
                    .then((accountsInformation: any) => {
                        const allAcountsMapped = accountsInformation.data.autocompleteAccounts?.map(
                            (accountInformation: {
                                accountId: string;
                                name: string;
                                soaUsername: string;
                                districtId?: string;
                                details?: string;
                            }) => {
                                return {
                                    accountId: accountInformation.accountId,
                                    name: accountInformation.name,
                                    soaUsername: accountInformation.soaUsername,
                                    districtId: accountInformation.districtId,
                                    details: accountInformation.details,
                                };
                            }
                        );
                        setAllAccounts([...allAcountsMapped]);
                    })
                    .catch((e: any) => {
                        console.log('error here ', e);
                    });
            }
        }, 400);
    };

    const fetchDistrictsData = async () => {
        setIsLoading(true);
        try {
            const response = await qmrsDashboardDistrictStatsService.fetchDistrictsData({});
            if (response.success) {
                const options = response.data.autocompleteDistricts.map((district) => ({
                    label: district.details,
                    value: district.districtId,
                }));
                setSelectedDistrictId(options[0].value);
                setSelectedDistrict(options[0]);
                if (options.length < 2) {
                    setDistrictSearch(false);
                }
                setDistrictDropDownOptions(options);
            } else throw response.data;
        } catch (err) {
            window.alert(err.message);
        }
        setIsLoading(false);
    };

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                //alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    return (
        <>
            <div className={classes.wrapper}>
                <AlertBar
                    show={alert.isOpen}
                    success={alert.type === 'success'}
                    error={alert.type === 'error'}
                    onClose={alert.close}
                >
                    <Typography>{alert.content}</Typography>
                </AlertBar>
                <div className={classes.details}>
                    {displayRoleSelection ? (
                        <Row
                            className={classes.qmrsDashBoardUpdatesRow}
                            style={{
                                justifyContent: 'flex-end',
                                paddingRight: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            <div className={classes.typeAheadWrapper}>
                                <Typeahead
                                    id="select account"
                                    placeholder="Select User"
                                    labelKey={(option) =>
                                        option.details
                                            ? `${option.name} (${option.soaUsername}) -  ${option.details}`
                                            : `${option.name} (${option.soaUsername})`
                                    }
                                    onChange={(selected: any) => {
                                        if (selected && selected.length > 0) {
                                            setSelectedAccount(selected[0]);
                                        } else {
                                            setSelectedAccount(undefined);
                                            setSelectedDistrict(undefined);
                                            setSelectedDistrictId('');
                                        }
                                    }}
                                    options={allAccounts}
                                    onInputChange={(text: string) => {
                                        if (text.length < 2) {
                                            setSelectedAccount(undefined);
                                        }
                                        fetchAllMatchingAccount(text);
                                    }}
                                />
                            </div>
                        </Row>
                    ) : districtSearch ? (
                        <Row
                            className={classes.qmrsDashBoardUpdatesRow}
                            style={{
                                justifyContent: 'flex-end',
                                paddingRight: '10px',
                                marginBottom: '10px',
                            }}
                        >
                            <div className={classes.typeAheadWrapper}>
                                <Select
                                    value={selectedDistrict}
                                    options={districtDropDownOptions}
                                    onChange={(event) => {
                                        setSelectedDistrict(event);
                                        setSelectedDistrictId(event.value);
                                    }}
                                />
                            </div>
                        </Row>
                    ) : (
                        <Row
                            className={classes.qmrsDashBoardUpdatesRow}
                            style={{
                                justifyContent: 'flex-end',
                                paddingRight: '10px',
                                marginBottom: '10px',
                            }}
                        ></Row>
                    )}
                    <QmrsDashboardBanner qmrsDashboardAlertLink={qmrsDashboardAlertLinks[0]} />
                    <div className="ml-6 mr-4">
                        <QmrSearch path={path} account={account} includeFilters={true} />
                    </div>
                    <QmrsDistrictStats
                        qmrsDistrictStats={{
                            displayStatsForAccunt: selectedAccount,
                            loggedInUserPermission: permissions,
                            selectedDistrict: selectedDistrict,
                            setSelectedDistrict: setSelectedDistrict,
                        }}
                    />
                    <Row className={classes.qmrsDashBoardUpdatesRow}>
                        {updates.map(
                            (update) => update && update.body.length > 0 && <QmrsUpdateCards updateCardInfo={update} />
                        )}
                    </Row>
                    {savedSearches?.length > 0 && (
                        <>
                            <Row className={classes.qmrsDashBoardUpdatesRow}>
                                <Col>
                                    <div className="d-flex align-items-center">
                                        <Typography variant="default">
                                            {t('views:dashboard.customDisplay.title', 'Advanced Saved Searches: ')}
                                        </Typography>
                                        <div style={{ width: '200px' }}>
                                            <Select
                                                value={selectedValue()}
                                                classNamePrefix={'react-select'}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    control: (styles) => ({
                                                        ...styles,
                                                        //backgroundColor: colors.blueFour,
                                                        color: colors.blueOne,
                                                        borderWidth: 0,
                                                        font: '18px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                                                        fontWeight: '700',
                                                    }),
                                                    singleValue: (styles) => ({
                                                        ...styles,
                                                        color: colors.blueOne,
                                                        fontSize: '15px',
                                                        lineHeight: '18px',
                                                        textTransform: 'capitalize',
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    option: (base) => ({
                                                        ...base,
                                                        fontSize: '15px',
                                                        lineHeight: '18px',
                                                        textTransform: 'capitalize',
                                                    }),
                                                }}
                                                isSearchable={false}
                                                options={options()}
                                                onChange={(inputValue) => {
                                                    setPage(0);
                                                    setPageSize(10);
                                                    setSelectedSavedSearch(inputValue?.value);
                                                }}
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                    DropdownIndicator: () => (
                                                        <Icon name={'arrow-small-down'} color="blueOne" size={20} />
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row /*className="mb-4"*/ className={classes.qmrsDashBoardUpdatesRow}>
                                <Col style={{ minHeight: 200 }}>
                                    <TableRenderer<QmrTableRowModel>
                                        isLoading={isLoading}
                                        selectAll={selectAll}
                                        selectAllSetter={setSelectAll}
                                        tableRowsData={qmrTableRows}
                                        tableRowsDataSetter={setQmrTableRows}
                                        onSelectionChange={setSelectedQmrs}
                                        tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                                            return (
                                                <QmrTableHead
                                                    selectedCount={selectedQmrs.length}
                                                    selectAll={selectAllValue}
                                                    onSelectAllChange={selectAllChangeHandler}
                                                    sortOrder={sortBy}
                                                    sortDirection={
                                                        sortDirection ? (sortDirection as SORT_DIRECTION) : null
                                                    }
                                                    onSort={handleSortChange}
                                                    onActionPress={qmrTableActions.handleActionPress}
                                                />
                                            );
                                        }}
                                        tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                                            return (
                                                <QmrTableRow
                                                    key={rowData.qmrId}
                                                    qmr={rowData}
                                                    checked={rowData.checked}
                                                    onCheckboxChange={rowSelectHandler}
                                                />
                                            );
                                        }}
                                    />
                                    <TablePagination
                                        page={page}
                                        size={pageSize}
                                        total={parseInt(qmrTotal)}
                                        prevDisable={isLoading}
                                        nextDisable={isLoading}
                                        onClick={(pageNumber) => {
                                            setPage(pageNumber);
                                        }}
                                        onSizeChange={(size) => {
                                            setPageSize(size);
                                        }}
                                        displayCount={`${parseInt(startCount)} -${parseInt(endCount)} of ${parseInt(
                                            qmrTotal
                                        )}`}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
                {hideAsideContent && (
                    <div className={`${classes.collapseButtonOuter}  ${classes.collapseButtonOuterHiddenAside}`}>
                        <Button
                            variant="link"
                            onPress={() => {
                                setHideAsideContent(!hideAsideContent);
                            }}
                        >
                            <div className={classes.collapseButton}>
                                <Icon
                                    name={hideAsideContent ? 'chevron-left' : 'chevron-right'}
                                    color="blueOne"
                                    size={16}
                                />
                            </div>
                        </Button>
                    </div>
                )}
            </div>
            <div className={classes.asideContent}>
                {hideAsideContent === false && (
                    <div className={classes.collapseButtonOuter}>
                        <Button
                            variant="link"
                            onPress={() => {
                                setHideAsideContent(!hideAsideContent);
                            }}
                        >
                            <div className={classes.collapseButton}>
                                <Icon
                                    name={hideAsideContent ? 'chevron-left' : 'chevron-right'}
                                    color="blueOne"
                                    size={16}
                                />
                            </div>
                        </Button>
                    </div>
                )}
                {displayPendingReviewList && <QmrsDashboardPendingReviewList districtId={selectedDistrictId} />}
                <View style={styles.divider} />
                <QmrsDashboardHelpfulLinks
                    qmrsDashboardHelpfullinksProps={{
                        title: 'Helpful links',
                        links: helpFullinksDisplayList,
                    }}
                />
            </div>
        </>
    );
};

export default QmrDashboard;
