import React, { FC } from 'react';
import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

const useBmisLayoutStyles = createUseStyles({
    reportIdentifiers: {
        borderRight: `1px solid ${colors.grayThree}`,
        height: '100%',
        padding: '8px 24px',
    },
});

const styles = StyleSheet.create({
    typographyStyles: {
        fontWeight: '700',
        color: '#4C525D',
        lineHeight: 15.95,
    },
});

export const BmisReportSections: FC = () => {
    const classes = useBmisLayoutStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.reportIdentifiers}>
            <Typography variant="caption2" style={styles.typographyStyles}>
                {t('Bmis Section')}
            </Typography>
        </div>
    );
};
