import React, { FC, useEffect, useRef, useState } from 'react';
import { Menu, Typeahead } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Icon, Typography } from '@packages/ui/shared';
import { RobotoFontFamily } from '@packages/ui/shared';
import { configService } from '@web/services/singletons';
import { useAuthState } from '@packages/contexts/auth';
import VinFilterButton from './vin-filter-button';
import { useMatch, useNavigate } from 'react-router-dom';
import { VIN } from '../types';
import { useVinSessionDispatch, useVinSessionState } from '../context/vin-sessions.hooks';
import { vinSessionActions } from '../context/vin-sessions.state';
import VinDetails from './vin-details';
import { filterList, getVin } from '../utils/vinSessionUtils';

type VinSessionSearchBarProps = {
    onHide: () => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createUseStyles({
    typeAheadContainer: {
        width: 570,
        borderRadius: 4,
        backgroundColor: colors.white,
    },
    typeAhead: {
        '& input::placeholder': {
            color: `#4C525D !important`,
            fontWeight: 400,
            fontSize: 15,
            lineHeight: 24,
        },
        '& input': {
            fontWeight: 400,
            color: `#4C525D !important`,
            padding: '8px 12px 8px 40px !important',
        },
    },
    hideSearch: {
        position: 'absolute',
        right: 12,
        top: 9,
        zIndex: 2,
        fontFamily: RobotoFontFamily.Regular,
        backgroundColor: colors.white,
        fontWeight: 400,
        fontSize: 15,
        color: `#999DA3 !important`,
        lineHeight: '24px',
        cursor: 'pointer',
    },
    hide: {
        position: 'absolute',
        right: 12,
        top: 10,
        backgroundColor: colors.white,
        height: 20,
        width: 20,
        zIndex: 2,
    },
    recentSearchMenu: {
        display: 'flex !important',
        flexDirection: 'column !important',
        padding: '16px !important',
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    filterList: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 535,
        minHeight: 204,
        gap: 16,
    },
    searchBarFilterList: {
        display: 'flex',
        position: 'absolute',
        top: 9,
        left: 40,
        gap: 8,
        whiteSpace: 'nowrap',
    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            height: '10px !important',
            width: '8px',
            backgroundColor: '#ffffff',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: '#E3E3E3',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colors.blueOne,
        },
    },
    filter: {
        display: 'flex',
        position: 'absolute',
        top: 4,
        left: 40,
        width: '57px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 4px 2px 4px',
        borderRadius: '4px',
        border: '1px solid #DEE6EF',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 16,
        marginLeft: 3,
    },
    vinButton: {
        '& input': {
            padding: '8px 12px 8px 104px !important',
        },
    },
    searchFilter: {
        height: 24,
        top: 8,
        left: 42,
    },
    vinDetailsStyling: {
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '12px 16px 12px 16px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    vinHeader: {
        marginBottom: 10,
        backgroundColor: '#DEE6EF',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 16px 16px 16px',
    },
    dynamicPadding: (divWidth: number) => ({
        '& input': {
            padding: `8px 12px 8px ${divWidth + 44}px !important`,
        },
    }),
});

const VinSessionSearchBar: FC<VinSessionSearchBarProps> = (props) => {
    const { onHide, isLoading, setIsLoading } = props;
    const navigate = useNavigate();
    const { isVinLoading, selectedVin, selectedFilters } = useVinSessionState();
    const dispatch = useVinSessionDispatch();
    const searchInputRef = useRef(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { account } = useAuthState();
    const canViewDebugger =
        configService.debugEnvName === 'production'
            ? account?.baseRoleIds.includes('LAG_SUPER_ADMIN')
            : configService.buildDebugEnabled;
    const [divWidth, setDivWidth] = useState<number>(0);
    const classes = useStyles(divWidth);
    const [hasInput, setHasInput] = useState<boolean>(false);
    const vinSessionRouteMatch = useMatch('/VIN/*');
    const [data, setData] = useState<any>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchVinData, setSearchedVinData] = useState<any>(undefined);
    const [searchFilter, setSearchFilter] = useState<boolean>(true);

    // Mock-data till backend api is built
    useEffect(() => {
        setData([
            {
                vinRecord: {
                    img: 'https://asset-mgmt.qa.subaru.com/rest/getAsset/vin/vehicleImage/M3604581?jwt=eyJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJhdWQiOiJ0ZWNoU2hhcmUiLCJyaWdodHMiOiJwb3J0Zm9saW86Kjtydzt0dGw6c2hvcnQiLCJpYXQiOjE3MTk4NTI0MzIsImV4cCI6MTcyMDU2ODAwM30.sEiUJfbO8WNZCux1YNXbs8A_AcuBRsffJ3eSdKWqemI&type=profile&render=width100',
                    vin: 'KS3121A6XK9802796',
                    model: '2021 WRX',
                    icon: '',
                },
            },
            {
                vinRecord: {
                    img: 'https://asset-mgmt.qa.subaru.com/rest/getAsset/vin/vehicleImage/M3604581?jwt=eyJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJhdWQiOiJ0ZWNoU2hhcmUiLCJyaWdodHMiOiJwb3J0Zm9saW86Kjtydzt0dGw6c2hvcnQiLCJpYXQiOjE3MTk4NTI0MzIsImV4cCI6MTcyMDU2ODAwM30.sEiUJfbO8WNZCux1YNXbs8A_AcuBRsffJ3eSdKWqemI&type=profile&render=width100',
                    vin: 'JF1VA1A6XK9802796',
                    model: '2023 Crosstrek',
                    icon: '',
                },
            },
            {
                vinRecord: {
                    img: 'https://asset-mgmt.qa.subaru.com/rest/getAsset/vin/vehicleImage/M3604581?jwt=eyJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJhdWQiOiJ0ZWNoU2hhcmUiLCJyaWdodHMiOiJwb3J0Zm9saW86Kjtydzt0dGw6c2hvcnQiLCJpYXQiOjE3MTk4NTI0MzIsImV4cCI6MTcyMDU2ODAwM30.sEiUJfbO8WNZCux1YNXbs8A_AcuBRsffJ3eSdKWqemI&type=profile&render=width100',
                    vin: '4S4WMAPD5K3466811',
                    model: '2021 Forester',
                    icon: '',
                },
            },
            {
                vinRecord: {
                    img: 'https://asset-mgmt.qa.subaru.com/rest/getAsset/vin/vehicleImage/M3604581?jwt=eyJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJhdWQiOiJ0ZWNoU2hhcmUiLCJyaWdodHMiOiJwb3J0Zm9saW86Kjtydzt0dGw6c2hvcnQiLCJpYXQiOjE3MTk4NTI0MzIsImV4cCI6MTcyMDU2ODAwM30.sEiUJfbO8WNZCux1YNXbs8A_AcuBRsffJ3eSdKWqemI&type=profile&render=width100',
                    vin: 'S5W7MNL32K3461012',
                    model: '2004 Brat',
                    icon: '',
                },
            },
            {
                vinRecord: {
                    img: 'https://asset-mgmt.qa.subaru.com/rest/getAsset/vin/vehicleImage/M3604581?jwt=eyJhbGciOiJIUzI1NiJ9.eyJlbnZpcm9ubWVudCI6InByZXByb2QiLCJhdWQiOiJ0ZWNoU2hhcmUiLCJyaWdodHMiOiJwb3J0Zm9saW86Kjtydzt0dGw6c2hvcnQiLCJpYXQiOjE3MTk4NTI0MzIsImV4cCI6MTcyMDU2ODAwM30.sEiUJfbO8WNZCux1YNXbs8A_AcuBRsffJ3eSdKWqemI&type=profile&render=width100',
                    vin: '4S4BTADCXM3165371',
                    model: '2021 Outback',
                    icon: '',
                },
            },
        ]);
    }, []);

    useEffect(() => {
        const updateWidth = () => {
            if (scrollContainerRef.current) {
                setDivWidth(scrollContainerRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [selectedFilters, vinSessionRouteMatch]);

    useEffect(() => {
        if (searchQuery.length > 0 && /^(?=.*[A-Za-z])([A-Za-z\d]{2,11}\d{6})$/.test(searchQuery)) fetchVIN();
    }, [searchQuery]);

    const handleChange = (selected: string) => {
        if (searchInputRef.current) {
            setHasInput(true);
            const searchedQuery = (searchInputRef.current as any).getInput().value;
            setIsLoading(false);
            if (searchedQuery.length == 8 || searchedQuery.length == 17) {
                setSearchQuery(searchedQuery);
            } else if (!/^(?=.*[A-Za-z])([A-Za-z\d]{2,11}\d{6})$/.test(searchQuery)) {
                navigate(`/docs?query=${searchedQuery}`);
                (searchInputRef.current as any).blur();
            }
        }
    };

    const clearInput = () => {
        if (searchInputRef.current) {
            (searchInputRef.current as any).clear();
            setHasInput(false);
        }
        dispatch(
            vinSessionActions.setSelectedFilters({
                selectedFilters: [],
            })
        );
        navigate('/');
        (searchInputRef.current as any).blur();
        (searchInputRef.current as any).clear();
    };

    // API call
    async function fetchVIN() {
        const searchVin = await getVin(searchQuery, setIsLoading);
        if (searchVin) {
            setSearchedVinData(searchVin);
        }
    }

    const handleClick = (vin: VIN) => {
        onHide();
        navigate('/VIN/' + `${vin.vinRecord.vin}`);
        setSearchedVinData(undefined);
        setHasInput(false);
        setSearchQuery('');
        (searchInputRef.current as any).blur();
        (searchInputRef.current as any).clear();
    };

    return (
        <div className={classes.typeAheadContainer} style={{ left: canViewDebugger ? 200 : 270 }}>
            <Typeahead
                ref={searchInputRef}
                onChange={(selected) => {
                    if (!selected[0]) return;
                    handleChange(selected[0]);
                }}
                onInputChange={() => {
                    if (searchInputRef.current) {
                        if ((searchInputRef.current as any).getInput().value == 0) {
                            setSearchedVinData(undefined);
                            setHasInput(false);
                            setSearchQuery('');
                        } else {
                            setSearchedVinData(undefined);
                            setHasInput(false);
                            setSearchQuery((searchInputRef.current as any).getInput().value);
                        }
                    }
                }}
                filterBy={() => true}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        handleChange((searchInputRef.current as any).inputNode.value);
                    }
                }}
                options={[]}
                placeholder="Enter Keyword for search or a VIN to Open a VIN Session"
                className={`${classes.typeAhead} ${(selectedFilters.length > 0 || vinSessionRouteMatch) && classes.dynamicPadding}`}
                renderMenu={(results, menuProps, ...args) => {
                    return (
                        <Menu {...menuProps} className={`${classes.recentSearchMenu} ${classes.scrollBar}`}>
                            {!searchVinData && (
                                <div className={classes.filters}>
                                    <Typography variant="caption" style={{ color: '#999DA3' }}>
                                        I'm searching for...
                                    </Typography>
                                    <div className={classes.filterList}>
                                        {filterList.map((filter, index) => (
                                            <VinFilterButton
                                                filter={filter}
                                                index={index}
                                                iconName="x-close"
                                                style={{ color: colors.blueOneHover }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {searchVinData && (
                                <div className={classes.vinHeader}>
                                    <div style={{ padding: 16 }}>
                                        <Typography
                                            style={{
                                                color: '#071424',
                                                fontFamily: RobotoFontFamily.Regular,
                                                fontWeight: '400',
                                                fontSize: 12,
                                                lineHeight: 24,
                                            }}
                                        >
                                            {vinSessionRouteMatch
                                                ? 'Want to open a different session? You can do it in a new tab.'
                                                : "Searching by VIN? Open a VIN Session for a streamlined overview of the car's info."}
                                        </Typography>
                                    </div>
                                    <VinDetails
                                        style={classes.vinDetailsStyling}
                                        car={searchVinData}
                                        handleClick={handleClick}
                                        index={1}
                                        onHide={onHide}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                    />
                                </div>
                            )}
                        </Menu>
                    );
                }}
            >
                <Icon
                    name="magnifying-glass"
                    style={{ position: 'absolute', top: 9, left: 12, color: '#999DA3' }}
                    onPress={() => {
                        (searchInputRef.current as any).inputNode.focus();
                    }}
                />

                {vinSessionRouteMatch && selectedFilters.length == 0 && (
                    <div
                        className={classes.filter}
                        style={{
                            backgroundColor: colors.blueFour,
                            top: 7,
                        }}
                        ref={scrollContainerRef}
                    >
                        <Typography variant="caption5" style={{ color: colors.blueOne, marginTop: 2 }}>
                            VIN
                        </Typography>
                        <div className={classes.icon}>{<Icon name="info" color="blueOne" status={true} />}</div>
                    </div>
                )}

                {selectedFilters.length > 0 && (
                    <div
                        className={`${classes.searchBarFilterList} ${searchFilter ? classes.searchFilter : ''}`}
                        ref={scrollContainerRef}
                    >
                        <VinFilterButton
                            filter={selectedFilters[0]}
                            textColor={colors.blueOne}
                            iconName="x-close"
                            searchFilter={searchFilter}
                            style={
                                searchFilter
                                    ? {
                                          color: colors.blueOne,
                                          fontFamily: RobotoFontFamily.Bold,
                                          fontWeight: 700,
                                          fontSize: 11,
                                          lineHeight: 16,
                                          textAlign: 'center',
                                      }
                                    : { color: colors.blueOne }
                            }
                        />
                        {selectedFilters.length > 1 && (
                            <VinFilterButton
                                filter={`+ ${selectedFilters.length - 1}`}
                                searchFilter={searchFilter}
                                style={
                                    searchFilter
                                        ? {
                                              color: colors.blueOne,
                                              fontFamily: RobotoFontFamily.Bold,
                                              fontWeight: 700,
                                              fontSize: 11,
                                              lineHeight: 16,
                                              textAlign: 'center',
                                          }
                                        : { color: colors.blueOne }
                                }
                            />
                        )}
                    </div>
                )}

                {hasInput && searchQuery.length > 0 ? (
                    <div className={classes.hideSearch} onClick={clearInput}>
                        Clear search
                    </div>
                ) : (
                    <div className={classes.hide} />
                )}
            </Typeahead>
        </div>
    );
};

export default VinSessionSearchBar;
