import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import DocsSearchResults from './docs/docs-search-results';
import useQuery from '@web/core/hooks/use-query';
import DocsFilterPanel from './docs/docs-filter-panel';
import DocsPagination from './docs/docs-pagination';
import DocsTable from './docs/docs-table';

type DocsQuerySearchProps = {};

const useStyles = createUseStyles({
    docsContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const DocsQuerySearch: FC<DocsQuerySearchProps> = (props) => {
    const classes = useStyles();
    const {} = props;
    const queryParams = useQuery();
    const quickSearchQuery = queryParams.get('query') || '';

    return (
        <div className={classes.docsContainer}>
            <div>
                <DocsSearchResults title={quickSearchQuery} />
                <DocsFilterPanel />
            </div>
            <DocsTable />
            <DocsPagination />
        </div>
    );
};

export default DocsQuerySearch;
