import React, { FC, useState, useCallback, useEffect } from 'react';
import { Row, Col, Modal, Form, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAbortController } from '@packages/core/http';
import { Qmr, Retailer, Vehicle, CreateQmrDto } from '@packages/models/api';
import { fetchAuthUserRetailers, useAuthDispatch, useAuthState } from '@packages/contexts/auth';

import { analyticsService, qmrsService } from '@web/services/singletons';
import PossibleVehicleCard from '@web/components/possible-vehicle-card';
import { TextInputHelper, Label, Icon, Button, Typography } from '@packages/ui/shared';
import RetailerSearchInput from '@web/components/retailer-search-input';
import Select, { SelectOption } from './select';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { SelectQmrPrimaryObjectives } from './select-qmr-primary-objectives';
import { isPrimaryObjectiveEnabled } from '@web/config/constants';

type CreateQmrModalProps = {
    show: boolean;
    onHide: (qmrId?: Qmr['qmrId']) => void;
};

const CreateQmrModal: FC<CreateQmrModalProps> = (props) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();
    const authDispatch = useAuthDispatch();
    const { account, retailers: accountRetailers } = useAuthState();

    const [selectedRetailer, setSelectedRetailer] = useState<Retailer>();

    const [vinInputValue, setVinInputValue] = useState('');
    const [checkingVin, setCheckingVin] = useState(false);
    const [vinError, setVinError] = useState('');
    const [showContinueWithoutVinWarning, setShowContinueWithoutVinWarning] = useState(false);
    const [continueWithoutVin, setContinueWithoutVin] = useState(false);
    const [possibleVehicles, setPossibleVehicles] = useState<Vehicle[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();

    const [showVehicleDetailsForm, setShowVehicleDetailsForm] = useState(false);
    const [carlineOptions, setCarlineOptions] = useState<SelectOption[]>([]);
    const [selectedCarlineId, setSelectedCarlineId] = useState('');

    const [modelYearInputValue, setModelYearInputValue] = useState<number | undefined>();
    const [transmissionNumberInputValue, setTransmissionNumberInputValue] = useState('');
    const [engineNumberInputValue, setEngineNumberInputValue] = useState('');
    const [colorInputValue, setColorInputValue] = useState('');
    const [modelInputValue, setModelInputValue] = useState('');
    const [modelOptionInputValue, setModelOptionInputValue] = useState('');
    const [isCreatingQmr, setIsCreatingQmr] = useState(false);
    const [selectedPrimaryObjectives, setSelectedPrimaryObjectives] = useState([]);

    const fetchCarlines = useCallback(async () => {
        return qmrsService
            .fetchCarlines({
                signal: abortSignalRef.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setCarlineOptions([
                    { title: t('qmr:inputs.carline.placeholder'), value: '' },
                    ...response.data.carlines.map((c) => ({
                        title: c.name,
                        value: c.carlineId,
                    })),
                ]);
            });
    }, [abortSignalRef, t]);

    useEffect(() => {
        if (!account || !props.show) {
            return;
        }

        fetchAuthUserRetailers({
            searchParams: { canCreateQmr: true },
            qmrsService,
            authDispatch,
            signal: abortSignalRef.current,
        }).then((response) => {
            // if user has access to one retailer, pre-select it
            if (response?.data.retailers.length === 1) {
                setSelectedRetailer(response.data.retailers[0]);
            }
        });

        fetchCarlines();
    }, [abortSignalRef, account, authDispatch, fetchCarlines, props.show]);

    useEffect(() => {
        if (!props.show) {
            setSelectedVehicle(undefined);
            setVinInputValue('');
            clearVehicleDetailsForm();
            setSelectedRetailer(undefined);
            setContinueWithoutVin(false);
            setShowVehicleDetailsForm(false);
            setShowContinueWithoutVinWarning(false);
        }
    }, [props.show]);

    function handleCheckVinButtonClick(callCreateQmrOnVehicleSelect = false) {
        if (checkingVin) return;

        setVinError('');
        setCheckingVin(true);
        setPossibleVehicles([]);
        setShowVehicleDetailsForm(false);
        clearVehicleDetailsForm();

        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CHECKS_A_VIN);

        qmrsService
            .checkVin({
                vin: vinInputValue,
                signal: abortSignalRef.current,
                ignoreCache: true,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setCheckingVin(false);

                const vehicles = response.data.vehicles;

                setPossibleVehicles(vehicles);
                if (vehicles.length === 0) {
                    setVinError(t('qmr:inputs.vin.errors.noMatch', 'No matches found'));
                } else if (vehicles.length === 1) {
                    handleSelectVehicleClick(vehicles[0]);
                    if (callCreateQmrOnVehicleSelect) {
                        handleCreateQmrButtonClick(vehicles[0]);
                    }
                }
            })
            .catch((e) => {
                setCheckingVin(false);
                setVinError(e.message);
            });
    }

    function handleContinueWithoutVinButtonClick() {
        setPossibleVehicles([]);

        setShowContinueWithoutVinWarning(true);

        setVinInputValue('');
        setVinError('');
        clearVehicleDetailsForm();
    }

    function continueToCreateWithoutVinButtonClick() {
        setPossibleVehicles([]);

        setContinueWithoutVin(true);
        setShowVehicleDetailsForm(true);
        setShowContinueWithoutVinWarning(false);

        setVinInputValue('');
        clearVehicleDetailsForm();
    }

    function handleAddVinButtonClick() {
        setContinueWithoutVin(false);
        setShowVehicleDetailsForm(false);

        setShowContinueWithoutVinWarning(false);

        setVinInputValue('');
        clearVehicleDetailsForm();
    }

    function handleSelectVehicleClick(vehicle: Vehicle) {
        setSelectedVehicle(vehicle);
        setPossibleVehicles([]);
        setShowVehicleDetailsForm(true);

        setVinInputValue(vehicle.vin);
        setModelYearInputValue(vehicle.modelYear);
        setSelectedCarlineId(vehicle.carlineId);
        setTransmissionNumberInputValue(vehicle.transmissionNumber || '');
        setEngineNumberInputValue(vehicle.engineNumber || '');
        setColorInputValue(vehicle.colorCode);
        setModelInputValue(vehicle.modelCode);
        setModelOptionInputValue(vehicle.optionCode);
    }

    function handleCancelButtonClick() {
        props.onHide();
    }

    function handleCreateQmrButtonClick(currentSelectedVehicle?: Vehicle) {
        if (!selectedVehicle && !currentSelectedVehicle) {
            handleCheckVinButtonClick(true);
            return;
        }
        if (!selectedRetailer || (!vinInputValue && !selectedCarlineId) || isCreatingQmr) {
            return;
        }

        let qmrDetails: CreateQmrDto;

        if (vinInputValue) {
            qmrDetails = {
                ...(selectedVehicle || currentSelectedVehicle),
                retailerId: selectedRetailer.retailerId,
                vin: vinInputValue,
                engineNumber: engineNumberInputValue,
                transmissionNumber: transmissionNumberInputValue,
            };
        } else {
            qmrDetails = {
                retailerId: selectedRetailer.retailerId,
                carlineId: selectedCarlineId,
                modelYear: modelYearInputValue!,
                engineNumber: engineNumberInputValue,
                transmissionNumber: transmissionNumberInputValue,
            };
        }

        if (isPrimaryObjectiveEnabled) {
            qmrDetails.qmrCategories = selectedPrimaryObjectives.map((c: any) => c.qmrCategoryId);
        }

        setIsCreatingQmr(true);

        qmrsService
            .createQmr(qmrDetails, abortSignalRef.current)
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                analyticsService.logEvent(ANALYTICS_EVENTS.USER_CREATES_DRAFT_QMR, {
                    tsId: response.data.qmr.stsIdentifier,
                });

                setIsCreatingQmr(false);
                props.onHide(response.data.qmr.displayIdentifier);
            })
            .catch((e) => {
                setIsCreatingQmr(false);
                alert(e.message);
            });
    }

    function handleOnHide() {
        props.onHide();
    }

    function clearVehicleDetailsForm() {
        setModelYearInputValue(undefined);
        setTransmissionNumberInputValue('');
        setEngineNumberInputValue('');
        setColorInputValue('');
        setModelInputValue('');
        setModelOptionInputValue('');
    }

    function onPrimaryObjectivesSelected(selectedOptions: any) {
        setSelectedPrimaryObjectives(selectedOptions);
    }

    function getContinueWithoutVinWarning() {
        return (
            <>
                <div className="d-flex mb-4">
                    <Icon name="warning" />

                    <div className="d-flex flex-column ml-2">
                        <div className="mb-2">
                            <Typography variant="h5">{t('modals:createQmr.withoutVin.title')}</Typography>
                        </div>

                        <Typography variant="lead">{t('modals:createQmr.withoutVin.description')}</Typography>
                    </div>
                </div>

                <Row>
                    <Col xs={6}>
                        <Card>
                            <Card.Body>
                                <div className="mb-7">
                                    <div className="mb-1">
                                        <Typography variant="h4">
                                            {t('modals:createQmr.options.withVin.title')}
                                        </Typography>
                                    </div>

                                    <Typography variant="lead">
                                        {t('modals:createQmr.options.withVin.description')}
                                    </Typography>
                                </div>

                                <Button variant="primary" onPress={handleAddVinButtonClick}>
                                    {t('modals:createQmr.options.withVin.cta')}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card>
                            <Card.Body>
                                <div className="mb-7">
                                    <div className="mb-1">
                                        <Typography variant="h4">
                                            {t('modals:createQmr.options.withoutVin.title')}
                                        </Typography>
                                    </div>

                                    <Typography variant="lead">
                                        {t('modals:createQmr.options.withoutVin.description')}
                                    </Typography>
                                </div>

                                <Button variant="secondary" onPress={continueToCreateWithoutVinButtonClick}>
                                    {t('modals:createQmr.options.withoutVin.cta')}
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    function getCreationForm() {
        return (
            <>
                {account?.systemCapabilities.createDraftQmrForAnyRetailer || accountRetailers.length > 1 ? (
                    <div className="mb-4">
                        <Label required controlId="newQmr-retailer">
                            {t('qmr:inputs.retailer.label')}
                        </Label>
                        <RetailerSearchInput selectedRetailer={selectedRetailer} onChange={setSelectedRetailer} />
                    </div>
                ) : null}

                {isPrimaryObjectiveEnabled ? (
                    <SelectQmrPrimaryObjectives onSelect={onPrimaryObjectivesSelected} />
                ) : null}

                <Row className="mt-4">
                    <Col xs={12}>
                        <TextInputHelper
                            controlId="newQmr-vin"
                            maxLength={17}
                            value={vinInputValue}
                            onChangeText={(text) => setVinInputValue(text.toUpperCase())}
                            autoCapitalize="characters"
                            required={!continueWithoutVin}
                            label={t('qmr:inputs.vin.label')}
                            labelHint={t('qmr:inputs.vin.hint', 'VIN should be either 8 or 17 characters long')}
                            placeholder={t('qmr:inputs.vin.placeholder')}
                            showCharCount
                            rightIcon={({ inputState }) => {
                                const canCheckVin = vinInputValue.length === 8 || vinInputValue.length === 17;

                                const disableCheckButton =
                                    inputState.isDisabled || continueWithoutVin || !canCheckVin || checkingVin;

                                return (
                                    <Button
                                        variant="primary"
                                        style={{
                                            height: inputState.hasError || inputState.isFocused ? 36 : 38,
                                            marginRight: -12,
                                        }}
                                        disabled={disableCheckButton}
                                        onPress={() => handleCheckVinButtonClick()}
                                    >
                                        {checkingVin
                                            ? `${t('text:loading.checking')}...` // TODO: Loading spinner instead of text
                                            : t('modals:createQmr.actions.checkVin')}
                                    </Button>
                                );
                            }}
                            editable={!continueWithoutVin && !checkingVin}
                            errorMessage={vinError}
                        />
                    </Col>

                    {/* SD-1389 Disable "No VIN" flow */}
                    {/* <Col xs={12}>
                        <div className="d-inline-block mt-2">
                            {continueWithoutVin ? (
                                <Button variant="link" onPress={handleAddVinButtonClick}>
                                    <Typography style={{ textDecorationLine: 'underline' }} color="graySix">
                                        {t('modals:createQmr.actions.addVin')}
                                    </Typography>
                                </Button>
                            ) : (
                                <Button variant="link" onPress={handleContinueWithoutVinButtonClick}>
                                    <Typography style={{ textDecorationLine: 'underline' }} color="graySix">
                                        {t('modals:createQmr.actions.continueWithoutVin')}
                                    </Typography>
                                </Button>
                            )}
                        </div>
                    </Col> */}
                </Row>

                {(possibleVehicles.length > 0 || showVehicleDetailsForm) && <hr className="my-3" />}

                {possibleVehicles.length > 0 && (
                    <>
                        <Row>
                            <Col xs={12}>
                                <Typography variant="lead">
                                    {t('modals:createQmr.multipleVinMatches', { count: possibleVehicles.length })}
                                </Typography>

                                <div className="mb-5">
                                    <Typography variant="lead">
                                        {t(
                                            'modals:createQmr.selectVehicle',
                                            'Please select the correct vehicle for VIN '
                                        )}
                                        <Typography variant="h6">{vinInputValue}</Typography>
                                    </Typography>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {possibleVehicles.map((possibleVehicle, index) => {
                                return (
                                    <Col xs={6} key={index}>
                                        <PossibleVehicleCard
                                            vehicle={possibleVehicle}
                                            onSelect={() => handleSelectVehicleClick(possibleVehicle)}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                )}
                {showVehicleDetailsForm && (
                    <>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Typography variant="h5">
                                    {continueWithoutVin
                                        ? t('modals:createQmr.withoutVin.details.title')
                                        : t('modals:createQmr.withVin.details.title')}
                                </Typography>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={6}>
                                <TextInputHelper
                                    required
                                    editable={!vinInputValue}
                                    controlId="newQmr-modelYear"
                                    label={t('qmr:inputs.modelYear.label')}
                                    value={modelYearInputValue ? '' + modelYearInputValue : ''}
                                    maxLength={4}
                                    onChangeText={(newVal) => {
                                        setModelYearInputValue(parseInt(newVal, 10));
                                    }}
                                    placeholder={t('qmr:inputs.modelYear.placeholder')}
                                />
                            </Col>
                            <Col xs={6}>
                                <Label required controlId="newQmr-carline">
                                    {t('qmr:inputs.carline.label')}
                                </Label>
                                <Select
                                    disabled={!!vinInputValue}
                                    id="newQmr-carline"
                                    value={selectedCarlineId}
                                    options={carlineOptions}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        setSelectedCarlineId(event.target.value);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col xs={6}>
                                <TextInputHelper
                                    controlId="newQmr-engineNumber"
                                    label={t('qmr:inputs.engineNumber.label')}
                                    value={engineNumberInputValue}
                                    onChangeText={setEngineNumberInputValue}
                                    placeholder={t('qmr:inputs.engineNumber.placeholder')}
                                />
                            </Col>
                            <Col xs={6}>
                                <TextInputHelper
                                    controlId="newQmr-transmissionNumber"
                                    label={t('qmr:inputs.transmissionNumber.label')}
                                    value={transmissionNumberInputValue}
                                    onChangeText={setTransmissionNumberInputValue}
                                    placeholder={t('qmr:inputs.transmissionNumber.placeholder')}
                                />
                            </Col>
                        </Row>

                        {!continueWithoutVin && (
                            <Row className="mb-4">
                                <Col xs={4}>
                                    <TextInputHelper
                                        editable={false}
                                        label={t('qmr:inputs.color.label')}
                                        value={colorInputValue}
                                        onChangeText={setEngineNumberInputValue}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <TextInputHelper
                                        editable={false}
                                        label={t('qmr:inputs.model.label')}
                                        value={modelInputValue}
                                        onChangeText={setEngineNumberInputValue}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <TextInputHelper
                                        editable={false}
                                        label={t('qmr:inputs.modelOptions.label')}
                                        value={modelOptionInputValue}
                                        onChangeText={setEngineNumberInputValue}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
            </>
        );
    }

    return (
        <Modal show={props.show} centered={true} onHide={handleOnHide} size="lg">
            <Modal.Header placeholder={''}>
                <Modal.Title>{t('modals:createQmr.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>{showContinueWithoutVinWarning ? getContinueWithoutVinWarning() : getCreationForm()}</Form>
            </Modal.Body>

            {!showContinueWithoutVinWarning && (
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={handleCancelButtonClick}>
                            {t('buttons:cancel')}
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={isCreatingQmr}
                        disabled={
                            !selectedRetailer ||
                            (continueWithoutVin
                                ? !selectedCarlineId || !modelYearInputValue
                                : possibleVehicles.length > 0 || vinInputValue.length < 17) ||
                            String(modelYearInputValue).length < 4 ||
                            (isPrimaryObjectiveEnabled && !selectedPrimaryObjectives?.length)
                        }
                        onPress={() => handleCreateQmrButtonClick()}
                    >
                        {t('modals:createQmr.actions.create')}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default CreateQmrModal;
