import React, { FC, useEffect, useRef, useState } from 'react';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Icon, Button, Typography } from '@packages/ui/shared';
import VinSessionDropdown from './vin-session-dropdown';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { VIN } from '../types';
import NoImageSubaruCar from '../assets/2CFlat_Hor_White.png';

type VinSessionBannerProps = {};

const useStyles = createUseStyles({
    '@global': {
        '@keyframes moveCar': {
            '0%': {
                transform: 'translateX(-100vw)',
            },
            '100%': {
                transform: 'translateX(0)',
            },
        },
        '@keyframes moveRectangle': {
            '0%': {
                transform: 'translateX(-100vw)',
            },
            '100%': {
                transform: 'translateX(0)',
            },
        },
    },
    bannerContainer: {
        backgroundColor: colors.blueOneHover,
        minHeight: 88,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 175,
    },
    rectangle: {
        width: 82,
        height: 38,
        backgroundColor: '#E6E6E6',
        border: '1px solid #ccc',
        zIndex: 100,
        animation: 'moveRectangle 0.5s ease-out forwards',
    },
    image: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        animation: 'moveCar 0.5s ease-out forwards',
    },
    detailsContainer: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        gap: 32,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    statusContainer: {
        height: 18,
        width: 131,
        borderRadius: 4,
        padding: '1px 8px 1px 8px',
        display: 'flex',
        alignContent: 'center',
        gap: 4,
        color: colors.black,
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 5,
        gap: 4,
    },
    imageContainer: {
        width: 160,
        height: 100,
        position: 'absolute',
        top: -11,
        left: 32,
        overflow: 'hidden',
        zIndex: 101,
    },
    imageContainerError: {
        width: 150,
        height: 50,
        position: 'absolute',
        top: 19,
        left: 55,
        overflow: 'hidden',
        zIndex: 101,
    },
});

const VinSessionBanner: FC<VinSessionBannerProps> = (props) => {
    const classes = useStyles();
    const [vinDetailsDropDown, setVinDetailsDropDown] = useState<boolean>(false);
    const [vinData, setVinData] = useState<VIN>();
    const dropDownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const { selectedVin } = useVinSessionState();

    useEffect(() => {
        if (selectedVin != undefined) {
            setVinData(selectedVin);
        }
    }, [selectedVin]);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
            if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
                setVinDetailsDropDown(false);
            }
        }
    };

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = `${NoImageSubaruCar}`;
        setIsImageError(true);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className={classes.bannerContainer}>
                <div>
                    <div className={classes.rectangle} />
                    <div className={isImageError ? classes.imageContainerError : classes.imageContainer}>
                        <img
                            src={vinData?.vehicleImageUrl}
                            className={classes.image}
                            onError={handleImageError}
                            alt="VehicleImage"
                        />
                    </div>
                </div>
                <div className={classes.detailsContainer}>
                    <div className={classes.details}>
                        <Typography variant="caption" style={{ color: '#FFFFFF' }}>
                            Car Line
                        </Typography>
                        <Typography variant="caption4" style={{ color: '#FFFFFF' }}>
                            {vinData?.vinRecord.model}
                        </Typography>
                    </div>
                    <div className={classes.details}>
                        <Typography variant="caption" style={{ color: '#FFFFFF' }}>
                            Model Year
                        </Typography>
                        <Typography variant="caption4" style={{ color: '#FFFFFF' }}>
                            {vinData?.vinRecord.modelYear}
                        </Typography>
                    </div>
                    <div className={classes.details}>
                        <Typography variant="caption" style={{ color: '#FFFFFF' }}>
                            Engine
                        </Typography>
                        <Typography variant="caption4" style={{ color: '#FFFFFF' }}>
                            {`${vinData?.vinRecord.engineSize} L${vinData?.vinRecord.turboIndicator == 'true' ? ` Turbo` : ''} ${vinData?.vinRecord.engine}Cylinders ${vinData?.vinRecord.fuel}`}
                        </Typography>
                    </div>
                    <div className={classes.details} style={{ gap: 4 }}>
                        <Typography variant="caption" style={{ color: '#FFFFFF' }}>
                            Trim Level
                        </Typography>
                        <Typography variant="caption4" style={{ color: '#FFFFFF' }}>
                            {vinData?.vinRecord.trimDescription}
                        </Typography>
                    </div>
                    <div className={classes.details}>
                        <Typography variant="caption" style={{ color: '#FFFFFF' }}>
                            Transmission Type
                        </Typography>
                        <Typography variant="caption4" style={{ color: '#FFFFFF' }}>
                            {vinData?.vinRecord.driveTrain} {vinData?.vinRecord.transmissionType}
                        </Typography>
                    </div>
                    <div className={classes.details} ref={buttonRef}>
                        <Button
                            style={{
                                backgroundColor: !vinDetailsDropDown ? '#436CA1' : '#1F518F',
                                maxWidth: 136,
                                padding: 8,
                            }}
                            onPress={() => setVinDetailsDropDown(!vinDetailsDropDown)}
                        >
                            <div className={classes.button}>
                                <Typography
                                    id="test1"
                                    variant="caption3"
                                    style={{ textAlign: 'center', color: '#FFFFFF' }}
                                >
                                    {vinDetailsDropDown ? 'Hide details' : 'All details'}
                                </Typography>
                                <Icon
                                    name={!vinDetailsDropDown ? 'arrow-small-down' : 'arrow-small-up'}
                                    style={{ color: '#FFFFFF', marginTop: !vinDetailsDropDown ? 1 : 0 }}
                                />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            {vinDetailsDropDown && <VinSessionDropdown vinData={vinData} dropDownRef={dropDownRef} />}
        </div>
    );
};

export default VinSessionBanner;
