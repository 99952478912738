/* eslint-disable react-native/no-inline-styles */
import React, { FC } from 'react';
import { StyleSheet, StyleProp, ViewStyle, View, ScrollView, TouchableOpacity, Platform } from 'react-native';
import { Button, Typography, Icon, TextBadge, Margin } from '../shared';
import { useTranslation } from 'react-i18next';
import { InvestigationModel, Qmr, QmrActivityType } from '@packages/models/api';
import { getQmrRoleReview } from '@packages/contexts/qmrs';
import { useAuthState } from '@packages/contexts/auth';
import colors from '@packages/core/styles/colors';
import { useInvestigationModal } from '@packages/contexts/investigations';
import InfoCard from '../shared/info-card';
import { AccountsService } from '@packages/contexts/accounts';
import { getBadgeVariant, getBadgeVariantTRSharedReply } from '../utils';

interface QmrUserProps {
    style?: StyleProp<ViewStyle>;
    avatar?: JSX.Element;
    label: string | JSX.Element;
    name: string;
    date?: string;
    accountService: AccountsService;
    accountId?: string;
    userName?: string;
}

const QmrUser: FC<QmrUserProps> = ({ style, avatar, label, name, date, accountService, accountId, userName }) => {
    return (
        <>
            <View style={[styles.userContainer, style]}>
                {typeof label === 'string' ? <Typography variant="h6">{label}</Typography> : <View>{label}</View>}

                <View style={styles.avatarWrapper}>
                    {avatar}

                    <View style={avatar && styles.avatarNameWrapper}>
                        <InfoCard
                            content={name}
                            accountId={accountId}
                            userName={userName}
                            accountService={accountService}
                        />

                        {!!date && <Typography variant="caption">{date}</Typography>}
                    </View>
                </View>
            </View>
        </>
    );
};

interface QmrDetailsSegmentProps {
    qmr: Qmr | null;
    avatarComponent?: React.ComponentType<{ firstName: string; lastName: string }>;
    onEditRetailer?: () => void;
    onInvestigationLinkPress?: () => void;
    onAddedToInvestigationSuccess?: (investigation: InvestigationModel) => void;
    accountService: AccountsService;
    style?: StyleProp<ViewStyle>;
}

export const QmrDetailsSegment: FC<QmrDetailsSegmentProps> = ({
    qmr,
    avatarComponent: AvatarComponent,
    onEditRetailer,
    onInvestigationLinkPress,
    onAddedToInvestigationSuccess,
    accountService,
    style,
}) => {
    const { t } = useTranslation();
    const { account } = useAuthState();
    const { showModal } = useInvestigationModal();

    if (!qmr) {
        return null;
    }

    const roleReview = getQmrRoleReview(qmr);

    const renderReviewProcess = () => {
        if (!account?.systemCapabilities.viewQmrRoleReviewOnList) {
            return null;
        }

        const dsqmChangeActivity = qmr.qmrActivities?.filter(
            (a) => a.qmrActivityTypeId === QmrActivityType.DsqmReviewStatusChanged
        )[0];
        // TODO: is the qa activity type id QmrStatusChanged | ReviewStatusChanged
        const qaChangeActivity = qmr.qmrActivities?.filter(
            (a) => a.qmrActivityTypeId === QmrActivityType.ReviewStatusChanged
        )[0];

        return (
            <View>
                <View style={styles.section}>
                    <Typography variant="h6">Review Process</Typography>
                </View>

                <QmrUser
                    style={styles.section}
                    label={
                        <View style={styles.row}>
                            <Typography variant="caption">DSQM:</Typography>
                            <Margin right={5} />

                            <TextBadge
                                icon={
                                    <Icon name={roleReview.dqsm.iconName} color={roleReview.dqsm.iconColor} size={20} />
                                }
                            >
                                {qmr.dsqmReviewStatus.description}
                            </TextBadge>
                        </View>
                    }
                    avatar={
                        AvatarComponent &&
                        dsqmChangeActivity && (
                            <AvatarComponent
                                firstName={dsqmChangeActivity.createdAccount.firstName}
                                lastName={dsqmChangeActivity.createdAccount.lastName}
                            />
                        )
                    }
                    name={dsqmChangeActivity?.createdAccount.name || ''}
                    date={dsqmChangeActivity?.createdTimestampDescription || ''}
                    accountService={accountService}
                    accountId={dsqmChangeActivity?.createdAccount.accountId}
                />

                <QmrUser
                    style={styles.section}
                    label={
                        <View style={styles.row}>
                            <Typography variant="caption">QA:</Typography>
                            <Margin right={5} />

                            <TextBadge
                                icon={<Icon name={roleReview.qa.iconName} color={roleReview.qa.iconColor} size={20} />}
                                variant={roleReview.qa.badgeVariant}
                            >
                                {qmr.qmrStatus.description}
                            </TextBadge>
                        </View>
                    }
                    avatar={
                        AvatarComponent &&
                        qaChangeActivity && (
                            <AvatarComponent
                                firstName={qaChangeActivity.createdAccount.firstName}
                                lastName={qaChangeActivity.createdAccount.lastName}
                            />
                        )
                    }
                    name={qaChangeActivity?.createdAccount.name || ''}
                    date={qaChangeActivity?.createdTimestampDescription || ''}
                    accountService={accountService}
                    accountId={qaChangeActivity?.createdAccount.accountId}
                />
            </View>
        );
    };

    return (
        <ScrollView contentContainerStyle={style}>
            {qmr.capabilities.viewRetailerOnQmr && (
                <View style={styles.section}>
                    <View style={styles.retailerLabelWrapper}>
                        <Typography variant="h6">{t('qmr:details.retailer.title', 'Retailer')}</Typography>
                        {Platform.OS === 'web' && (
                            <>
                                {qmr.capabilities.editRetailerOnQmr && (
                                    <TouchableOpacity
                                        onPress={onEditRetailer}
                                        disabled={!onEditRetailer}
                                        style={styles.retailerLink}
                                    >
                                        <Typography
                                            variant="caption"
                                            color="textDarkSecondary"
                                            style={styles.editRetailer}
                                        >
                                            {t('qmr:details.retailer.edit', 'Change Retailer')}
                                        </Typography>
                                    </TouchableOpacity>
                                )}
                            </>
                        )}
                    </View>
                    <Typography style={styles.marginTop_8} color="blueOne">
                        {qmr.retailer.name} (#{qmr.retailer.code})
                    </Typography>
                    <Typography>
                        Region {qmr.retailer.regionCode}, Zone {qmr.retailer.zoneCode}, District{' '}
                        {qmr.retailer.districtCode}
                    </Typography>
                </View>
            )}

            {qmr.dsqmAccount && (
                <QmrUser
                    style={styles.section}
                    label={t('qmr:details.entities.localDSQM', 'Local DSQM')}
                    avatar={
                        AvatarComponent && (
                            <AvatarComponent
                                firstName={qmr.dsqmAccount.firstName}
                                lastName={qmr.dsqmAccount.lastName}
                            />
                        )
                    }
                    name={qmr.dsqmAccount.name}
                    accountService={accountService}
                    accountId={qmr.dsqmAccount.accountId}
                />
            )}

            {qmr.capabilities.viewCreatorNameOnQmr && (
                <>
                    {qmr.createdAccount && (
                        <QmrUser
                            style={styles.section}
                            label={t('qmr:details.entities.created', 'Created')}
                            avatar={
                                AvatarComponent && (
                                    <AvatarComponent
                                        firstName={qmr.createdAccount.firstName}
                                        lastName={qmr.createdAccount.lastName}
                                    />
                                )
                            }
                            name={qmr.createdAccount.name}
                            date={qmr.relativeCreatedTimestampDescription}
                            accountService={accountService}
                            accountId={qmr.createdAccount.accountId}
                        />
                    )}

                    {qmr.lastUpdatedAccount && (
                        <QmrUser
                            style={styles.section}
                            label={t('qmr:details.entities.updated', 'Updated')}
                            avatar={
                                AvatarComponent && (
                                    <AvatarComponent
                                        firstName={qmr.lastUpdatedAccount.firstName}
                                        lastName={qmr.lastUpdatedAccount.lastName}
                                    />
                                )
                            }
                            name={qmr.lastUpdatedAccount.name}
                            date={qmr.relativeLastEditedTimestampDescription}
                            accountService={accountService}
                            accountId={qmr.lastUpdatedAccount.accountId}
                        />
                    )}
                </>
            )}

            {/* <QmrUser
                style={styles.section}
                label={t('qmr:details.entities.firstSubmitted', 'First Submitted')}
                avatar={<AvatarComponent  />}
                name="User Name"
                date="Nov 12, 2019 at 10:01 AM"
            /> */}

            <View style={styles.divider} />

            {renderReviewProcess()}

            {Platform.OS === 'web' && (
                <>
                    {account?.investigationCapabilities.viewInvestigations &&
                        qmr.investigationId &&
                        qmr.investigationTitle && (
                            <View style={styles.section}>
                                <Typography variant="h6">
                                    {t('qmr:details.investigations.title', 'Investigations')}
                                </Typography>
                                <View style={styles.buttonOuter}>
                                    <Button
                                        variant="link"
                                        title={qmr.investigationTitle}
                                        onPress={onInvestigationLinkPress}
                                    />
                                </View>
                            </View>
                        )}

                    {account?.investigationCapabilities.editAnInvestigation && !qmr.investigationId && (
                        <View style={styles.section}>
                            <Typography variant="h6">
                                {t('qmr:details.investigations.title', 'Investigations')}
                            </Typography>
                            <View style={{ marginTop: 8, flexDirection: 'row' }}>
                                <Button
                                    alignTitle="left"
                                    iconLeft={<Icon color="white" name="folders" />}
                                    onPress={() => {
                                        showModal({
                                            selectedQmrs: [qmr],
                                            onSuccess: onAddedToInvestigationSuccess,
                                        });
                                    }}
                                >
                                    {t('buttons:addToInvestigation', 'Add to Investigation')}
                                </Button>
                            </View>
                        </View>
                    )}

                    {qmr.capabilities.canViewEscalationOnQmr && (
                        <>
                            <View style={styles.divider} />

                            <View style={styles.section}>
                                <View style={styles.trReplyWrapper}>
                                    <View style={styles.trReplySection}>
                                        <Typography variant="h6">TR Reply Status</Typography>
                                        <TextBadge
                                            style={styles.marginTop_8}
                                            variant={getBadgeVariant(qmr.trReplyStatus?.trReplyStatusId ?? 'gray')}
                                        >
                                            {qmr.trReplyStatus?.description}
                                        </TextBadge>
                                    </View>
                                    <View style={styles.trReplySection}>
                                        <Typography variant="h6">TR Reply Received</Typography>
                                        <Typography style={styles.marginTop_8}>{qmr.trReplyRecieved}</Typography>
                                    </View>
                                </View>

                                <View style={styles.trReplySection}>
                                    <Typography variant="h6">TR Reply Shared</Typography>
                                    <TextBadge
                                        style={styles.marginTop_8}
                                        variant={getBadgeVariantTRSharedReply(qmr.trReplyShared)}
                                    >
                                        {qmr.trReplyShared}
                                    </TextBadge>
                                </View>
                            </View>
                        </>
                    )}
                </>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    userContainer: {
        minHeight: 82,
    },
    avatarWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    avatarNameWrapper: {
        marginLeft: 16,
    },
    section: {
        paddingVertical: 12,
        paddingHorizontal: 20,
    },
    retailerLabelWrapper: {
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    marginTop_8: {
        marginTop: 8,
    },
    editRetailer: {
        textTransform: 'uppercase',
        fontSize: 11,
    },
    retailerLink: {
        alignSelf: 'flex-start',
        marginTop: 2,
        marginLeft: 8,
    },
    buttonOuter: {
        alignItems: 'flex-start',
    },
    button: {
        textDecorationLine: 'none',
    },
    row: { flex: 1, flexDirection: 'row', alignItems: 'center' },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: colors.grayFour,
    },
    trReplyWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 12,
    },
    trReplySection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});
