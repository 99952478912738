import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { SkeletonLoader } from '@web/components/skeleton-loader';

const useStyles = createUseStyles({
    dataCardHeading: {
        backgroundColor: '#F8F8F8',
        borderBottom: '1px solid #E3E3E3',
        padding: '20px 16px',
    },
    loaderLeft: {
        maxWidth: '65%',
    },
    loaderRight: {
        maxWidth: '25%',
    },
});

interface DataCardHeaderProps {
    headerBadge?: React.ReactNode | Element | null;
    headerText: string;
    finalBatteryTestDecision?: string;
    loading?: boolean;
}

export const DataCardHeader: FC<DataCardHeaderProps> = ({ headerBadge = null, headerText, loading = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            {loading ? (
                <div
                    className={`d-flex justify-content-between align-items-center w-100 flex-wrap ${classes.dataCardHeading}`}
                    data-testid="data-card-header-loading"
                >
                    <SkeletonLoader type="text" className={classes.loaderLeft} />
                    <SkeletonLoader type="text" className={classes.loaderRight} />
                </div>
            ) : (
                <div
                    className={`d-flex justify-content-between align-items-center w-100 flex-wrap ${classes.dataCardHeading}`}
                    data-testid="data-card-header"
                >
                    <>
                        <Typography variant="label">{t(headerText)}</Typography>
                        {headerBadge || ''}
                    </>
                </div>
            )}
        </div>
    );
};
