import React, { FC, useCallback, useMemo } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { Icon, Typography } from '..';
import colors from '@packages/core/styles/colors';

interface ContactInfo {
    name?: string;
    jobTitle?: string;
    phoneNumber?: string;
    email?: string;
    locationDetails?: string;
}

interface Props {
    contactInfo: ContactInfo;
    additionalDetails?: { [key: string]: string };
}

const Card: FC<Props> = ({ contactInfo, additionalDetails }) => {
    const info = useMemo(() => {
        const { name, phoneNumber, email, jobTitle, locationDetails } = contactInfo;

        return {
            name,
            phoneNumber,
            email,
            jobTitle,
            locationDetails,
            ...additionalDetails,
        };
    }, [contactInfo, additionalDetails]);

    const handleEmailClick = useCallback(() => {
        if (info.email) {
            Linking.openURL(`mailto:${info.email}`);
        }
    }, [info.email]);

    return (
        <>
            <View style={styles.section}>
                {info.name && <Typography style={styles.name}>{info.name}</Typography>}
                {info.jobTitle && (
                    <Typography variant="caption" style={styles.marginVertical3}>
                        {info.jobTitle}
                    </Typography>
                )}
                {info.locationDetails && (
                    <Typography variant="caption" style={styles.marginVertical3}>
                        {info.locationDetails}
                    </Typography>
                )}
            </View>

            <View style={styles.divider} />

            {info.email && (
                <View style={styles.iconTextContainer}>
                    <Icon name="launch" style={styles.icon} />
                    <Typography variant="caption" style={styles.linkText} onPress={handleEmailClick}>
                        {info.email}
                    </Typography>
                </View>
            )}

            {info.phoneNumber && (
                <View style={styles.iconTextContainer}>
                    <Icon name="phone" style={styles.icon} />
                    <Typography style={styles.marginVertical3}>{info.phoneNumber}</Typography>
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    section: {
        marginVertical: 5,
    },
    name: {
        fontSize: 24,
        fontWeight: '700',
        lineHeight: 24,
        marginVertical: 3,
    },
    iconTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 3,
    },
    icon: {
        marginRight: 10,
    },
    linkText: {
        color: colors.blueOne,
    },
    divider: {
        height: 10,
        borderBottomWidth: 1,
        borderColor: colors.grayFour,
    },
    marginVertical3: {
        marginVertical: 3,
    },
});

export default Card;
