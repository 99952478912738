import {
    WorksheetElementAnswers,
    WorksheetForm,
    WorksheetFormElement,
    WorksheetFormGetResponse,
} from '@packages/models/api/worksheets';
import { techlineService, worksheetsService } from '@web/services/singletons';
import { useCallback, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { findLatest } from '../../../../administration/views/worksheets/utils/worksheet-form';
import { Asset, AssetProcessingState } from '@packages/models/api';
const useWorksheetSubmissionData = ({
    caseNumber,
    submissionId,
    worksheetId,
}: {
    caseNumber: string;
    submissionId: string;
    worksheetId: string;
}) => {
    const [worksheetSubmissionData, setWorksheetSubmissionData] = useState<WorksheetFormGetResponse>();
    const [fetching, setFetching] = useState<boolean>(false);
    const timeoutRef = useRef<any>(null);

    const fetchWorksheetData = useCallback(async () => {
        setFetching(true);
        worksheetsService
            .fetchWorksheetFormById(worksheetId)
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    console.log(response.data);
                } else {
                    const data = response.data;
                    setWorksheetSubmissionData(data);
                }
                setFetching(false);
            })
            .catch((error) => {
                alert('Error: ' + error + '. Please refresh & try again.');
                console.log(error);
                setFetching(false);
            });
    }, [worksheetId]);

    const fetchAssets = useCallback(async (assetDetailsData: Asset[]) => {
        try {
            if (assetDetailsData?.length) {
                const inProgressAssets = assetDetailsData.filter(
                    (asset: any) => asset.assetProcessingStateId === AssetProcessingState.Uploading
                );
                if (!!inProgressAssets?.length) {
                    setTimeout(() => {
                        pullingAssets(inProgressAssets, 0);
                    }, 5000);
                }
            }
        } catch (err) {
            console.log('Assets fetching Error: ', err, { caseNumber });
        }
    }, []);

    const pullingAssets = useCallback(async (uploadingAssets: any[], count: number = 0) => {
        if (uploadingAssets.length) {
            const responses = await Promise.all(uploadingAssets.map((a) => techlineService.retrieveAsset(a.assetId)));
            const otherStatusAssets: any[] = [];
            const newUploadingAssets: any[] = [];
            responses.forEach((r) => {
                if (r.success) {
                    const asset = r.data?.results?.asset;
                    if (asset?.assetProcessingStateId === AssetProcessingState.Uploading) {
                        newUploadingAssets.push(asset);
                    } else {
                        otherStatusAssets.push(asset);
                    }
                }
            });

            if (otherStatusAssets.length) {
                setWorksheetSubmissionData((prev) => {
                    console.log('pullingAssets setWorksheetSubmissionData prev: => ', prev);
                    const prevClone = cloneDeep(prev);
                    otherStatusAssets.forEach((a) => {
                        prevClone?.elements.forEach((ele) => {
                            if (ele.answerAssets?.length) {
                                ele.answerAssets.forEach((ansAsset) => {
                                    if (ansAsset.assetId === a.assetId) {
                                        Object.assign(ansAsset, a);
                                    }
                                });
                            }
                        });
                    });

                    console.log('pullingAssets setWorksheetSubmissionData prevClone: => ', prevClone);
                    return prevClone;
                });
            }
            if (newUploadingAssets?.length && count < 5) {
                timeoutRef.current = setTimeout(() => {
                    pullingAssets(newUploadingAssets, ++count);
                }, 5000);
            }
        }
    }, []);

    const clearPullingAssetsTimeout = () => {
        // Cleanup function to stop recursion on component unload
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const fetchRetrieveWorksheetSubmission = useCallback(async () => {
        setFetching(true);
        worksheetsService
            .retrieveWorksheetSubmission({ submissionId, caseNumber })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    console.log(response.data);
                } else {
                    const data = response.data;
                    const assetDetails = response.data.elements
                        .map((ele) => {
                            if (ele.answerAssets?.length) {
                                return ele.answerAssets;
                            }
                            return null;
                        })
                        .filter((a) => a)
                        .flatMap((a) => a);
                    const assetDetailsData = (assetDetails as unknown as Asset[]).filter(
                        (a: { assetProcessingStateId: AssetProcessingState }) =>
                            [AssetProcessingState.Complete, AssetProcessingState.Uploading].includes(
                                a.assetProcessingStateId
                            )
                    );
                    if (assetDetailsData.length) {
                        fetchAssets(assetDetailsData);
                    }

                    setWorksheetSubmissionData(data);
                }

                setFetching(false);
            })
            .catch((error) => {
                alert('Error: ' + error + '. Please refresh & try again.');
                console.log(error);
                setFetching(false);
            });
    }, [caseNumber, submissionId]);

    const prepareElementAnswer = useCallback((elements: WorksheetFormElement[]) => {
        let elementsSelectedOptions: { [x: string]: WorksheetElementAnswers } = {};

        const elementsClone = cloneDeep(elements);
        elementsClone?.forEach((element) => {
            if (element.selectedOptions?.length || element.answerAssets?.length) {
                elementsSelectedOptions = {
                    ...elementsSelectedOptions,
                    [element.worksheetElementId]: {
                        elementId: element.worksheetElementId,
                        selectedOptions:
                            element.selectedOptions?.map((option) => ({
                                optionId: option.optionId,
                                optionLabel: option.optionLabel || '',
                                answerText: option.answerText || '',
                            })) || [],
                        asset: { answerFile: findLatest(element.answerAssets)! },
                    },
                };
            }
        });
        return elementsSelectedOptions;
    }, []);

    return {
        worksheetData: worksheetSubmissionData,
        fetching,
        actions: {
            fetchWorksheetData,
            fetchRetrieveWorksheetSubmission,
            prepareElementAnswer,
            clearPullingAssetsTimeout,
        },
    };
};

export default useWorksheetSubmissionData;
