import React from 'react';
import { createUseStyles } from 'react-jss';
import { DataCardHeader } from '@web/components/data-card/data-card-header';
import { DataCardBody, DataCardItem } from '@web/components/data-card/data-card-body';
import { DataCardFooter } from '@web/components/data-card/data-card-footer';
import { DataCardEmptyState } from './data-card-empty-state';

const useStyles = createUseStyles({
    dataCardContainer: {
        border: '1px solid #e3e3e3',
        marginBottom: '16px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
    },
});

export interface DataCardProps {
    data?: DataCardItem[];
    footerButtonLink: string;
    footerButtonText: string;
    headerBadge?: React.ReactNode | Element;
    headerText?: string;
    loading?: boolean;
    isEmpty?: boolean;
    emptyTitle?: string;
    emptyDescription?: string;
}

export const DataCard: React.FC<DataCardProps> = React.memo(
    ({
        data = [],
        footerButtonLink = '',
        footerButtonText = '',
        headerBadge = '',
        headerText = '',
        loading = false,
        isEmpty = false,
        emptyTitle,
        emptyDescription,
    }) => {
        const classes = useStyles();

        return (
            <div className={classes.dataCardContainer}>
                <DataCardHeader headerBadge={headerBadge} headerText={headerText} loading={loading} />
                {!isEmpty ? (
                    <>
                        <DataCardBody data={data} loading={loading} />
                        <DataCardFooter buttonLink={footerButtonLink} buttonText={footerButtonText} loading={loading} />
                    </>
                ) : (
                    <DataCardEmptyState
                        titleText={emptyTitle || 'No Title Provided'}
                        description={emptyDescription || 'No Description Provided'}
                    />
                )}
            </div>
        );
    }
);
