import React, { FC, useCallback, useState } from 'react';
import { Label, Button, Icon, Typography } from '@packages/ui/shared';
import Modal from 'react-bootstrap/Modal';
import { accountsService, techlineService } from '@web/services/singletons';
import colors from '@packages/core/styles/colors';
import { StyleSheet, View } from 'react-native';
import Avatar from '@web/components/avatar';
import { Retailer } from '@packages/models/api/techline';
import { GetRetailerByRetailerResponseBody } from '@packages/contexts/accounts';
import InfoCard from '@packages/ui/shared/info-card';
import AsyncSelect from 'react-select/async';
interface OptionType {
    label: string;
    value: GetRetailerByRetailerResponseBody;
}
interface Props {
    retailerNumber: string;
    caseNumber: string;
    retailerProfiles: Retailer['retailerProfiles'];
    modifiedOnDescription?: string;
    showError?: (error: string) => void;
    disableAddMember?: boolean;
}
const CaseTeam: FC<Props> = ({
    retailerNumber,
    caseNumber,
    retailerProfiles,
    showError,
    disableAddMember,
    modifiedOnDescription,
}) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

    const fetchMembers = useCallback(
        async (inputValue: string, callback: (options: OptionType[]) => void): Promise<OptionType[]> => {
            if (inputValue.length < 3) {
                callback([]);
                return [];
            }
            setIsLoading(true);
            try {
                const data = await accountsService.getRetailersByRetailerNumber({
                    text: inputValue,
                    retailerCode: retailerNumber,
                });

                if (data.success) {
                    const options = data.data.map((retailer) => ({
                        label: `${retailer.firstName} ${retailer.lastName} (${retailer.soaUsername}) - ${retailer.jobTitle}`,
                        value: retailer,
                    }));
                    callback(options); // Directly call the callback with the options
                    return options;
                } else {
                    callback([]);
                    return [];
                }
            } catch (error) {
                console.error('Error fetching members:', error);
                callback([]);
                return [];
            } finally {
                setIsLoading(false);
            }
        },
        [retailerNumber]
    );

    const addMember = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await techlineService.patchRetailerProfile({
                casenumber: caseNumber,
                username: selectedOption?.value.soaUsername!,
                retailernumber: retailerNumber,
                statuscode: 2,
            });
            if (!res.success) {
                showError && showError('User could not be added because of a server error.');
            }
        } catch (error) {
            console.error('Add Member error:', error);
            showError && showError('User could not be added because of a server error.');
        } finally {
            setIsLoading(false);
            setShow(false);
        }
    }, [caseNumber, retailerNumber, selectedOption]);

    return (
        <>
            <div className="mt-3 d-flex flex-column">
                <Typography color="black" variant="h5">
                    Case team
                </Typography>
            </div>

            {retailerProfiles.map((profile) => {
                return (
                    <View>
                        <View style={styles.avatarWrapper}>
                            <View style={styles.avatarNameWrapper}>
                                {/* TODO: Add JOb Description and last updated date */}
                                <InfoCard
                                    content={`${profile.userName} (${profile.soaUserId})`}
                                    accountService={accountsService}
                                    userName={profile.soaUserId}
                                />
                                <Typography variant="caption">{modifiedOnDescription} Last update</Typography>
                            </View>
                        </View>
                    </View>
                );
            })}

            <div className="mt-5 d-flex">
                <Button
                    onPress={() => {
                        setShow(true);
                    }}
                    iconLeft={<Icon size={16} raised name="plus" raisedContainerStyle={{ width: 22, height: 22 }} />}
                    disabled={disableAddMember}
                >
                    Add member
                </Button>
            </div>
            <Modal show={show} centered={true} onHide={() => setShow(false)} size="lg">
                <Modal.Header placeholder={''}>
                    <Modal.Title>Add member to case</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Label required>Member name</Label>
                    <AsyncSelect
                        styles={{
                            control: (styles, { isFocused }) => ({
                                ...styles,
                                boxShadow: `0 0 0 1px ${colors.blueOne}`,
                                padding: 6,
                            }),
                            menuPortal: (base) => ({ ...base, zIndex: 99999999 }),
                            option: (base, { isFocused }) => ({
                                ...base,
                                lineHeight: '18px',
                                backgroundColor: isFocused ? colors.blueOne : 'white',
                                color: isFocused ? colors.white : colors.black,
                                padding: 20,
                            }),
                            indicatorSeparator: (base) => ({
                                ...base,
                                width: 0,
                            }),
                        }}
                        loadOptions={fetchMembers}
                        placeholder={''}
                        isLoading={isLoading}
                        onChange={(option) => {
                            setSelectedOption(option);
                        }}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <div className="mr-2">
                        <Button
                            variant="ghost-blue"
                            onPress={() => {
                                setShow(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={isLoading}
                        disabled={isLoading || !selectedOption}
                        onPress={() => addMember()}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CaseTeam;

const styles = StyleSheet.create({
    userContainer: {
        minHeight: 82,
    },
    avatarWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
    },
    avatarNameWrapper: {
        marginLeft: 16,
    },
});
