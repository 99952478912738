import { BadgeVariants } from '@packages/ui/shared';
import { formatDate } from '@web/utils/date-format';
import { DATE_FORMAT } from '@web/config/constants';
import { startCase } from 'lodash';

export const getBadgeVariant = (status: string): BadgeVariants => {
    switch (status) {
        // https://subaruofamerica.atlassian.net/browse/TECHSHARE-1769
        case 'Pending':
            return 'yellow';
        case 'NOT_ESCALATED':
            return 'gray';
        case 'ESCALATION_FAILED':
            return 'red';
        case 'TRANSFERRING':
            return 'yellow';
        case 'SENT_TO_TR':
            return 'gray';
        case 'TR_DRAFT':
            return 'gray';
        case 'TR_ISSUED':
            return 'yellow';
        case 'TEMPORARY_REPLY_RECEIVED':
            return 'yellow';
        case 'FINAL_REPLY_RECEIVED':
            return 'green';
        case 'FINAL_REPLY_APPROVED':
            return 'green';
        case 'FINAL_REPLY_REFUSED':
            return 'red';
        default:
            return 'yellow';
    }
};

export const getBadgeVariantTRSharedReply = (status: string): BadgeVariants => {
    switch (status) {
        case 'SHARED':
            return 'green';
        case 'PENDING':
            return 'gray';
        case 'CLOSED':
            return 'green';
        case 'NO_REPLY':
            return 'yellow';
        default:
            return 'yellow';
    }
};

export const caseStatusMap: any = {
    DRAFT: ['DRAFT'],
    OPEN: ['OPEN', 'OPEN-ESCALATED'],
    CLOSED: [
        'CLOSED',
        'CLOSED-RESOLVED',
        'CLOSED-RESOLUTION UNKNOWN',
        'CLOSED-APPROVED',
        'CLOSED-REFERRED',
        'CLOSED-REJECTED',
        'CLOSED-CREATED IN ERROR',
        'CLOSED-DUPLICATE',
    ],
    PENDING_TECHLINE: [
        'PENDING-TECHLINE',
        'PENDING_TECHLINE',
        'PENDING-RESEARCH',
        'PENDING-C/M',
        'PENDING-PART DELAY',
        'DEALER UPDATE',
        'PENDING TECHLINE',
    ],
    UPDATE_REQUESTED: ['UPDATE_REQUESTED', 'PENDING-DEALER UPDATE'],
};

export const getBadgeVariantTechline = (status: string): BadgeVariants => {
    for (const variant in caseStatusMap) {
        if (caseStatusMap[variant].includes(status)) {
            switch (variant) {
                // Add edge case to remove the underscore to stay consistent in the variant styling file
                case 'PENDING_TECHLINE':
                    return 'PENDING';
                case 'UPDATE_REQUESTED':
                    return 'UPDATE';
                default:
                    return variant as BadgeVariants;
            }
        }
    }

    return 'default';
};

// This function is used to display the value with a suffix if it exists, otherwise it will return 'N/A'
export function getDisplayValue(value: any, suffix: string = '', defaultValue: string = 'N/A'): string {
    if (value === undefined || value === null || value === '') {
        return defaultValue;
    }

    return `${value}${suffix}`;
}

export function getDisplayDate(date: Date | undefined | string, dateFormat: string = DATE_FORMAT.MM_DD_YEAR) {
    if (!date) {
        return 'N/A';
    }
    return formatDate(date, dateFormat);
}

/**
 * Formats the time in minutes to a human-readable string.
 * @param minutes - The charge time in minutes (optional).
 * @returns A formatted string, e.g., "1 hour 4 minutes" or "9 minutes".
 */
export function getFormattedTime(minutes?: number): string {
    // Ensure minutes is defined and greater than 0
    if (!minutes || minutes <= 0) return '0 minutes';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
        // Format for hours and minutes
        return `${hours} ${hours === 1 ? 'hour' : 'hours'}${remainingMinutes > 0 ? ` ${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}` : ''}`;
    } else {
        // Format for minutes only
        return `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
    }
}

/**
 * Formats a given text string to title case, converting all characters to lowercase
 * first and then capitalizing the first letter of each word.
 *
 * @param {string} text - The input text to be formatted.
 * @returns {string} The formatted text in title case.
 *
 * @example
 * formatToTitleCase("SYSTEM TEST FUNCTION - System Test");
 * // Returns "System Test Function - System Test"
 */
export const formatToTitleCase = (text?: string): string => {
    return startCase(text?.toLowerCase());
};
