import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Icon, Label } from '@packages/ui/shared';
import { qmrsService } from '@web/services/singletons';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import './select-qmr-primary-objectives.css';

const useStyles = createUseStyles({
    dropDownContainer: {
        position: 'absolute',
        backgroundColor: colors.white,
        zIndex: 1,
        width: '100%',
        boxShadow: '0 7px 7px #ddd5d5',
        padding: '3px',
    },
    dropDownItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        cursor: 'pointer',
        padding: '10px',

        '&:hover': {
            backgroundColor: '#DEE6EF',
        },
    },
});

export const SelectQmrPrimaryObjectives = ({ onSelect, defaultSelected = [] }: any) => {
    const typeaheadRef = useRef<Typeahead<any> | null>(null);
    const classes = useStyles();

    const [qmrCategories, setQmrCategories] = useState([]);

    const handleOnChange = useCallback(
        (selected: any) => {
            const currentQmrCategories: any = qmrCategories.map((category: any) => {
                if (selected.find((d: any) => d.qmrCategoryId === category.qmrCategoryId)?.qmrCategoryId) {
                    return { ...category, isSelected: true };
                }
                return { ...category, isSelected: false };
            });
            setQmrCategories(currentQmrCategories);
        },
        [qmrCategories]
    );

    const fetchQmrCategories = async () => {
        try {
            const qmrCategories: any = await qmrsService.fetchQmrCategories();
            if (qmrCategories?.data?.QmrCategories?.length) {
                setQmrCategories(
                    qmrCategories.data.QmrCategories.map((category: any) => ({
                        ...category,
                        isSelected: !!defaultSelected.find((c: any) => c.qmrCategoryId === category.qmrCategoryId)
                            ?.qmrCategoryId,
                    }))
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchQmrCategories();
    }, []);

    useEffect(() => {
        onSelect(qmrCategories.filter((d: { isSelected: any }) => d.isSelected));
    }, [qmrCategories]);

    const onOptionSelect = (option: any) => {
        const currentQmrCategories: any = qmrCategories.map((category: any) => {
            if (option.qmrCategoryId === category.qmrCategoryId) {
                return { ...category, isSelected: !category.isSelected };
            }
            return category;
        });
        setQmrCategories(currentQmrCategories);
    };

    return (
        <>
            <Label required controlId="newQmr-retailer">
                What is the primary objective of submitting this report?
            </Label>
            <Typeahead
                multiple
                className=""
                id="manage-multi-select-typeahead"
                placeholder={'Select Objective'}
                ref={typeaheadRef}
                labelKey={'description'}
                options={qmrCategories}
                onChange={handleOnChange}
                selected={qmrCategories.filter((category: { isSelected: boolean }) => category.isSelected)}
                renderMenu={() => (
                    <div className={classes.dropDownContainer}>
                        {qmrCategories.map((option: any, index) => {
                            return (
                                <div
                                    data-testid="option"
                                    onClick={() => onOptionSelect(option)}
                                    className={classes.dropDownItem}
                                >
                                    <Icon
                                        color={'blueOne'}
                                        name={option.isSelected ? 'checkbox-marked' : 'checkbox-unmarked'}
                                    />
                                    <span className="ml-2">{option.description}</span>
                                </div>
                            );
                        })}
                    </div>
                )}
            />
        </>
    );
};
