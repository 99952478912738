import React from 'react';

export const OpenInNew = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginBottom: 12 }}
        >
            <g id="Open in New">
                <path
                    id="Vector"
                    d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                    fill="#999DA3"
                />
            </g>
        </svg>
    );
};

export const VinSessionCarIcon = () => {
    return (
        <div>
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Left Icon">
                    <path
                        id="Vector"
                        d="M12 6.05625L9.64583 3.60625L10.8125 2.35937L12 3.60625L14.9583 0.5L16.125 1.725L12 6.05625ZM5.33333 21.5C5.09722 21.5 4.89931 21.4161 4.73958 21.2484C4.57986 21.0807 4.5 20.8729 4.5 20.625V13.625L6.25 8.375C6.33333 8.1125 6.48264 7.90104 6.69792 7.74062C6.91319 7.58021 7.15278 7.5 7.41667 7.5H16.5833C16.8472 7.5 17.0868 7.58021 17.3021 7.74062C17.5174 7.90104 17.6667 8.1125 17.75 8.375L19.5 13.625V20.625C19.5 20.8729 19.4201 21.0807 19.2604 21.2484C19.1007 21.4161 18.9028 21.5 18.6667 21.5H17.8333C17.5972 21.5 17.3993 21.4161 17.2396 21.2484C17.0799 21.0807 17 20.8729 17 20.625V19.75H7V20.625C7 20.8729 6.92014 21.0807 6.76042 21.2484C6.60069 21.4161 6.40278 21.5 6.16667 21.5H5.33333ZM6.83333 11.875H17.1667L16.2917 9.25H7.70833L6.83333 11.875ZM8.25 17.125C8.59722 17.125 8.89236 16.9974 9.13542 16.7422C9.37847 16.487 9.5 16.1771 9.5 15.8125C9.5 15.4479 9.37847 15.138 9.13542 14.8828C8.89236 14.6276 8.59722 14.5 8.25 14.5C7.90278 14.5 7.60764 14.6276 7.36458 14.8828C7.12153 15.138 7 15.4479 7 15.8125C7 16.1771 7.12153 16.487 7.36458 16.7422C7.60764 16.9974 7.90278 17.125 8.25 17.125ZM15.75 17.125C16.0972 17.125 16.3924 16.9974 16.6354 16.7422C16.8785 16.487 17 16.1771 17 15.8125C17 15.4479 16.8785 15.138 16.6354 14.8828C16.3924 14.6276 16.0972 14.5 15.75 14.5C15.4028 14.5 15.1076 14.6276 14.8646 14.8828C14.6215 15.138 14.5 15.4479 14.5 15.8125C14.5 16.1771 14.6215 16.487 14.8646 16.7422C15.1076 16.9974 15.4028 17.125 15.75 17.125Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    );
};

export const TextIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 28V15H13V12H27V15H21.5V28H18.5Z" fill="#1F518F" />
        </svg>
    );
};

export const Save = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29 15V29H11V11H25L29 15ZM20 26C20.8333 26 21.5417 25.7083 22.125 25.125C22.7083 24.5417 23 23.8333 23 23C23 22.1667 22.7083 21.4583 22.125 20.875C21.5417 20.2917 20.8333 20 20 20C19.1667 20 18.4583 20.2917 17.875 20.875C17.2917 21.4583 17 22.1667 17 23C17 23.8333 17.2917 24.5417 17.875 25.125C18.4583 25.7083 19.1667 26 20 26ZM14 18H23V14H14V18Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Share = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26 30C25.1667 30 24.4583 29.7083 23.875 29.125C23.2917 28.5417 23 27.8333 23 27C23 26.8833 23.0083 26.7623 23.025 26.637C23.0417 26.5123 23.0667 26.4 23.1 26.3L16.05 22.2C15.7667 22.45 15.45 22.6457 15.1 22.787C14.75 22.929 14.3833 23 14 23C13.1667 23 12.4583 22.7083 11.875 22.125C11.2917 21.5417 11 20.8333 11 20C11 19.1667 11.2917 18.4583 11.875 17.875C12.4583 17.2917 13.1667 17 14 17C14.3833 17 14.75 17.0707 15.1 17.212C15.45 17.354 15.7667 17.55 16.05 17.8L23.1 13.7C23.0667 13.6 23.0417 13.4877 23.025 13.363C23.0083 13.2377 23 13.1167 23 13C23 12.1667 23.2917 11.4583 23.875 10.875C24.4583 10.2917 25.1667 10 26 10C26.8333 10 27.5417 10.2917 28.125 10.875C28.7083 11.4583 29 12.1667 29 13C29 13.8333 28.7083 14.5417 28.125 15.125C27.5417 15.7083 26.8333 16 26 16C25.6167 16 25.25 15.929 24.9 15.787C24.55 15.6457 24.2333 15.45 23.95 15.2L16.9 19.3C16.9333 19.4 16.9583 19.5123 16.975 19.637C16.9917 19.7623 17 19.8833 17 20C17 20.1167 16.9917 20.2373 16.975 20.362C16.9583 20.4873 16.9333 20.6 16.9 20.7L23.95 24.8C24.2333 24.55 24.55 24.354 24.9 24.212C25.25 24.0707 25.6167 24 26 24C26.8333 24 27.5417 24.2917 28.125 24.875C28.7083 25.4583 29 26.1667 29 27C29 27.8333 28.7083 28.5417 28.125 29.125C27.5417 29.7083 26.8333 30 26 30Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Recent = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.8 24.2L19 20.4V15H21V19.6L24.2 22.8L22.8 24.2ZM20 29C17.7 29 15.696 28.2373 13.988 26.712C12.2793 25.1873 11.3 23.2833 11.05 21H13.1C13.3333 22.7333 14.104 24.1667 15.412 25.3C16.7207 26.4333 18.25 27 20 27C21.95 27 23.604 26.3207 24.962 24.962C26.3207 23.604 27 21.95 27 20C27 18.05 26.3207 16.3957 24.962 15.037C23.604 13.679 21.95 13 20 13C18.85 13 17.775 13.2667 16.775 13.8C15.775 14.3333 14.9333 15.0667 14.25 16H17V18H11V12H13V14.35C13.85 13.2833 14.8877 12.4583 16.113 11.875C17.3377 11.2917 18.6333 11 20 11C21.25 11 22.421 11.2373 23.513 11.712C24.6043 12.1873 25.5543 12.829 26.363 13.637C27.171 14.4457 27.8127 15.3957 28.288 16.487C28.7627 17.579 29 18.75 29 20C29 21.25 28.7627 22.4207 28.288 23.512C27.8127 24.604 27.171 25.554 26.363 26.362C25.5543 27.1707 24.6043 27.8127 23.513 28.288C22.421 28.7627 21.25 29 20 29Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Favourite = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.825 29L15.45 21.975L10 17.25L17.2 16.625L20 10L22.8 16.625L30 17.25L24.55 21.975L26.175 29L20 25.275L13.825 29Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const NoFoundError = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3334 73.3333C18.7223 73.3333 14.7917 71.7083 11.5417 68.4583C8.29175 65.2083 6.66675 61.2778 6.66675 56.6667C6.66675 52.0556 8.29175 48.125 11.5417 44.875C14.7917 41.625 18.7223 40 23.3334 40C27.9445 40 31.8751 41.625 35.1251 44.875C38.3751 48.125 40.0001 52.0556 40.0001 56.6667C40.0001 61.2778 38.3751 65.2083 35.1251 68.4583C31.8751 71.7083 27.9445 73.3333 23.3334 73.3333ZM68.6668 70L47.3334 48.6667C46.6668 47.9444 45.9584 47.2083 45.2084 46.4583C44.4584 45.7083 43.7223 45 43.0001 44.3333C45.1112 43 46.8056 41.2222 48.0834 39C49.3612 36.7778 50.0001 34.3333 50.0001 31.6667C50.0001 27.5 48.5418 23.9583 45.6251 21.0417C42.7084 18.125 39.1668 16.6667 35.0001 16.6667C30.8334 16.6667 27.2918 18.125 24.3751 21.0417C21.4584 23.9583 20.0001 27.5 20.0001 31.6667C20.0001 32 20.014 32.3194 20.0417 32.625C20.0695 32.9306 20.1112 33.25 20.1667 33.5833C19.1667 33.6944 18.0695 33.9167 16.8751 34.25C15.6806 34.5833 14.6112 34.9722 13.6667 35.4167C13.5556 34.8056 13.4723 34.1944 13.4167 33.5833C13.3612 32.9722 13.3334 32.3333 13.3334 31.6667C13.3334 25.6111 15.4306 20.4861 19.6251 16.2917C23.8195 12.0972 28.9445 10 35.0001 10C41.0556 10 46.1806 12.0972 50.3751 16.2917C54.5695 20.4861 56.6668 25.6111 56.6668 31.6667C56.6668 34.0556 56.2918 36.3194 55.5418 38.4583C54.7918 40.5972 53.7501 42.5556 52.4168 44.3333L73.3334 65.3333L68.6668 70ZM17.4167 64.9167L23.3334 59L29.1668 64.9167L31.5834 62.5833L25.6668 56.6667L31.5834 50.75L29.2501 48.4167L23.3334 54.3333L17.4167 48.4167L15.0834 50.75L21.0001 56.6667L15.0834 62.5833L17.4167 64.9167Z"
                fill="#999DA3"
            />
        </svg>
    );
};
