import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography, Icon, StsIconName } from '@packages/ui/shared';
import { useVinSessionDispatch, useVinSessionState } from '../context/vin-sessions.hooks';
import { vinSessionActions } from '../context/vin-sessions.state';
import { handleSelectedFilters } from '../utils/vinSessionUtils';

type VinFilterButtonProps = {
    index?: number;
    filter: string;
    textColor?: string;
    iconName?: StsIconName;
    style?: any;
    searchFilter?: boolean;
};

const useStyles = createUseStyles({
    filter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 4px 2px 4px',
        borderRadius: '4px',
        border: '1px solid #DEE6EF',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 16,
        marginBottom: 1,
        marginLeft: 2,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
});

const VinFilterButton: FC<VinFilterButtonProps> = (props) => {
    const classes = useStyles();
    const { selectedFilters } = useVinSessionState();
    const dispatch = useVinSessionDispatch();
    const { index, filter, textColor, iconName, style, searchFilter } = props;

    return (
        <div
            key={index}
            className={classes.filter}
            style={{
                cursor: 'pointer',
                backgroundColor:
                    selectedFilters.includes(filter) || filter.includes('+') ? colors.blueFour : colors.white,
            }}
            onClick={() => {
                if (!filter.includes('+') && !searchFilter) {
                    handleSelectedFilters(filter, selectedFilters, vinSessionActions, dispatch);
                }
            }}
        >
            <Typography variant="caption5" style={style}>
                {filter}
            </Typography>
            {!searchFilter && iconName && (
                <div className={classes.icon}>
                    {selectedFilters.includes(filter) && <Icon name={iconName} color="blueOne" status={true} />}
                </div>
            )}
        </div>
    );
};

export default VinFilterButton;
