import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WorksheetFormElement } from '@web/administration/views/worksheets/components/worksheet-form-element';
import { prepareFormElementsWithOrder } from '@web/administration/views/worksheets/utils/worksheet-form';
import { WorksheetModal } from '@web/administration/views/worksheets/components/worksheet-modal';
import { SelectPopover } from '@web/administration/views/worksheets/components/selectPopover';
import { Button, Divider, Icon, Typography } from '@packages/ui/shared';
import { TechlineStatusCode, WorksheetAnswerStatus, WorksheetElementAnswers } from '@packages/models/api';
import AsyncPage from '@web/components/async-page';
import colors from '@packages/core/styles/colors';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WorksheetToaster } from './toaster';
import { createUseStyles } from 'react-jss';
import Loader from '@web/components/loader';
import { DropResult } from 'react-beautiful-dnd';
import { useWorksheetSubmission } from './hooks/useWorksheetSubmission';
import useWorksheetSubmissionData from './hooks/useWorksheetSubmissionData';
import useUserDetailsForWorksheet from './hooks/useUserDetailsOrPermissions';
import useWorksheetAnswerValidation from './hooks/useWorksheetAnswerValidation';
import { cloneDeep } from 'lodash';
import { elementQuestionTypeMapping } from './utils/util';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { analyticsService, worksheetsService } from '@web/services/singletons';
import { DeleteMessage } from './worksheet-add';
interface Props {
    worksheetId?: string;
}
interface Show {
    actionMenu: boolean;
    submitModal: boolean;
    draftModal: boolean;
    toaster: boolean;
}
export const WorksheetAnswer: FC<Props> = () => {
    const classes = useNewWorksheetHeaderStyles();
    const [show, setShow] = useState<Show>({
        actionMenu: false,
        submitModal: false,
        draftModal: false,
        toaster: false,
    });
    const [formElementsAnswers, setFormElementsAnswers] = useState<Record<string, WorksheetElementAnswers>>({});
    const [toasterMessage, setToasterMessage] = useState<string>('');
    const [invalidFieldCount, setInvalidFieldCount] = useState<number>(0);
    const [showWorksheetValidationModal, setShowWorksheetValidationModal] = useState(false);

    const [showDeleteModal, setDeleteModal] = useState<{ show: boolean; submissionId: string }>({
        show: false,
        submissionId: '',
    });
    const [isDeletedLoading, setIsDeletedLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const worksheetId = searchParams.get('worksheetId') || '';
    const caseNumber = searchParams.get('casenumber') || '';
    const submissionId = searchParams.get('submissionId') || '';
    const caseStatus = searchParams.get('caseStatus') as TechlineStatusCode;

    //permission for worksheet answer submission api
    const {
        permissions: { canAddAndEditWorksheet },
    } = useUserDetailsForWorksheet();
    const { checkElementAnswer, isValidWorksheetAnswer } = useWorksheetAnswerValidation();
    //get worksheet data
    const { worksheetData, actions, fetching } = useWorksheetSubmissionData({
        submissionId,
        worksheetId,
        caseNumber,
    });

    //worksheet dto to FormElement
    const elements = useMemo(() => {
        const el = prepareFormElementsWithOrder(worksheetData?.elements || []);
        console.log('check  preper ==.,', el);
        return el;
    }, [worksheetData]);

    const isSubmitted = useMemo(() => {
        return worksheetData?.worksheetSubmissionStatus === WorksheetAnswerStatus.Submitted;
    }, [worksheetData?.worksheetSubmissionStatus]);

    // Whole techline's case status.
    const caseStatusTypes = useMemo(() => {
        return {
            isCloseCase: [TechlineStatusCode.CLOSED, TechlineStatusCode.CLOSED_RESOLVED].includes(caseStatus),
            isSubmittedCase: ![
                TechlineStatusCode.DRAFT,
                TechlineStatusCode.CLOSED,
                TechlineStatusCode.CLOSED_RESOLVED,
            ].includes(caseStatus),
            isDraftCase: caseStatus === TechlineStatusCode.DRAFT,
        };
    }, [caseStatus]);

    useEffect(() => {
        // Initial get the all elements ans dto and set in formElementAnswer
        let elementsSelectedOptions: { [x: string]: WorksheetElementAnswers } = actions.prepareElementAnswer(
            worksheetData?.elements || []
        );
        // TODO: This state should be updated when asset status changed
        setFormElementsAnswers(elementsSelectedOptions);
    }, [worksheetData]);

    // Initial count of which element has not answer or required.
    useEffect(() => {
        const { conditionalOptionFormElementTypes } = elementQuestionTypeMapping;
        if (worksheetData) {
            const filterElementIds = worksheetData.elements.reduce((_array: string[], element) => {
                // Array of conditional element ids
                if (conditionalOptionFormElementTypes.includes(element.worksheetElementTypeId)) {
                    element.options.forEach((opt) => {
                        const trgIdList = opt.trgtWorksheetElementIdList;
                        if (trgIdList?.length) {
                            trgIdList.forEach((id) => {
                                _array.push(id);
                            });
                        }
                    });
                }
                return _array;
            }, []);

            const countRequiredFields = worksheetData.elements.reduce((count, element) => {
                if (!filterElementIds.includes(element.worksheetElementId)) {
                    const isValid = checkElementAnswer(element);
                    if (!isValid) {
                        return ++count;
                    }
                }
                return count;
            }, 0);
            setInvalidFieldCount(countRequiredFields);
        }
    }, [worksheetData]);

    // Hook for submit or as draft
    const { onSubmit, isLoading, setIsLoading } = useWorksheetSubmission({ worksheetId, caseNumber, submissionId });

    //Update Form element answer.
    const onAnswerChange = useCallback((newAnswer: WorksheetElementAnswers) => {
        setFormElementsAnswers((prev) => ({ ...prev, [newAnswer.elementId]: newAnswer }));
    }, []);

    const handleSubmit = useCallback(async () => {
        const response = await onSubmit({
            formElementAnswer: formElementsAnswers,
            status: WorksheetAnswerStatus.Submitted,
            caseStatus,
        });
        if (!response.success) {
            setToasterMessage(response.data.message);
            handleModalAndToasterShow('toaster', true);
        } else {
            setToasterMessage('Your worksheet was successfully submitted');
            handleModalAndToasterShow('toaster', true);
            setTimeout(() => {
                navigate(-1);
            }, 3000);
        }
        handleModalAndToasterShow('submitModal', false);
    }, [formElementsAnswers, navigate, onSubmit]);

    const saveAsDraft = useCallback(async () => {
        const response = await onSubmit({
            formElementAnswer: formElementsAnswers,
            status: WorksheetAnswerStatus.Draft,
            caseStatus,
        });
        if (!response.success) {
            console.log(response.data.message);
            handleModalAndToasterShow('draftModal', false);
        } else {
            setTimeout(() => {
                setIsLoading((prev) => ({ ...prev, [WorksheetAnswerStatus.Draft]: false }));
                handleModalAndToasterShow('draftModal', false);
                navigate(-1);
            }, 3000);
        }
    }, [formElementsAnswers, navigate, onSubmit, setIsLoading]);

    const handleModalAndToasterShow = useCallback(
        (key: keyof Show, action: boolean) => {
            setShow((prev) => ({ ...prev, [key]: action }));
        },
        [setShow]
    );

    const handleIsValid = (valid: boolean) => {
        // When field is valid decrease one count in invalidCount or not a valid than increase one in inValidFiledCount
        setInvalidFieldCount((prev) => {
            let prevClone = prev;
            if (valid) {
                prevClone--;
            } else {
                prevClone++;
            }
            return prevClone;
        });
    };

    const checkValidation = useCallback(async () => {
        if (Object.keys(formElementsAnswers).length && worksheetData) {
            const worksheetDataClone = cloneDeep(worksheetData);
            worksheetDataClone?.elements.forEach((ele) => {
                const ans = formElementsAnswers[ele.worksheetElementId];
                if (ans) {
                    ele.selectedOptions = ans.selectedOptions;
                }
            });
            return await isValidWorksheetAnswer({ worksheetData: worksheetDataClone });
        }
    }, [formElementsAnswers, worksheetData, isValidWorksheetAnswer]);

    const handleSaveAsDraft = useCallback(async () => {
        const isValid = await checkValidation();
        if (!isValid) {
            setShowWorksheetValidationModal(true);
        } else {
            saveAsDraft();
        }
    }, [checkValidation, saveAsDraft]);

    const handleDraftModalAction = useCallback(
        async (actions: boolean) => {
            if (actions) {
                await saveAsDraft();
            } else {
                setShow((prev) => ({ ...prev, draftModal: false }));
            }
        },
        [handleSaveAsDraft]
    );

    const handleModalAction = useCallback(
        async (action: boolean) => {
            if (action) {
                await handleSubmit();
            }
            handleModalAndToasterShow('submitModal', false);
        },
        [handleSubmit]
    );

    const getCurrentAnswerByElementId = useCallback(
        (id: string) => {
            return formElementsAnswers[id];
        },
        [formElementsAnswers]
    );

    const deleteWorksheetDraft = useCallback(async () => {
        const response = await worksheetsService.deleteDraftWorksheetSubmission(showDeleteModal.submissionId);
        if (response.data.message) {
            setToasterMessage(response.data.message);
            handleModalAndToasterShow('toaster', true);
            navigate(-1);
        }
        setIsDeletedLoading(false);
    }, [handleModalAndToasterShow, navigate, showDeleteModal.submissionId]);

    const handleDeleteModalAction = useCallback(
        async (action: boolean) => {
            if (action) {
                setIsDeletedLoading(true);
                await deleteWorksheetDraft();
            }
            setDeleteModal({ show: false, submissionId: '' });
        },
        [deleteWorksheetDraft]
    );

    useEffect(() => {
        // Cleanup function to stop recursion on component unload
        return () => {
            actions.clearPullingAssetsTimeout();
        };
    }, []);

    return (
        <>
            <AsyncPage fetchData={submissionId ? actions.fetchRetrieveWorksheetSubmission : actions.fetchWorksheetData}>
                {fetching ? (
                    <Loader />
                ) : (
                    <>
                        <WorksheetModal
                            show={showWorksheetValidationModal}
                            onHideModal={() => setShowWorksheetValidationModal(false)}
                            title="Worksheet Validation"
                            bodyMessage={
                                'Some of the required fields in your worksheets are empty. Please review your worksheets and make sure all of the required fields are complete.'
                            }
                            cancelButton="Cancel"
                            confirmButton="Ok"
                            confirmButtonColor="info"
                            handleAction={(action) => {
                                if (action) {
                                    handleModalAndToasterShow('draftModal', true);
                                }
                                setShowWorksheetValidationModal(false);
                            }}
                        />
                        <WorksheetToaster
                            show={show.toaster}
                            onClose={() => handleModalAndToasterShow('toaster', false)}
                            body={toasterMessage}
                        />
                        <WorksheetModal
                            show={showDeleteModal.show}
                            onHideModal={() => setDeleteModal((prev) => ({ show: false, submissionId: '' }))}
                            title="Delete worksheet"
                            bodyMessage={DeleteMessage.deleteSubmitted}
                            cancelButton="Cancel"
                            confirmButton="Delete"
                            confirmButtonColor="danger"
                            confirmButtonLoading={isDeletedLoading}
                            handleAction={handleDeleteModalAction}
                        />
                        <WorksheetModal
                            show={show.submitModal}
                            onHideModal={() => handleModalAndToasterShow('submitModal', false)}
                            title="Submit worksheet"
                            bodyMessage={
                                'By submitting this worksheet, it will become visible to Techline users and can no longer be edited. Are you sure you want continue?'
                            }
                            cancelButton="Cancel"
                            confirmButton="Submit"
                            confirmButtonLoading={isLoading.SUBMITTED}
                            handleAction={handleModalAction}
                        />
                        <WorksheetModal
                            show={show.draftModal}
                            onHideModal={() => handleModalAndToasterShow('draftModal', false)}
                            title="Save worksheet"
                            bodyMessage={
                                caseStatusTypes.isSubmittedCase
                                    ? 'This worksheet will not be seen by Techline until submitted.'
                                    : 'Worksheet will be submitted on case submission.'
                            }
                            cancelButton="Cancel"
                            confirmButton="Save"
                            confirmButtonLoading={isLoading.DRAFT}
                            handleAction={handleDraftModalAction}
                        />
                        <div className={classes.view} style={{ backgroundColor: colors.grayTwo }}>
                            <div
                                style={{ backgroundColor: colors.white }}
                                className={`d-flex flex-row align-items-center justify-content-between p-2`}
                            >
                                <div className="ml-5 d-flex flex-row align-items-center">
                                    <Button variant="link" onPress={() => navigate(`/techline/${caseNumber}`)}>
                                        <Icon name="arrow-back" />
                                    </Button>
                                    <div className="ml-4 mr-4">
                                        <Typography variant="h5">{caseNumber}</Typography>
                                    </div>
                                    <Icon name="chevron-right" />
                                    <div className="ml-4">
                                        <Typography color="black" variant="h4">
                                            {worksheetData?.displayname}
                                        </Typography>
                                    </div>
                                </div>
                                {!isSubmitted && (
                                    <div className="mr-5">
                                        <SelectPopover
                                            isOpen={show.actionMenu}
                                            setShow={(isShow) => handleModalAndToasterShow('actionMenu', isShow)}
                                            options={
                                                <Button
                                                    variant="ghost-gray"
                                                    onPress={() => {
                                                        analyticsService.logEvent(
                                                            ANALYTICS_EVENTS.USER_DELETES_WORKSHEET_DRAFT
                                                        );
                                                        setDeleteModal({
                                                            show: true,
                                                            submissionId,
                                                        });
                                                        handleModalAndToasterShow('actionMenu', false);
                                                    }}
                                                    alignTitle="left"
                                                    title={'Delete'}
                                                    iconLeft={<Icon name="trash" />}
                                                />
                                            }
                                        >
                                            <div>
                                                <Button
                                                    variant="ghost-gray"
                                                    onPress={() =>
                                                        handleModalAndToasterShow('actionMenu', !show.actionMenu)
                                                    }
                                                >
                                                    <Icon
                                                        size={30}
                                                        name="more-dots-vertical"
                                                        color="blueOne"
                                                        raisedContainerStyle={{ width: 22, height: 22 }}
                                                    />
                                                </Button>
                                            </div>
                                        </SelectPopover>
                                    </div>
                                )}
                            </div>

                            <div className="w-50 p-5 mb-5 mt-5 m-auto" style={{ backgroundColor: colors.white }}>
                                {elements.map((element) => {
                                    return (
                                        <WorksheetFormElement
                                            onAnswerChange={(newAnswer: WorksheetElementAnswers) =>
                                                onAnswerChange(newAnswer)
                                            }
                                            key={element.formElementId}
                                            item={element}
                                            handleDragEnd={(result: DropResult) => {}}
                                            readOnly={
                                                isSubmitted || caseStatusTypes.isCloseCase || !canAddAndEditWorksheet
                                                    ? true
                                                    : false
                                            }
                                            getCurrentAnswerByElementId={getCurrentAnswerByElementId}
                                            preview
                                            handleIsValid={handleIsValid}
                                        />
                                    );
                                })}
                                <Divider />
                                {!isSubmitted && canAddAndEditWorksheet && !caseStatusTypes.isCloseCase && (
                                    <div className="d-flex justify-content-end align-items-center mt-5 p-5">
                                        <Button
                                            variant={caseStatusTypes.isDraftCase ? 'primary' : 'ghost-gray'}
                                            onPress={handleSaveAsDraft}
                                            style={{ marginRight: 5 }}
                                            disabled={isLoading.DRAFT}
                                            isLoading={isLoading.DRAFT}
                                        >
                                            {caseStatusTypes.isDraftCase ? 'Save' : 'Save as draft'}
                                        </Button>
                                        {caseStatusTypes.isSubmittedCase && (
                                            <Button
                                                variant="primary"
                                                disabled={!!invalidFieldCount || isLoading.SUBMITTED}
                                                onPress={() => handleModalAndToasterShow('submitModal', true)}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </AsyncPage>
        </>
    );
};

const useNewWorksheetHeaderStyles = createUseStyles({
    view: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
    },
    header: {
        flexShrink: 0,
        display: 'flex',
        padding: '0 30px',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: colors.white,
        height: 80,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
});
