import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    skeleton: {
        backgroundColor: '#e3e3e3',
        borderRadius: '4px',
        animation: '$pulse 1.5s infinite ease-in-out',
    },
    skeletonText: {
        height: '12px',
        display: 'block',
        marginBottom: '8px',
        backgroundColor: '#e0e0e0',
        borderRadius: '4px',
        width: '100%',
    },
    skeletonBtn: {
        height: '38px',
        width: '100px',
    },
    '@keyframes pulse': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.4 },
        '100%': { opacity: 1 },
    },
});

export const SkeletonLoader: FC<{ type: 'text' | 'btn'; className?: string }> = ({ type, className }) => {
    const classes = useStyles();
    return (
        <div
            className={`${classes.skeleton} ${
                type === 'text' ? classes.skeletonText : classes.skeletonBtn
            } ${className || ''}`}
            aria-hidden="true"
            data-testid={type === 'text' ? 'skeleton-text' : 'skeleton-btn'}
        />
    );
};
