import React, { forwardRef, Fragment, PropsWithChildren, useMemo } from 'react';
import { StyleSheet, Text, TextProps, Platform, Linking } from 'react-native';
import colors from '@packages/core/styles/colors';

interface TypographyProps extends TextProps {
    /**
     * @default: default
     * @example
     * variant: size/lineHeight - weight | family
     * h1: 35/48 - 400 | Regular
     * h2: 27/36 - 400 | Regular
     * h3: 21/26 - 500 | Medium
     * h4: 19/24 - 700 | Bold
     * h5: 17/20 - 500 | Medium
     * h6: 15/18 - 700 | Bold
     * lead: 17/24 - 400 | Regular
     * default: 15/18 - 400 | Regular
     * caption: 13/16 - 400 | Regular
     * caption2: 11/16 - 500 | Medium
     * caption3: 15/24 - 600 | Bold
     * label: 15/20 - 500 | Medium
     * labelRegular: 15/20 - 400 | Regular
     */
    variant?:
        | 'caption'
        | 'caption2'
        | 'caption3'
        | 'caption4'
        | 'default'
        | 'dropdown'
        | 'error'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'label'
        | 'labelRegular'
        | 'lead'
        | 'statusBadge'
        | 'caption'
        | 'caption2'
        | 'caption3'
        | 'caption4'
        | 'caption5'
        | 'error'
        | 'vin'
        | 'vinError'
        | 'statusBadge';
    color?: keyof typeof colors;
    href?: string;
    hbs?: boolean; // for Handlebars templates containing JSX
    hbsNested?: boolean; // for typography within typography...
    ariaLevel?: number;
}

export const Typography = forwardRef<Text, PropsWithChildren<TypographyProps>>(
    ({ children, variant = 'default', color = 'textDarkPrimary', style, hbs, hbsNested, ...props }, ref) => {
        const str = useMemo(() => {
            function trimText(child: any) {
                let trimmed: any = '';

                // The Dynamic JSX parser wraps text with react fragments by default
                // https://github.com/TroyAlford/react-jsx-parser/blob/develop/source/components/JsxParser.tsx#L102
                // It also includes all whitespace that's part of the `hbs` template and between `jsx` tags

                if (child && child.type && child.type === Fragment) {
                    trimmed = child.props.children.trim();
                } else if (child && child.type && child.type === Typography) {
                    trimmed = React.cloneElement(child, { ...child.props, hbsNested: true });
                } else {
                    trimmed = child.trim();
                }

                if (hbsNested) {
                    return [' ', trimmed, ' '];
                }

                return trimmed;
            }

            if (hbs && Array.isArray(children)) {
                return children.map(trimText);
            } else if (hbs) {
                return trimText(children);
            }

            return children;
        }, [children, hbs, hbsNested]);

        function getAriaAttrs() {
            if (!variant.startsWith('h') || props.accessibilityRole || props.ariaLevel) {
                return {};
            }

            const ariaLevel = parseInt(variant, 10);

            return {
                accessibilityRole: 'header' as any,
                ariaLevel,
            };
        }

        return (
            <Text
                ref={ref}
                style={[styles[variant], { color: colors[color] }, style]}
                {...props}
                {...getAriaAttrs()}
                onPress={
                    props.href || props.onPress
                        ? (e) => {
                              const url = props.href || '';
                              if (props.href && Platform.OS !== 'web') {
                                  Linking.canOpenURL(url).then((supported) => {
                                      supported && Linking.openURL(url);
                                  });
                                  // Linking.canOpenURL(props.href) && Linking.openURL(props.href);
                              } else if (props.onPress) {
                                  props.onPress(e);
                              }
                          }
                        : undefined
                }
            >
                {str}
            </Text>
        );
    }
);

export const RobotoFontFamily = Platform.select({
    web: {
        Regular: 'Roboto',
        Medium: 'Roboto',
        Bold: 'Roboto',
    },
    default: {
        Regular: 'Roboto-Regular',
        Medium: 'Roboto-Medium',
        Bold: 'Roboto-Bold',
    },
});

const styles = StyleSheet.create({
    h1: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 35,
        lineHeight: 48,
    },
    h2: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 27,
        lineHeight: 36,
    },
    h3: {
        fontFamily: RobotoFontFamily.Medium,
        fontWeight: '500',
        fontSize: 21,
        lineHeight: 26,
    },
    h4: {
        fontFamily: RobotoFontFamily.Bold,
        fontWeight: '700',
        fontSize: 19,
        lineHeight: 24,
    },
    h5: {
        fontFamily: RobotoFontFamily.Medium,
        fontWeight: '500',
        fontSize: 17,
        lineHeight: 20,
    },
    h6: {
        fontFamily: RobotoFontFamily.Bold,
        fontWeight: '700',
        fontSize: 15,
        lineHeight: 18,
    },
    lead: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 17,
        lineHeight: 24,
    },
    default: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 18,
    },
    caption: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 13,
        lineHeight: 16,
    },
    caption2: {
        fontFamily: RobotoFontFamily.Medium,
        fontWeight: '500',
        fontSize: 11,
        lineHeight: 16,
        textTransform: 'uppercase',
    },
    caption3: {
        fontFamily: RobotoFontFamily.Medium,
        fontWeight: '600',
        fontSize: 15,
        lineHeight: 24,
        //textTransform: 'uppercase',
    },
    caption4: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '600',
        fontSize: 15,
        lineHeight: 24,
    },
    caption5: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '600',
        fontSize: 14,
        lineHeight: 18,
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
    },
    dropdown: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 14,
        lineHeight: 1,
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
    },
    label: {
        fontFamily: RobotoFontFamily.Medium,
        fontWeight: '500',
        fontSize: 15,
        lineHeight: 20,
    },
    labelRegular: {
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 20,
    },
    error: {
        color: colors.redOne,
        fontSize: 12,
        fontWeight: '600',
        lineHeight: 10,
    },
    vin: {
        alignItems: 'center',
        display: 'flex',
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        textTransform: 'captalize',
    },
    vinError: {
        color: '#DE2622',
        fontFamily: RobotoFontFamily.Regular,
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
    statusBadge: {
        color: '#071424',
        fontFamily: RobotoFontFamily.Regular,
        fontSize: 13,
        fontWeight: '600',
    },
});
