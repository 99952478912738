import React, { createContext, FC, useReducer } from 'react';
import { bmisActions, BmisActionDispatcher, bmisReducer, BmisState, initialState } from './bmis.state';
import { FetchBmisOptions, type BmisService } from './bmis.service';

export const BmisStateContext = createContext<BmisState | undefined>(undefined);
export const BmisDispatchContext = createContext<BmisActionDispatcher | undefined>(undefined);

export const BmisProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(bmisReducer, initialState);

    return (
        <BmisStateContext.Provider value={state}>
            <BmisDispatchContext.Provider value={dispatch}>{children}</BmisDispatchContext.Provider>
        </BmisStateContext.Provider>
    );
};

export async function setVin(dispatch: BmisActionDispatcher, bmisService: BmisService, options: FetchBmisOptions = {}) {
    const { vin } = options;

    dispatch(bmisActions.setVin({ vin }));

    if (!vin) {
        dispatch(bmisActions.clearBmisData());
        return;
    }

    dispatch(bmisActions.isLoading());

    try {
        const response = await bmisService.fetchBmis({
            ...(options as FetchBmisOptions),
            ignoreCache: false,
        });
        dispatch(bmisActions.setBmisData({ bmisData: response }));
        dispatch(bmisActions.hasLoaded());
        dispatch(bmisActions.selectBmisRow());
    } catch (e) {
        const err = e as Error;
        dispatch(bmisActions.hasErrors({ errors: [err.message] }));
        dispatch(bmisActions.hasLoaded());
        dispatch(bmisActions.clearBmisData());
        console.error('Error Fetching BMIS Data', e);
    }
}

export function setRowGuid(dispatch: BmisActionDispatcher, rowGuid: string | null) {
    dispatch(bmisActions.setRowGuid({ rowGuid }));
    dispatch(bmisActions.selectBmisRow());
}
