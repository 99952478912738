import React, { FC, RefObject } from 'react';
import { Table } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import { VIN } from '../types';

type VinSessionDropdownProps = {
    vinData: VIN | undefined;
    dropDownRef: RefObject<HTMLDivElement>;
};

const useStyles = createUseStyles({
    tableContainer: {
        position: 'fixed',
        top: 154,
        maxHeight: '67%',
        width: '100%',
        backgroundColor: colors.white,
        zIndex: 10,
        padding: 16,
        boxShadow: '0px 2px 8px 0px #00000029',
    },
    row1: {
        width: '50%',
        backgroundColor: '#F8F8F8',
        border: 'solid 1px #E3E3E3',
        padding: '10px !important',
    },
    row2: {
        width: '50%',
        backgroundColor: '#FFFFFF',
        border: 'solid 1px #E3E3E3',
        padding: '10px !important',
    },
    heading: {
        height: 40,
        border: 'solid 1px #E3E3E3',
        backgroundColor: '#DEE6EF',
        padding: '10px !important',
    },
    link: {
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '24px',
        marginRight: 16,
    },
});

const VinSessionDropdown: FC<VinSessionDropdownProps> = (props) => {
    const classes = useStyles();
    const { vinData, dropDownRef } = props;

    return (
        <div className={classes.tableContainer} ref={dropDownRef}>
            <Table>
                <tbody>
                    <tr>
                        <td className={classes.row1}>
                            <Typography variant="caption4">Carline: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.model}
                            </Typography>
                        </td>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Year: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.modelYear}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Engine: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {`${vinData?.vinRecord.engineSize} L${vinData?.vinRecord.turboIndicator == 'true' ? ` Turbo` : ''} ${vinData?.vinRecord.engine}Cylinders ${vinData?.vinRecord.fuel}`}
                            </Typography>
                        </td>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Engine n°: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.engineId}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.row1}>
                            <Typography variant="caption4">Trim: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.trimDescription}
                            </Typography>
                        </td>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Model: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.modelNumber}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Transmission Type: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.driveTrain} {vinData?.vinRecord.transmissionType}
                            </Typography>
                        </td>
                        <td className={classes.row1}>
                            <Typography variant="caption4">Transmission n°: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.vehicleTransmissionNumber}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.row1}>
                            <Typography variant="caption4">Fuel Type: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.fuel}
                            </Typography>
                        </td>
                        <td className={classes.row2}>
                            <Typography variant="caption4">Exterior Color: </Typography>
                            <Typography variant="caption4" style={{ fontWeight: '400', textTransform: 'capitalize' }}>
                                {vinData?.vinRecord.colorDescription}
                            </Typography>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default VinSessionDropdown;
