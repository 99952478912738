import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    TechlineHeader,
    TechlineSections,
    TechlineSideNav,
    VehicleInfo,
    CaseDetailsInput,
    DtcsInputs,
    FailCode,
    Attachments,
} from '../components';
import { useAuthState } from '@packages/contexts/auth';
import { AlertBar, Button, TextInputHelper, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { techlineService, analyticsService, accountsService } from '@web/services/singletons';
import { WorksheetReport, WorksheetValid } from '../components/worksheet/worksheet-report';
import { Retailer, WorksheetOnCaseSubmit } from '@packages/models/api/techline';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { WorksheetModal } from '@web/administration/views/worksheets/components/worksheet-modal';
import CaseTeam from '../components/case-team';
import { isAfter, isEqual } from 'date-fns';
import { CaseTeamOnDetails } from '../components/case-team-on-details';

const useStyles = createUseStyles({
    container: {
        height: '100%',
    },
    centerContainer: {
        flex: 1,
        overflow: 'scroll',
        borderLeft: `1px solid ${colors.grayTwo}`,
        borderRight: `1px solid ${colors.grayTwo}`,
    },
    rightContainer: {
        backgroundColor: colors.grayOne,
        width: '24%',
    },
    rightContainerHead: {
        height: '56px',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
});

export const Draft = ({
    caseNumber,
    vehicleDetails,
    caseDetails,
    assetsDetails,
    fetchAssets,
    fetchCaseDetails,
    setCaseDetails,
}: {
    caseNumber: any;
    vehicleDetails: any;
    caseDetails: Retailer | undefined;
    assetsDetails: any;
    fetchAssets: any;
    fetchCaseDetails: (caseNumber: string) => Promise<void>;
    setCaseDetails: any;
}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { account } = useAuthState();
    const [showReviewRequirementsConfirmation, setShowReviewRequirementsConfirmation] = useState(false);
    const [showConfirmInjuryEWDConfirmation, setShowConfirmInjuryEWDConfirmation] = useState(false);
    const [currentSelectedSection, setCurrentSelectedSection] = useState<TechlineSections>(
        TechlineSections.VehicleInformation
    );
    const [updateCaseDetailsLoader, setUpdateCaseDetailsLoader] = useState(false);
    const [issueFeildCaseDetails, setIssueFeildCaseDetails] = useState('');
    const [issueFeildFailCode, setIssueFeildFailCode] = useState<string>('');
    const [detailsEditedMap, setDetailsEditedMap] = useState({
        vehicleInformation: true,
        caseDetails: false,
        DTCs: false,
        failCode: false,
        worksheets: false,
        caseTeam: false,
        attachments: assetsDetails.length > 0,
        resolution: false,
    });
    //For delete case model
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeletedLoading, setIsDeletedLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
    const [invalidFields, setInvalidFields] = useState<{
        caseDetailsInvalidField: string[];
        failCodeInvalidFields: string[];
        worksheetInvalidFields: string[];
    }>({
        caseDetailsInvalidField: [],
        failCodeInvalidFields: [],
        worksheetInvalidFields: [],
    });
    const [showSubmitCaseIssue, setShowSubmitCaseIssue] = useState(false);

    const [isWorksheetsValidState, setIsWorksheetsValidState] = useState<WorksheetValid>({
        submitted: false,
        draft: false,
        caseSelected: false,
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    // Ref parse to WorksheetReport com for submit button call for worksheet validation function
    const worksheetRef = useRef<{
        checkAllWorksheet: () => Promise<boolean>;
        submissionWorksheets: () => WorksheetOnCaseSubmit;
    }>(null);

    const scrollDivRef = useRef<HTMLDivElement>(null);

    const handleIsWorksheetValid = useCallback((newState: Partial<WorksheetValid>) => {
        setIsWorksheetsValidState((prev) => ({ ...prev, ...newState }));
    }, []);

    const isWorksheetsValid = useMemo(() => {
        const message: string[] = [];

        if (!(isWorksheetsValidState.submitted || isWorksheetsValidState.draft)) {
            message.push('At least one worksheet is required for every case type');
        }
        if (!isWorksheetsValidState.caseSelected) {
            message.push('Case type');
        }
        setInvalidFields((prev) => ({ ...prev, worksheetInvalidFields: message }));
        return message.length;
    }, [isWorksheetsValidState]);

    const disableDraftEdit = useMemo(() => {
        if (account?.baseRoleIds?.length) {
            return !!account?.baseRoleIds.find((d) => d === 'CORPORATE_USER');
        }
        return false;
    }, [account]);

    const onSaveAndExit = async () => {
        try {
            navigate(
                `/techline/dashboard?retailerNumber=${
                    caseDetails?.retailerProfiles?.find((retailer: { isPrimary: any }) => retailer.isPrimary)
                        ?.retailerNumber
                }`
            );
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        }
    };

    const onDelete = async () => {
        setIsDeletedLoading(true);
        try {
            const createCaseRes = await techlineService.deleteCase({
                caseNumber: caseNumber || '',
            });
            if (createCaseRes?.success) {
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_DELETES_TECHLINE_DRAFT);
                navigate(
                    `/techline/dashboard?retailerNumber=${
                        caseDetails?.retailerProfiles?.find((retailer: { isPrimary: any }) => retailer.isPrimary)
                            ?.retailerNumber
                    }`
                );
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        } finally {
            setIsDeletedLoading(false);
            setShowDeleteModal(false);
        }
    };

    const updateCaseDetails = async (detailsToUpdate: any, onSuccess?: () => void, detailsType?: any) => {
        try {
            setCaseDetails((preVal: any) => ({ ...preVal, ...detailsToUpdate }));
            if (detailsType) {
                setDetailsEditedMap((preVal: any) => ({ ...preVal, [detailsType]: true }));
            }
            setUpdateCaseDetailsLoader(true);
            const updateBody = {
                caseNumber,
                vin: vehicleDetails.vin,
                username: account?.soaUsername || '',
                retailernumber: caseDetails?.retailerProfiles?.find(
                    (retailer: { isPrimary: any }) => retailer.isPrimary
                )?.retailerNumber,
                ...detailsToUpdate,
            };
            await techlineService.updateCase({ updateBody });
            setUpdateCaseDetailsLoader(false);
            onSuccess?.();
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
            setUpdateCaseDetailsLoader(false);
        }
    };

    const updateDTCCode = async (dtcCodeObj: any) => {
        setDetailsEditedMap((preVal: any) => ({ ...preVal, DTCs: true }));
        setCaseDetails((preVal: any) => ({ ...preVal, diagnosticTroubleCodes: dtcCodeObj }));
    };

    const rightContainerHead = () => {
        return (
            <div className={`${classes.rightContainerHead} d-flex flex-row align-items-center`}>
                {/* TODO */}
                {/* <div className="ml-4 mt-3 d-flex flex-column">
                    <Typography color="grayFive" variant="h6">
                        Comments
                    </Typography>
                    <div
                        className="mt-3"
                        style={{
                            borderBottom: `2px solid ${colors.grayOne}`, 
                        }}
                    />
                </div> */}
                <div onClick={() => setShowConfirmInjuryEWDConfirmation(true)} className="ml-4 mt-3 d-flex flex-column">
                    <Typography color="blueOne" variant="h6">
                        Details
                    </Typography>
                    <div
                        className="mt-3"
                        style={{
                            borderBottom: `2px solid ${colors.blueOne}`,
                        }}
                    />
                </div>
                {/* TODO */}
                {/* <div className="ml-4 mt-3 d-flex flex-column">
                    <Typography color="grayFive" variant="h6">
                        Activity
                    </Typography>
                    <div
                        className="mt-3"
                        style={{
                            borderBottom: `2px solid ${colors.grayOne}`,
                        }}
                    />
                </div> */}
            </div>
        );
    };

    const renderRightContainer = () => {
        return (
            <>
                <div className={`${classes.rightContainer}`}>
                    {rightContainerHead()}
                    <div className="ml-4 mt-3 mr-4">
                        <div className="d-flex flex-row justify-content-between">
                            <Typography color="black" variant="h5">
                                Retailer
                            </Typography>
                            {/* TODO */}
                            {/* <Typography color="blueOne" variant="h6">
                            Change retailer
                        </Typography> */}
                        </div>
                        {caseDetails?.retailerUniqueProfiles?.length &&
                            caseDetails.retailerUniqueProfiles.map((retailer: any) => (
                                <div className="mt-3 d-flex flex-column">
                                    <Typography color="blueOne">
                                        {retailer.name} (#{retailer.retailerNumber})
                                    </Typography>
                                    <div className="mt-1">
                                        <Typography>
                                            {retailer.city} {retailer.regionCode}, {retailer.state} {retailer.zoneCode},{' '}
                                            {retailer.districtCode}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        <div
                            className="mt-5"
                            style={{
                                borderBottom: `1px solid ${colors.grayThree}`,
                            }}
                        />
                        <CaseTeam
                            retailerNumber={
                                caseDetails?.retailerProfiles?.find(
                                    (retailer: { isPrimary: boolean }) => retailer.isPrimary
                                )?.retailerNumber || ''
                            }
                            retailerProfiles={caseDetails?.retailerProfiles || []}
                            caseNumber={caseNumber}
                            showError={onShowErrorMessage}
                            disableAddMember={disableDraftEdit}
                            modifiedOnDescription={caseDetails?.modifiedOnDescription}
                        />

                        <div
                            className="mt-5"
                            style={{
                                borderBottom: `1px solid ${colors.grayThree}`,
                            }}
                        />

                        <div className="mt-3 d-flex flex-column">
                            <Typography color="black" variant="h5">
                                Case Details
                            </Typography>

                            <div className="mt-3 d-flex flex-row align-items-center">
                                <div className="d-flex flex-column">
                                    <Typography variant="caption">
                                        <b>Last Updated:</b>{' '}
                                        {caseDetails?.modifiedOnDescription
                                            ? caseDetails.modifiedOnDescription
                                            : 'Today'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const scrollToSection = (section: TechlineSections) => {
        setCurrentSelectedSection(section);
        const targetSection = document.querySelector(`#${TechlineSections[section]}`);
        targetSection?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const scrollToSectionAttachment = (section: TechlineSections) => {
        setCurrentSelectedSection(section);

        const targetSection = document.querySelector(`#${TechlineSections[section]}`) as HTMLElement;
        if (targetSection && scrollDivRef.current) {
            const targetTop = targetSection.offsetTop;
            const offset = 100;

            scrollDivRef.current.scrollTo({
                top: targetTop - offset,
                behavior: 'smooth',
            });
        }
    };

    const onCenterContainerScrolled = () => {
        const sections: any = Object.values(TechlineSections);
        let selectedSectionId: any = TechlineSections.VehicleInformation;
        for (let i = 0; i < sections.length; i++) {
            if (typeof sections[i] !== 'string') {
                continue;
            }
            const currentSection: any = document.querySelector(`#${sections[i]}`);
            if (currentSection) {
                const topSpace = currentSection.getBoundingClientRect().top;
                const height = currentSection.getBoundingClientRect().height;
                if (topSpace > 0 || height - 200 + topSpace > 0) {
                    selectedSectionId = sections[i];
                    break;
                }
            }
        }
        setCurrentSelectedSection(TechlineSections[selectedSectionId] as any);
    };

    const isCaseDetailsValid = useMemo(() => {
        let issueFieldsLabel = '';
        const invalidFields: any = [];
        if (!caseDetails) return false;
        if (!caseDetails.repairOrderNumber?.length) {
            invalidFields.push('Repair order number');
            if (!issueFieldsLabel) {
                issueFieldsLabel = 'repairOrderNumber';
            }
        }
        if (caseDetails.repairOrderOpenDate?.length) {
            const selectedDate = new Date(caseDetails.repairOrderOpenDate);
            const allowedDate = new Date('1753-01-01');
            if (!(isAfter(selectedDate, allowedDate) || isEqual(selectedDate, allowedDate))) {
                invalidFields.push('Repair Order Date');
                if (!issueFieldsLabel) {
                    issueFieldsLabel = 'repairOrderOpenDate';
                }
            }
        }
        if (!caseDetails.repairOrderOpenDate?.length) {
            invalidFields.push('Repair Order Date');
            if (!issueFieldsLabel) {
                issueFieldsLabel = 'repairOrderOpenDate';
            }
        }
        if (!(caseDetails.mileage?.toString()?.length && caseDetails.mileage != 0)) {
            invalidFields.push('Mileage');
            if (!issueFieldsLabel) {
                issueFieldsLabel = 'mileage';
            }
        }
        if (!caseDetails.customerConcern?.length) {
            invalidFields.push("Customer's concern description");
            if (!issueFieldsLabel) {
                issueFieldsLabel = 'customerConcern';
            }
        }
        if (!caseDetails.technicianDescription?.length) {
            invalidFields.push("Technician's condition description");
            if (!issueFieldsLabel) {
                issueFieldsLabel = 'technicianDescription';
            }
        }
        if (caseDetails.hasBeenAbleToDuplicate) {
            if (!caseDetails.technician5StepsBeforeFailureDuplication?.toString()?.length) {
                invalidFields.push('Steps completed to duplicate');
                if (!issueFieldsLabel) {
                    issueFieldsLabel = 'technician5StepsBeforeFailureDuplication';
                }
            }
        }
        if (caseDetails.vehicleHasBeenInBefore) {
            if (!caseDetails.previousRONumber?.length) {
                invalidFields.push('Previous repair order number');
                if (!issueFieldsLabel) {
                    issueFieldsLabel = 'previousRONumber';
                }
            }
            if (!caseDetails.previousMileage?.toString()?.length) {
                invalidFields.push('Previous mileage');
                if (!issueFieldsLabel) {
                    issueFieldsLabel = 'previousMileage';
                }
            }
            if (caseDetails.werePartsReplaced) {
                if (!caseDetails.whatPartsWereReplaced?.length) {
                    invalidFields.push('What parts were replaced?');
                    if (!issueFieldsLabel) {
                        issueFieldsLabel = 'whatPartsWereReplaced';
                    }
                }
                if (!caseDetails.previousDiagnosisAndRepairs?.length) {
                    invalidFields.push('Previous Diagnosis and Repair');
                    if (!issueFieldsLabel) {
                        issueFieldsLabel = 'previousDiagnosisAndRepairs';
                    }
                }
                if (!caseDetails.didTheConditionImprove?.length) {
                    invalidFields.push('Did the condition improve or change noticeably? If so, how?');
                    if (!issueFieldsLabel) {
                        issueFieldsLabel = 'didTheConditionImprove';
                    }
                }
            }
        }
        // if (caseDetails.rolloverFireAccidentInjuryDeathDamage == undefined) {
        //     return false;
        // }
        // if (
        //     caseDetails.rolloverFireAccidentInjuryDeathDamage &&
        //     JSON.parse(caseDetails.rolloverFireAccidentInjuryDeathDamage)
        // ) {
        //     return (
        //         caseDetails.rolloverFlag ||
        //         caseDetails.fireFlag ||
        //         caseDetails.accidentFlag ||
        //         caseDetails.injuryFlag ||
        //         caseDetails.deathFlag ||
        //         (caseDetails.propertyDamage && JSON.parse(caseDetails.propertyDamage)) ||
        //         caseDetails.airbagRuptureFlag
        //     );
        // }
        setIssueFeildCaseDetails(issueFieldsLabel);
        setInvalidFields((preVal) => ({ ...preVal, caseDetailsInvalidField: invalidFields }));
        return !invalidFields.length;
    }, [caseDetails]);

    const isFailCodeValid = useMemo(() => {
        const invalidFields: any = [];
        let issueFieldLabel = '';
        if (!caseDetails) {
            return false;
        }
        if (!caseDetails.section?.length) {
            invalidFields.push('Fail code');
            if (!issueFieldLabel) {
                issueFieldLabel = 'failCode';
            }
        }
        if (!caseDetails.subSection?.length) {
            if (!issueFieldLabel) {
                issueFieldLabel = 'subSection';
            }
        }
        if (!caseDetails.repairCode?.length) {
            if (!issueFieldLabel) {
                issueFieldLabel = 'repairCode';
            }
        }
        setIssueFeildFailCode(issueFieldLabel);
        setInvalidFields((preVal) => ({ ...preVal, failCodeInvalidFields: invalidFields }));
        return !invalidFields.length;
    }, [caseDetails]);

    const onShowErrorMessage = useCallback((error: string) => {
        setErrorMessage(error);
        setTimeout(() => {
            setErrorMessage('');
        }, 5000);
    }, []);
    const onSubmitCase = useCallback(async () => {
        setDetailsEditedMap((preVal: any) => ({ ...preVal, worksheets: true }));
        setShowSubmitCaseIssue(false);
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_SUBMIT_TECHLINE_CASE);
        setIsSubmitLoading(true);
        const check = await worksheetRef?.current?.checkAllWorksheet();
        setIsSubmitLoading(false);
        if (!isCaseDetailsValid || !isFailCodeValid || !check || isWorksheetsValid) {
            setShowSubmitCaseIssue(true);
            setTimeout(() => {
                setShowSubmitCaseIssue(false);
            }, 5000);
            return;
        }
        if (!check) {
            return;
        }
        setShowReviewRequirementsConfirmation(true);
    }, [isCaseDetailsValid, isFailCodeValid, isWorksheetsValid]);

    const onSubmitConfirmed = async () => {
        try {
            setUpdateCaseDetailsLoader(true);
            if (!worksheetRef.current) {
                return;
            }
            const submissionWOrksheets: WorksheetOnCaseSubmit = worksheetRef.current.submissionWorksheets();
            const updateCaseDetailsRes = await techlineService.updateCaseStatus({
                updateBody: {
                    casenumber: caseNumber,
                    username: account?.soaUsername || '',
                    retailernumber: caseDetails?.retailerProfiles?.find(
                        (retailer: { isPrimary: any }) => retailer.isPrimary
                    )?.retailerNumber,
                    techlinecasestatus: 'OPEN',
                    ...submissionWOrksheets,
                },
            });
            if (updateCaseDetailsRes?.success) {
                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SUCCESSFULLY_SUBMITS_TECHLINE_CASE);
                navigate(`/techline/dashboard?showSubmitCaseSuccessFor=${caseNumber}`);
            }
        } catch (err) {
            alert('Error: ' + err + '. Please refresh & try again.');
            console.log(err);
        } finally {
            setUpdateCaseDetailsLoader(false);
        }
    };

    const reviewRequirementsConfirmation = () => {
        return (
            <Modal
                show={showReviewRequirementsConfirmation}
                centered={true}
                onHide={() => setShowReviewRequirementsConfirmation(false)}
                size="lg"
            >
                <Modal.Header placeholder={''}>
                    <Modal.Title>Submit Techline Case</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Typography>
                        By submitting this case, it will become visible to Techline users and can no longer be edited or
                        deleted. Are you sure you are ready to submit?
                    </Typography>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowReviewRequirementsConfirmation(false)}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={updateCaseDetailsLoader}
                        disabled={false}
                        onPress={onSubmitConfirmed}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const renderConfirmInjuryEWD = () => {
        return (
            <Modal
                show={showConfirmInjuryEWDConfirmation}
                centered={true}
                onHide={() => setShowConfirmInjuryEWDConfirmation(false)}
                size="lg"
            >
                <Modal.Header placeholder={''}>
                    <Modal.Title>Confirm injury EWD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Typography>
                        Please be extremely detailed in completing this Techline case and enter the number of people
                        injured below.
                    </Typography>
                    <TextInputHelper
                        required
                        label={'Number of people injured'}
                        placeholder={'Enter Number of people injured'}
                        value={''}
                        onChangeText={() => {}}
                        onBlur={() => {}}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowConfirmInjuryEWDConfirmation(false)}>
                            Cancel
                        </Button>
                    </div>

                    <Button
                        variant="primary"
                        isLoading={false}
                        disabled={false}
                        onPress={() => setShowConfirmInjuryEWDConfirmation(false)}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className={`${classes.container} d-flex flex-column`}>
            <WorksheetModal
                show={showDeleteModal}
                onHideModal={() => setShowDeleteModal(false)}
                title="Delete Case"
                bodyMessage={'Are you sure you want to delete this case?'}
                cancelButton="Cancel"
                confirmButton="Delete"
                confirmButtonColor="danger"
                confirmButtonLoading={isDeletedLoading}
                handleAction={(ans) => {
                    if (ans) {
                        onDelete();
                    } else {
                        setShowDeleteModal(false);
                    }
                }}
            />

            <TechlineHeader
                onSubmit={onSubmitCase}
                onSaveAndExit={onSaveAndExit}
                caseNumber={caseNumber}
                caseDetails={caseDetails}
                isSubmitLoading={isSubmitLoading}
                onDelete={() => setShowDeleteModal(true)}
                disabledSubmit={disableDraftEdit}
                disabledSave={disableDraftEdit}
                disabledDelete={disableDraftEdit}
                // disabledSubmit={
                //     !isCaseDetailsValid ||
                //     !isFailCodeValid ||
                //     !(isWorksheetsValid.submitted || isWorksheetsValid.draft) ||
                //     !isWorksheetsValid.caseSelected
                // }
            />
            {showSubmitCaseIssue && (
                <div>
                    <AlertBar
                        error
                        show={true}
                        onClose={() => {
                            setShowSubmitCaseIssue(false);
                        }}
                    >
                        <Typography>
                            Required fields are missing {invalidFields.caseDetailsInvalidField?.join(', ')}
                            {invalidFields.caseDetailsInvalidField.length ? ', ' : ''}{' '}
                            {invalidFields.failCodeInvalidFields?.join(', ')}
                            {invalidFields.failCodeInvalidFields.length ? ', ' : ''}{' '}
                            {invalidFields.worksheetInvalidFields?.join(', ')}
                        </Typography>
                    </AlertBar>
                </div>
            )}

            {errorMessage && (
                <div>
                    <AlertBar
                        error
                        show={true}
                        onClose={() => {
                            setErrorMessage('');
                        }}
                    >
                        <Typography>ERROR: {errorMessage}</Typography>
                    </AlertBar>
                </div>
            )}

            {successMessage && (
                <div>
                    <AlertBar
                        success
                        show={true}
                        onClose={() => {
                            setSuccessMessage('');
                        }}
                    >
                        <Typography>{successMessage}</Typography>
                    </AlertBar>
                </div>
            )}
            <div style={{ height: '92%' }} className="d-flex flex-row">
                <TechlineSideNav
                    caseDetails={caseDetails}
                    scrollToSection={scrollToSection}
                    currentSelectedSection={currentSelectedSection}
                    isCaseDetailsValid={isCaseDetailsValid}
                    isFailCodeValid={isFailCodeValid}
                    isWorksheetsValid={!isWorksheetsValid}
                    assetsDetails={assetsDetails}
                    detailsEditedMap={detailsEditedMap}
                />
                <div
                    ref={scrollDivRef}
                    onScroll={onCenterContainerScrolled}
                    className={`${classes.centerContainer} p-10`}
                >
                    <section id={TechlineSections[TechlineSections.VehicleInformation]}>
                        <VehicleInfo vehicleDetails={vehicleDetails} type={caseDetails?.techlineStatusCode || ''} />
                    </section>
                    <section id={TechlineSections[TechlineSections.CaseDetails]}>
                        {caseDetails && (
                            <CaseDetailsInput
                                caseDetails={caseDetails}
                                updateCaseDetails={(detailsToUpdate: any, onSuccess?: () => void) =>
                                    updateCaseDetails(detailsToUpdate, onSuccess, 'caseDetails')
                                }
                                issueFeildCaseDetails={issueFeildCaseDetails}
                            />
                        )}
                    </section>
                    <hr className="mt-5 mb-5" color={colors.grayThree} />

                    <section id={TechlineSections[TechlineSections.DTCSSymptoms]}>
                        {caseDetails && (
                            <DtcsInputs
                                caseNumber={caseNumber || ''}
                                caseDetails={caseDetails}
                                updateDTCCode={updateDTCCode}
                            />
                        )}
                    </section>

                    <section id={TechlineSections[TechlineSections.FailCode]}>
                        <FailCode
                            caseDetails={caseDetails}
                            updateCaseDetails={(detailsToUpdate: any, onSuccess?: () => void) =>
                                updateCaseDetails(detailsToUpdate, onSuccess, 'failCode')
                            }
                            vehicleDetails={vehicleDetails}
                            issueFeildFailCode={issueFeildFailCode}
                        />
                    </section>

                    <section id={TechlineSections[TechlineSections.Worksheets]}>
                        <WorksheetReport
                            worksheetSubmission={caseDetails?.worksheetSubmissions}
                            caseTypesDefault={caseDetails?.caseTypes}
                            handleIsWorksheetValid={handleIsWorksheetValid}
                            caseStatus={caseDetails?.techlineStatusCode}
                            ref={worksheetRef}
                        />
                    </section>

                    <section id={TechlineSections[TechlineSections.CaseTeam]} className="mt-5">
                        <CaseTeamOnDetails
                            retailerNumber={
                                caseDetails?.retailerProfiles?.find(
                                    (retailer: { isPrimary: boolean }) => retailer.isPrimary
                                )?.retailerNumber || ''
                            }
                            retailerProfiles={caseDetails?.retailerProfiles || []}
                            caseNumber={caseNumber}
                            showError={onShowErrorMessage}
                            disableAddMember={disableDraftEdit}
                            fetchCaseDetails={fetchCaseDetails}
                            caseStatus={caseDetails?.techlineStatusCode}
                            modifiedOnDescription={caseDetails?.modifiedOnDescription}
                        />
                    </section>

                    <section id={TechlineSections[TechlineSections.Attachments]}>
                        <Attachments
                            fetchAssets={async (caseNumber: string) => {
                                await fetchAssets(caseNumber);
                                setDetailsEditedMap((preVal: any) => ({ ...preVal, attachments: true }));
                            }}
                            assetsDetails={assetsDetails}
                            caseNumber={caseNumber || ''}
                            caseDetails={caseDetails}
                            scrollToSection={scrollToSectionAttachment}
                            setErrorMessage={setErrorMessage}
                            setSuccessMessage={setSuccessMessage}
                        />
                    </section>
                </div>
                {renderRightContainer()}
            </div>
            {reviewRequirementsConfirmation()}
            {renderConfirmInjuryEWD()}
        </div>
    );
};
