import { HttpClient } from '@packages/core/http';
import {
    FailCodeSearchResponse,
    FailCodesSearchResponse,
    FailCodeSectionsResponse,
    FailCodeSubsectionsResponse,
    FailCodeFiguresResponse,
    FailCodesResponse,
    FailCodeLocationsResponse,
    FailCodeFrequenciesResponse,
    FailCodeConcernsResponse,
    FailCodeElectricalGridSubSectionResponse,
    IncidentSectionsResponse,
    IncidentSubsectionsResponse,
} from '@packages/models/api';

import { FailCodes } from '@packages/models/api/fail-codes';

export interface DeleteFailCodeParams {
    failCode: string;
    team?: string;
    action: string;
}

export interface FailCodeParams {
    failCode?: string;
}

export interface AddFailCodeTeamParams {
    action: string;
    team: string;
    failCode: string;
}

export interface DeleteTeamParams {
    action: string;
    team: string;
}

export interface RenameTeamParams {
    action: string;
    team: string;
    newTeam: string;
}

export interface AddFailCodeParams {
    action: string;
    team: string;
    failCode: string;
}

export interface SearchFailCodeParams {
    query: string;
}

export interface FailCodeQueryParams {
    page?: number;
    size?: number;
    [key: string]: string | number | boolean | undefined;
}

export class FailCodesService {
    constructor(private http: HttpClient) {}

    searchFailCodes({
        qmrId,
        query,
        ignoreCache,
        signal,
    }: {
        qmrId?: string;
        query?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/search' + this.http.queryString({ qmrId, query });

        return this.http.orchestrateRequest<FailCodeSearchResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    searchFailCode({
        searchFailCodeParams,
        ignoreCache,
        signal,
    }: {
        searchFailCodeParams: SearchFailCodeParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/all-fail-codes-with-teams' + this.http.queryString(searchFailCodeParams);

        return this.http.orchestrateRequest<FailCodesSearchResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    searchPartNumbers({
        qmrId,
        query,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        query?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/part-number/search' + this.http.queryString({ qmrId, query });

        return this.http.orchestrateRequest<FailCodeSearchResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeSections({
        qmrId,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/sections' + this.http.queryString({ qmrId });

        return this.http.orchestrateRequest<FailCodeSectionsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodeSubsections({
        qmrId,
        sectionNumber,
        sectionName,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        sectionNumber: number;
        sectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/subsections' + this.http.queryString({ qmrId, sectionNumber, sectionName });

        return this.http.orchestrateRequest<FailCodeSubsectionsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodeFigures({
        qmrId,
        sectionNumber,
        sectionName,
        subsectionNumber,
        subsectionName,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        sectionNumber: number;
        sectionName: string;
        subsectionNumber: number;
        subsectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url =
            '/fail-codes/figures' +
            this.http.queryString({ qmrId, sectionNumber, sectionName, subsectionNumber, subsectionName });

        return this.http.orchestrateRequest<FailCodeFiguresResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    lookupFailCodes({
        qmrId,
        sectionNumber,
        subsectionNumber,
        figureCode,
        partNumber,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        sectionNumber?: number;
        subsectionNumber?: number;
        figureCode?: string;
        partNumber?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = partNumber
            ? this.http.queryString({ qmrId, partNumber })
            : this.http.queryString({ qmrId, sectionNumber, subsectionNumber, figureCode });

        const url = '/fail-codes' + queryString;

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeWiringDiagramSubsections({
        qmrId,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ qmrId });

        return this.http.orchestrateRequest<FailCodeElectricalGridSubSectionResponse>({
            method: 'GET',
            url: '/fail-codes/electrical-wiring-diagrams/subsection-figures' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchIsValidFailCode({
        failCode,
        ignoreCache,
        signal,
    }: {
        failCode: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ failCode });

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'GET',
            url: '/fail-codes/siebel-fail-codes' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeLocations({ ignoreCache, signal }: { ignoreCache?: boolean; signal?: AbortSignal }) {
        return this.http.orchestrateRequest<FailCodeLocationsResponse>({
            method: 'GET',
            url: '/fail-codes/locations',
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeFrequencies({
        locationCode,
        ignoreCache,
        signal,
    }: {
        locationCode: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/frequencies' + this.http.queryString({ locationCode });

        return this.http.orchestrateRequest<FailCodeFrequenciesResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeConcerns({
        locationCode,
        frequencyCode,
        ignoreCache,
        signal,
    }: {
        locationCode: string;
        frequencyCode: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/concerns' + this.http.queryString({ locationCode, frequencyCode });

        return this.http.orchestrateRequest<FailCodeConcernsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchPossibleSections({
        qmrId,
        failCode,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        failCode?: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ qmrId, failCode });

        return this.http.orchestrateRequest<FailCodeSectionsResponse>({
            method: 'GET',
            url: '/fail-codes/fail-code-sections' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchPossibleSubsections({
        qmrId,
        failCode,
        sectionNumber,
        sectionName,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        failCode?: string;
        sectionNumber: number;
        sectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ qmrId, failCode, sectionNumber, sectionName });

        return this.http.orchestrateRequest<FailCodeSubsectionsResponse>({
            method: 'GET',
            url: '/fail-codes/fail-code-subsections' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchSubSectionsWithoutFailCode({
        qmrId,
        sectionNumber,
        sectionName,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        sectionNumber: number;
        sectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const queryString = this.http.queryString({ qmrId, sectionNumber, sectionName });

        return this.http.orchestrateRequest<FailCodeSubsectionsResponse>({
            method: 'GET',
            url: '/fail-codes/subsections' + queryString,
            ignoreCache,
            signal,
        });
    }

    fetchFailCodeIncident({
        qmrId,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/incident-inspection' + this.http.queryString({ qmrId });

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchIncidentSections({
        qmrId,
        failCode,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        failCode: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-codes/incident-inspection/sections' + this.http.queryString({ qmrId, failCode });

        return this.http.orchestrateRequest<IncidentSectionsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    fetchIncidentSubsections({
        qmrId,
        failCode,
        sectionName,
        ignoreCache,
        signal,
    }: {
        qmrId: string;
        failCode: string;
        sectionName: string;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url =
            '/fail-codes/incident-inspection/subsections' + this.http.queryString({ qmrId, failCode, sectionName });

        return this.http.orchestrateRequest<IncidentSubsectionsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    getFailCodeTeams({
        ignoreCache = false,
        signal,
        queryParameters = {},
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
        queryParameters?: FailCodeQueryParams;
    }) {
        const url = `/fail-codes-teams${this.http.queryString(queryParameters)}`;

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    deleteFailCode({
        deleteParams,
        ignoreCache,
        signal,
    }: {
        deleteParams: DeleteFailCodeParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-code-process' + this.http.queryString(deleteParams);

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'POST',
            url,
            ignoreCache,
            signal,
        });
    }

    getFailCode({
        searchParams,
        ignoreCache,
        signal,
    }: {
        searchParams: FailCodeParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/all-fail-codes-with-teams' + this.http.queryString(searchParams);

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }

    addFailCodeTeam({
        addParams,
        ignoreCache,
        signal,
    }: {
        addParams: AddFailCodeTeamParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-code-process' + this.http.queryString(addParams);

        return this.http.orchestrateRequest<AddFailCodeTeamParams>({
            method: 'POST',
            url,
            ignoreCache,
            signal,
        });
    }

    deleteTeam({
        deleteTeamParams,
        ignoreCache,
        signal,
    }: {
        deleteTeamParams: DeleteTeamParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-code-process' + this.http.queryString(deleteTeamParams);

        return this.http.orchestrateRequest<FailCodesResponse>({
            method: 'POST',
            url,
            ignoreCache,
            signal,
        });
    }

    renameFailCodeTeam({
        renameParams,
        ignoreCache,
        signal,
    }: {
        renameParams: RenameTeamParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-code-process' + this.http.queryString(renameParams);

        return this.http.orchestrateRequest<RenameTeamParams>({
            method: 'POST',
            url,
            ignoreCache,
            signal,
        });
    }

    addFailCode({
        addFailCodeParams,
        ignoreCache,
        signal,
    }: {
        addFailCodeParams: AddFailCodeParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/fail-code-process' + this.http.queryString(addFailCodeParams);

        return this.http.orchestrateRequest<AddFailCodeParams>({
            method: 'POST',
            url,
            ignoreCache,
            signal,
        });
    }

    initiateCsvExport({ failCodes }: { failCodes: FailCodes[] | null }, searchId?: string | null) {
        const failCode = failCodes ? failCodes.map((failCodes) => failCodes.failCode) : null;
        const body = {
            failCodes: failCode,
            ...(searchId ? { searchId } : {}),
        };

        return this.http.orchestrateRequest({
            method: 'POST',
            url: '/failCode/csv-export',
            body,
        });
    }
}
