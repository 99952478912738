import React, { FC } from 'react';
import { Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { SkeletonLoader } from '@web/components/skeleton-loader';
import { StyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import { TextStyle } from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

const useStyles = createUseStyles({
    dataCardBody: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        paddingBottom: '6px',
    },
    dataCardTextOuter: {
        color: '#071424',
        fontSize: 13,
        marginBottom: '10px',
    },
    dataCardTextInner: {
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export type DataCardItem = { label?: string | undefined; value: string | JSX.Element | number };

export interface DataCardBodyProps {
    data: DataCardItem[];
    loading?: boolean;
    maxCharacters?: number;
}

export const DataCardBody: FC<DataCardBodyProps> = ({ data, loading = false }) => {
    const classes = useStyles();

    function resolveItemText(item: DataCardItem) {
        let text = '';

        if (item.label) {
            text += `${item.label}: `;
        }

        if (item.value) {
            text += `${item.value}`;
        } else {
            text += 'N/A';
        }

        return text;
    }

    const itemClasses = `col-12 col-lg-6 d-flex align-items-center ${classes.dataCardTextOuter}`;

    const typographyStyles = {
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    } as unknown as StyleProp<TextStyle>;

    return (
        <div className={`row ${classes.dataCardBody}`}>
            {loading ? (
                <>
                    <div className={itemClasses} data-testid="data-card-body-loading">
                        <SkeletonLoader type="text" className="w-100" />
                    </div>
                    <div className={itemClasses}>
                        <SkeletonLoader type="text" className="w-100" />
                    </div>
                    <div className={itemClasses}>
                        <SkeletonLoader type="text" className="w-100" />
                    </div>
                    <div className={itemClasses}>
                        <SkeletonLoader type="text" className="w-100" />
                    </div>
                </>
            ) : (
                data.map((item, index) => (
                    <div key={index} className={itemClasses}>
                        <Typography variant="caption" style={typographyStyles}>
                            {resolveItemText(item)}
                        </Typography>
                    </div>
                ))
            )}
        </div>
    );
};
